export const FETCH__USER_PROFILE = 'FETCH__USER_PROFILE';
export const FETCH__USER_PROFILE__FAIL = 'FETCH__USER_PROFILE__FAIL';
export const FETCH__USER_PROFILE__SUCCESS = 'FETCH__USER_PROFILE__SUCCESS';

export const FETCH__USER_PROFILE_SETTINGS = 'FETCH__USER_PROFILE_SETTINGS';
export const FETCH__USER_PROFILE_SETTINGS__FAIL = 'FETCH__USER_PROFILE_SETTINGS__FAIL';
export const FETCH__USER_PROFILE_SETTINGS__SUCCESS = 'FETCH__USER_PROFILE_SETTINGS__SUCCESS';

export const UPDATE__USER_PROFILE_SETTINGS = 'UPDATE__USER_PROFILE_SETTINGS';
export const UPDATE__USER_PROFILE_SETTINGS__SUCCESS = 'UPDATE__USER_PROFILE_SETTINGS__SUCCESS';
export const UPDATE__USER_PROFILE_SETTINGS__FAIL = 'UPDATE__USER_PROFILE_SETTINGS__FAIL';

export const RESET__USER_PROFILE_SETTINGS = 'RESET__USER_PROFILE_SETTINGS';
