import React from 'react';

import AuthLayout from '../../common/layout/auth/AuthLayout';
import ForgotPasswordForm from '../../auth/forgot-password/ForgotPassword';
import forgotPasswordImg from '../../assets/images/island.jpg';

const ForgotPasswordPage = () => (
  <AuthLayout path={forgotPasswordImg}>
    <ForgotPasswordForm />
  </AuthLayout>
);

export default ForgotPasswordPage;
