import React from 'react';

import AuthLayout from '../../common/layout/auth/AuthLayout';
import LoginForm from '../../auth/login/LoginForm';
import loginImg from '../../assets/images/login.jpg';

const LoginPage = () => (
  <AuthLayout path={loginImg}>
    <LoginForm />
  </AuthLayout>
);

export default LoginPage;
