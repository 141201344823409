const loginFormLabels = {
  validation: {
    email: {
      wrongFormat: 'It doesn\'t look like email',
      empty: 'It\'s required',
    },
    password: {
      tooShort: 'It\'s too short',
      empty: 'It\'s required',
    },
    loginFailed: 'Wrong combination of login & password',
  },
  header: 'financial portal',
  subHeader: 'Welcome to online account access for investors and borrowers',
  message: 'This service enables you to view your account information online, updated daily.',
  controls: {
    email: {
      label: 'Email Address',
      placeholder: 'Example: mail@mail.com',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter password here',
    }
  },
  buttons: {
    forgotPassword: 'Forgot Password?',
    logIn: 'Log In',
    register: 'Register now!',
  },
  registerMessage: 'Don\'t have an account?',
}

export default loginFormLabels;