import React, { Component } from 'react';

class BestMatchItem extends Component {
  render() {
    let { item: { id, resourceType, number, name }, onItemSelected, search} = this.props;
    let numberParts = number.split(new RegExp(`(${search})` ,'gi'));
    let nameParts = name.split(new RegExp(`(${search})` ,'gi'));

    return (
      <li onDoubleClick={onItemSelected} data-item-id={id} className="best-match-item">
        <h4>{resourceType === 0 ? 'Loan' : 'Investment' }</h4>
        <p>
          {
            numberParts.map((item, index) => {
              return item.toLowerCase() === search.toLowerCase()
                ? <strong key={index}>{item}</strong>
                : <span key={index}>{item}</span>
            })
          }:
          {
            nameParts.map((item, index) => {
              return item.toLowerCase() === search.toLowerCase()
                ? <strong key={index}>{item}</strong>
                : <span key={index}>{item}</span>
            })
          }
        </p>
      </li>

    );
  }
}

export default BestMatchItem;