import {
  REQUEST_RESOURCE_REQUESTS,
  RECEIVE_RESOURCE_REQUESTS,
  REQUEST_RSRQ_DETAILS,
  RECEIVE_RSRQ_DETAILS,
  RSRQ_APPROVE,
  HANDLE_RSRQ_APPROVE,
  RSRQ_ACCEPT,
  HANDLE_RSRQ_ACCEPT,
  RSRQ_DECLINE,
  HANDLE_RSRQ_DECLINE,
  FETCH_RSRQ_RESOLUTION,
  HANDLE_RSRQ_RESOLUTION,
  RRQ_DELETE,
  RRQ_DELETE_SUCCESS
} from '../actions';

const defaultState = {
  items: [],
  itemDetails: null,
  lastUpdated: null,
  isRequested: false,
  isOperationInProgress: false,
  resolution: null
};

const resourceRequests = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_RESOURCE_REQUESTS:
    case REQUEST_RSRQ_DETAILS:
      return { ...state, isRequested: true, };
    case RECEIVE_RESOURCE_REQUESTS:
      return {
        ...state,
        items: action.items,
        isRequested: false,
      };
    case RECEIVE_RSRQ_DETAILS:
      return {
        ...state,
        itemDetails: action.item,
        isRequested: false,
      };
    case RSRQ_APPROVE:
    case RSRQ_ACCEPT:
    case RSRQ_DECLINE:
    case RRQ_DELETE:
      return {
        ...state,
        isOperationInProgress: true
      };
    case HANDLE_RSRQ_APPROVE:
    case HANDLE_RSRQ_ACCEPT:
    case HANDLE_RSRQ_DECLINE:
    case RRQ_DELETE_SUCCESS:
      return {
        ...state,
        isOperationInProgress: false
      };
    case FETCH_RSRQ_RESOLUTION:
      return {
        ...state,
        resolution: {
          isRequested: true,
          item: null
        }
      };
    case HANDLE_RSRQ_RESOLUTION:
      return {
        ...state,
        resolution: {
          isRequested: false,
          item: action.item
        }
      };
    default:
      return state;
  }
}

export default resourceRequests;