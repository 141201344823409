import React, { Component } from 'react';
import { Avatar } from 'antd';
import { FileOutlined } from '@ant-design/icons';

export default class FileItem extends Component {
  render() {
    let { fileInfo, uploaded } = this.props;
    let avatarStyle = {
      backgroundColor: uploaded ? '#28a745' : '#6c757d'
    };

    return (
      <div className="file-item">
        <div><Avatar size={32} icon={<FileOutlined />} style={avatarStyle} /></div>
        <div>
          <h4>{fileInfo.caption}</h4>
          <h5>{fileInfo.fileName}</h5>
          <p>{fileInfo.description}</p>
        </div>
      </div>
    )
  }
}
