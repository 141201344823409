import React from 'react';
import { connect } from 'react-redux';

import { adminRole } from '../../const/user';
import UserAccountPage from '../../common/pages/user-account-page/UserAccountPage';
import AdminPage from '../../common/pages/admin-page/AdminPage';
import ProfileSettingsForm from '../../user/profile-settings/ProfileSettingsForm';

import './profile-settings.less';

const ProfileSettingsPage = ({
  user: {
    role
  }
}) => {
  return role === adminRole
    ? (
      <AdminPage>
        <div className="profile-settings">
          <ProfileSettingsForm />
        </div>
      </AdminPage>
    )
    : (
      <UserAccountPage loadProfile>
        <div className="profile-settings">
          <ProfileSettingsForm />
        </div>
      </UserAccountPage>
    );
}

export default connect(
  ({ user }) => ({ user }),
  {}
)(ProfileSettingsPage);
