import axios from 'axios';
import { investmentDetailsEndpoint } from '../../const/backend';
import { actionTemplate, authHeaders, userAndAuthHeaders } from '../shared';
import { LOG_OUT } from '../user/actions';
import {
  FETCH__ASSET_DETAILS,
  FETCH__ASSET_DETAILS__FAIL,
  FETCH__ASSET_DETAILS__SUCCESS
} from './actions';

export const fetchAssetDetails = (userId, investmentId, assetId, token) => dispatch => {
  dispatch(actionTemplate(FETCH__ASSET_DETAILS));

  axios.get(
    investmentDetailsEndpoint.assetDetails(investmentId, assetId),
    { headers: !!userId ? userAndAuthHeaders(token, userId) : authHeaders(token) }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ ...actionTemplate(FETCH__ASSET_DETAILS__SUCCESS), details: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__ASSET_DETAILS__FAIL));
  });
}
