import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchStates, fetchCities } from '../../store/geo/actionCreators';
import PropTypes from 'prop-types';

import { Button, Select } from 'antd';

class CityFilterPanel extends Component {
  componentDidMount() {
    let { fetchStates } = this.props;

    fetchStates();
  }

  handleFilterOption = (inputValue, option) => {
    let { props: { label }} = option;

    return label
      .toLowerCase()
      .includes(inputValue.toLowerCase());
  }

  handleStateChange = (stateId) => {
    let { fetchCities } = this.props;
    fetchCities(stateId);
  }

  render() {
    let {
      geo: {
        isStatesRequested,
        isStatesRequestFailed,
        states,
        isCitiesRequested,
        isCitiesRequestFailed,
        cities
      },
      onApplyFilter,
      onCityChanged,
      cityId
    } = this.props;

    return (
      <div className="city-filter-panel">
        <div className="city-filter-panel_ctrl">
          <label>State</label>
          <Select
            showSearch
            filterOption={this.handleFilterOption}
            disabled={!states || isStatesRequestFailed}
            onChange={this.handleStateChange}
            loading={isStatesRequested}>
            {
              !!states && states.map((state) => (
                <Select.Option 
                  key={state.id}
                  label={state.name}
                  value={state.id}>{state.name}
                </Select.Option>))
            }
          </Select>
        </div>
        <div className="city-filter-panel_ctrl">
          <label>City</label>
          <Select
            value={cityId}
            showSearch
            optionLabelProp="label"
            filterOption={this.handleFilterOption}
            loading={isCitiesRequested}
            onChange={onCityChanged}
            disabled={!cities || cities.length === 0 || isCitiesRequested || isCitiesRequestFailed}
          >
            {
              !!cities && cities.map((city) => (
                <Select.Option key={city.id} value={city.id} label={city.name}>
                  <div className="cities-select__name">{city.name}</div>
                  <div className="cities-select__county">{`${city.county} County`}</div>
                </Select.Option>))
            }
          </Select>
        </div>
        <Button type="primary" disabled={!cityId} onClick={onApplyFilter}>Filter</Button>
      </div>
    );
  }
}

CityFilterPanel.propTypes = {
  cityId: PropTypes.number,
  onCityChanged: PropTypes.func,
  onApplyFilter: PropTypes.func,
};

CityFilterPanel.defaultProps = {
  onCityChanged: () => {},
  onApplyFilter: () => {},
};


export default connect(
  ({ geo }) => ({ geo }),
  { fetchStates, fetchCities }
)(CityFilterPanel);