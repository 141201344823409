import React from 'react';
import { Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';

import UserLogo from '../../UserLogo/UserLogo';
import { routes } from '../../../routing';
import userHeaderLabels from '../../../labels/user-header';

const { Item, Divider } = Menu;

const ProfileMenu = ({ onLogout }) => {
  const userMenu = (
    <Menu>
      <Item key={0}>
        <Link to={routes.profileSettings}>
          {userHeaderLabels.userMenu.editProfile}
        </Link>
      </Item>
      <Divider />
      <Item key={1} onClick={onLogout}>
        {userHeaderLabels.userMenu.logOut}
      </Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={userMenu}
      trigger={['click']}
      placement="bottomRight"
    >
      <div className="portal-layout_header_profile-icon">
        <UserLogo />
      </div>
    </Dropdown>
  );
};

export default ProfileMenu;
