import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Tag, Button } from 'antd';
import { resourceRequestNumberTemplate } from '../../utility';

const statusMap = {
  '0': 'open',
  '1': 'accepted',
  '2': 'declined',
  '3': 'approved'
}

const requestTypeMap = {
  '0': 'Loan',
  '1': 'Investment'
}

const ResourceRequestListItemAlt = ({
  id,
  requestType,
  number,
  createdOn,
  status,
  lastModifiedOn,
  initiatedBy,
  initiatedFor,
  onDoubleClick,
  onDelete,
  processedBy,
}) => {
  let rootClasses = classNames(
    'rrq-list_item', {
      [`rrq-list_item--${statusMap[status]}`]: true
    }
  );

  return (
    <li className={rootClasses} data-item-id={id} onDoubleClick={onDoubleClick}>
      <div className="rrq-list_item_number-col">
        <h3>{resourceRequestNumberTemplate(id)} <span>{requestTypeMap[requestType]}</span></h3>
        <div className="rrq-list_item_secondary-text">{`on ${moment(createdOn).format('MMMM Do YYYY')} (${moment(createdOn).fromNow()})`}</div>
        <div className="rrq-list_item_secondary-text">{`by ${initiatedBy.fullName}`}</div>
      </div>
      <div className="rrq-list_item_col">
        <h4>Initiated for</h4>
        <div className="rrq-list_item_primary-text">{initiatedFor.organizationName}</div>
        <div className="rrq-list_item_primary-text">{initiatedFor.fullName}</div>
      </div>
      <div className="rrq-list_item_col">
        <h4>Requested Number</h4>
        <div className="rrq-list_item_primary-text">{number}</div>
      </div>
      <div className="rrq-list_item_col">
        <h4>Status</h4>
        <Tag className={`rrq-list_item_tag--${statusMap[status]}`}>{statusMap[status]}</Tag>
      </div>
      <div className="rrq-list_item_col">
        <div className="rrq-list_item_primary-text">{`Last Updated ${moment(lastModifiedOn).fromNow()}`}</div>
        <div className="rrq-list_item_secondary-text">{`on ${moment(lastModifiedOn).format('lll')} `}</div>
        <div className="rrq-list_item_secondary-text">{`by ${processedBy ? processedBy.fullName : initiatedBy.fullName} `}</div>
      </div>
      <div className="controls">
        <Button type="danger" onClick={() => onDelete(id)}>Delete</Button>
      </div>
    </li>
  )
}

export default ResourceRequestListItemAlt;