import React, { PureComponent } from 'react';
import { Checkbox, Button, Input } from 'antd';
import PropTypes from 'prop-types';
import { allChecked, filterFlags, filterLabelsMap } from '../../const/user-search';

import './_usersSearchPanel.scss';

const { Search } = Input;

export default class UsersSearchPanel extends PureComponent {
  render() {
    let {
      onCheckAll,
      onQueryChanged,
      onScopeChanged,
      onSearch,
      onUncheckAll,
      searchQuery,
      searchScope,
    } = this.props;

    return (
      <div className="users-search-panel">
        <div>
          {Object.keys(filterFlags).map((filterValue, i) => (
            <Checkbox
              key={i}
              name={filterValue}
              checked={searchScope & filterFlags[filterValue]}
              onChange={onScopeChanged}
            >
              {filterLabelsMap[filterValue]}
            </Checkbox>
          ))}
          {
            searchScope === allChecked ? (
              <Button type="link" onClick={onUncheckAll}>Uncheck All...</Button>
            ) : (
              <Button type="link" onClick={onCheckAll}>Check All...</Button>
            )
          }
        </div>
        <div>
          <Search
            enterButton
            placeholder="input search text"
            value={searchQuery}
            onChange={onQueryChanged}
            onSearch={onSearch} />
        </div>
      </div>
    );
  }
}

UsersSearchPanel.propTypes = {
  searchScope: PropTypes.number,
  searchQuery: PropTypes.string,
  onCheckAll: PropTypes.func,
  onQueryChanged: PropTypes.func,
  onScopeChanged: PropTypes.func,
  onSearch: PropTypes.func,
  onUncheckAll: PropTypes.func,
};

UsersSearchPanel.defaultProps = {
  searchScope: 0b1111,
  searchQuery: '',
  onCheckAll: () => {},
  onQueryChanged: () => {},
  onScopeChanged: () => {},
  onSearch: () => {},
  onUncheckAll: () => {},
};