import React from 'react';

import AuthLayout from '../../common/layout/auth/AuthLayout';
import ResetPasswordForm from '../../auth/reset-password/ResetPassword';
import resetPasswordImg from '../../assets/images/mountein.jpg';

const ResetPasswordPage = ({ location }) => (
  <AuthLayout path={resetPasswordImg}>
    <ResetPasswordForm location={location}/>
  </AuthLayout>
)

export default ResetPasswordPage;
