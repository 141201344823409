import {
  FETCH__DATAIMPORTFILES,
  FETCH__DATAIMPORTFILES__SUCCESS,
  FETCH__DATAIMPORTFILES__FAIL
} from './actions';

const defaultState = {
  items: [],
  isItemsFetched: false,
  isItemsFetchFailed: false
};

const dataImportFiles = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH__DATAIMPORTFILES:
      return {
        ...state,
        isItemsFetched: true,
        isItemsFetchFailed: false
      };
    case FETCH__DATAIMPORTFILES__FAIL:
      return {
        ...state,
        isItemsFetched: false,
        isItemsFetchFailed: true
      };
    case FETCH__DATAIMPORTFILES__SUCCESS:
        return {
          ...state,
          isItemsFetched: false,
          items: action.data
        };
    default:
      return state;
  }
};

export default dataImportFiles;