import React from 'react';

import './_dashboardCard.less';

const DashboardCard = ({ title, children }) => {
  return (
    <div className="tmf-dashboard-card">
      <h2>{title}</h2>
      <div className="tmf-dashboard-card__content">{children}</div>
    </div>
  );
};

export default DashboardCard;
