import {
  FETCH__SESSIONS,
  FETCH__SESSIONS__SUCCESS,
  FETCH__SESSIONS__FAILED,
  FETCH__SESSION_JOBS,
  FETCH__SESSION_JOBS__FAIL,
  FETCH__SESSION_JOBS__SUCCESS,
  START__SESSION,
  START__SESSION__FAIL,
  START__SESSION__SUCCESS,
  UPDATE__SELECTED_SESSION,
  CLEAR__SELECTED_SESSION,
  CREATE__SESSION,
  CREATE__SESSION__SUCCESS,
  CREATE__SESSION__FAIL,
  CANCEL__SESSION,
  CANCEL__SESSION__FAIL,
  CANCEL__SESSION__SUCCESS
} from './actions';

const defaultState = {
  items: [],
  isSessionsFetched: false,
  isItemsFetchFailed: false,
  sessionJobs: [],
  isJobsFetched: false,
  isJobsFetchFailed: false,
  isStartSessionRequested: false,
  isStartSessionRequestFailed: false,
  isCreateNewRequested: false,
  isCreateNewRequestFailed: false,
  selectedSession: null,
  isCancelSessionRequested: false,
  isCancelSessionFailed: false
};

const dataImportSessions = (state = defaultState, action) => {
  let activeSession;

  switch (action.type) {
    case CANCEL__SESSION:
      return {
        ...state,
        isCancelSessionFailed: false,
        isCancelSessionRequested: true
      };
    case CANCEL__SESSION__SUCCESS:
      return {
        ...state,
        isCancelSessionRequested: false
      };
    case CANCEL__SESSION__FAIL:
      return {
        ...state,
        isCancelSessionFailed: true,
        isCancelSessionRequested: false
      };
    case CREATE__SESSION:
      return {
        ...state,
        isCreateNewRequested: true,
        isCreateNewRequestFailed: false
      };
    case CREATE__SESSION__SUCCESS:
      return {
        ...state,
        isCreateNewRequested: false
      };
    case CREATE__SESSION__FAIL:
      return {
        ...state,
        isCreateNewRequested: false,
        isCreateNewRequestFailed: true
      };
    case START__SESSION:
      return {
        ...state,
        isStartSessionRequested: true,
        isStartSessionRequestFailed: false
      };
    case START__SESSION__FAIL:
      return {
        ...state,
        isStartSessionRequested: false,
        isStartSessionRequestFailed: true
      };
    case UPDATE__SELECTED_SESSION:
      return {
        ...state,
        selectedSession: action.data
      };
    case START__SESSION__SUCCESS:
      return {
        ...state,
        isStartSessionRequested: false,
      }
    case FETCH__SESSIONS:
      return {
        ...state,
        isSessionsFetched: true,
        isItemsFetchFailed: false
      };
    case FETCH__SESSIONS__FAILED:
      return {
        ...state,
        isSessionsFetched: false,
        isItemsFetchFailed: true
      };
    case FETCH__SESSIONS__SUCCESS:
      let { selectedSession } = state;

      activeSession = action.data.find(session => session.status < 4);

      return !!selectedSession || !!activeSession ? {
        ...state,
        isSessionsFetched: false,
        items: action.data,
        selectedSession: !!selectedSession ?
          action.data.find(session => selectedSession.id === session.id)
          : activeSession
      } : {
        ...state,
        isSessionsFetched: false,
        items: action.data
      };
    case FETCH__SESSION_JOBS:
      return {
        ...state,
        isJobsFetched: true,
        isItemsFetchFailed: false
      };
    case FETCH__SESSION_JOBS__FAIL:
      return {
        ...state,
        isJobsFetched: false,
        isItemsFetchFailed: true
      };
    case FETCH__SESSION_JOBS__SUCCESS:
      return {
        ...state,
        isJobsFetched: false,
        sessionJobs: action.data
      }
    case CLEAR__SELECTED_SESSION:
      let { items } = state;
      activeSession = items.find(session => !!session && session.status < 4);  

      return {
        ...state,
        selectedSession: activeSession  
      };
    default:
      return state;
  }
};

export default dataImportSessions;