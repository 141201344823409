import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Button, Card, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const { TextArea } = Input;

class ResourceRequestControlPanel extends Component {
  state = {
    declineText: ''
  }

  handleDeclineTextChange = (e) => {
    let { target: { value }} = e;

    this.setState({ declineText: value});
  }

  handleDecline = () => {
    let { declineText } = this.state;
    let { onDecline } = this.props;

    onDecline(declineText);
  }

  render() {
    let {
      itemToLink,
      onDropSelection,
      onApprove,
      requestStatus,
      resourceRequests: {
        isOperationInProgress
      }
    } = this.props;
    let { declineText } = this.state;
    
    return requestStatus === 1 ?
    (
      <div className="rsrq-control-panel">
        <h3>Actions</h3>
        {
          requestStatus === 1 &&
          <Fragment>
            <Card
              size="small"
              title="Approve"
              actions={[
                <Button
                  loading={isOperationInProgress}
                  disabled={!itemToLink}
                  onClick={onApprove}
                  type="primary">Approve</Button>
              ]}>
                {
                  !itemToLink &&
                  <p>Please, doubleclick on one of the items from "Best Matches" list to pick resource you would like to reference with user account.</p>
                }
                {
                  !!itemToLink &&
                  <div className="resource-card">
                    <div className="resource-card--header">
                      <span className="resource-card--title">{itemToLink.resourceType === 0 ? 'Loan' : 'Investment'}</span>
                      <Button onClick={onDropSelection} size="small" type="link"><CloseOutlined /></Button>
                    </div>
                    <div>
                      <p>{itemToLink.number}</p>
                      <p>{itemToLink.name}</p>
                    </div>
                  </div>
                }
            </Card>
            <Card
              size="small"
              title="Decline"
              actions={[
                <Button
                  ghost
                  type="danger"
                  onClick={this.handleDecline}
                  disabled={!declineText}>Decline</Button>
              ]}>
              <p><strong>IMPORTANT!</strong> Text entered into the text area below will be visible to the user</p>
              <TextArea
                rows={3}
                className="rsrq-control-panel_decline-text"
                text={declineText}
                onChange={this.handleDeclineTextChange} />
            </Card>
          </Fragment>
        }
      </div>
    ) : null;
  }
}

export default connect(
  (resourceRequests) => (resourceRequests)
)(ResourceRequestControlPanel);