export const statuses = {
  open: 0,
  accepted: 1,
  declined: 2,
  approved: 3
};

export const statusDescriptionMap = {
  0: 'Open',
  1: 'Accepted',
  2: 'Declined',
  3: 'Approved'
};