import axios from 'axios';
import { geoEndpoints } from '../../const/backend';
import {
  FETCH_STATES,
  FETCH_STATES_SUCCESS,
  FETCH_STATES_FAILED,
  FETCH_CITIES,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILED,
} from './actions';

export const fetchStates = () => dispatch => {
  dispatch({ type: FETCH_STATES });

  axios.get(
    geoEndpoints.states()
  ).then(response => {
    if (response.status === 200) {
      dispatch({
        type: FETCH_STATES_SUCCESS,
        states: response.data
      });
    }
  }).catch(() => {
    dispatch({ type: FETCH_STATES_FAILED });
  });
}

export const fetchCities = (stateId) => dispatch => {
  dispatch({ type: FETCH_CITIES });

  axios.get(
    geoEndpoints.cities(stateId)
  ).then(response => {
    if (response.status === 200) {
      dispatch({
        type: FETCH_CITIES_SUCCESS,
        cities: response.data
      });
    }
  }).catch(() => {
    dispatch({ type: FETCH_CITIES_FAILED });
  });
}

