import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Result, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { routes, routeBuilder } from '../../routing';
import { fetchAssetDetails } from '../../store/assetDetails/actionCreators';
import UserAccountPage from '../../common/pages/user-account-page/UserAccountPage';
import AssetStatementsTable from '../../components/AssetStatementsTable/AssetStatementsTable';

import './asset-details.less';

const AssetDetailsPage = ({
  match: {
    params: {
      userId,
      investmentId,
      assetId
    }
  },
  user: {
    token
  },
  assetDetails: {
    assetDetails,
    isAssetDetailsFetched,
    isAssetDetailsFetchFailed,
  },
  fetchAssetDetails
}) => {
  const investmentLink = !userId
    ? routeBuilder.investmentDetails(investmentId)
    : routeBuilder.userAccountInvestmentDetails(userId, investmentId);
  const homeLink = !userId
    ? routes.userInvestments
    : routeBuilder.userAccountInvestments(userId);

  useEffect(() => {
    fetchAssetDetails(userId, investmentId, assetId, token);
  }, [userId, investmentId, assetId, token]);

  if (isAssetDetailsFetched) {
    return (
      <Spin
        size="large"
        className="rsrq-details_spinner"
        indicator={
          <LoadingOutlined spin />
        }
      />
    );
  }

  if (isAssetDetailsFetchFailed || !assetDetails) {
    return (
      <Result
        status="error"
        title="Something went wrong"
        subTitle="Please try to refresh page a bit later. If situation still take place - contact support"
        extra={(
          <Button type="ghost" onClick={() => fetchAssetDetails(userId, investmentId, assetId, token)}>Refresh Page</Button>
        )}
      />
    );
  }

  return (
    <UserAccountPage
      userId={userId}
      loadProfile
    >
      <div className="asset-details-page">
        <div className="asset-details-page_header">
          <h3>
            <Link to={homeLink} className="asset-details-page_number">Investments</Link>
            <span className="asset-details-page_number">/</span>
            <Link to={investmentLink}>{assetDetails.investmentNumber}: {assetDetails.investmentName}</Link>
            <span> /</span>
            <span className="asset-details-page_description">{assetDetails.number} {assetDetails.name}</span>
          </h3>
        </div>
        <div className="asset-details-page_content">
          <h3>History</h3>
          <AssetStatementsTable statements={assetDetails.statements} />
        </div>
      </div>
    </UserAccountPage>
  )
}

export default connect(
  ({ user, assetDetails }) => ({ user, assetDetails }),
  { fetchAssetDetails }
)(AssetDetailsPage);
