export const baseUrl =
  typeof window !== 'undefined'
    ? window.location.origin
    : 'https://localhost';

const authApiPrefix = `${baseUrl}/api/auth`;

export const authorizedRequestHeaders = (token) => ({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${token}`,
});

export const authEndpoints = {
  login: () => (`${authApiPrefix}/login`),
  register: () => (`${authApiPrefix}/register`),
  resetPassword: () => (`${authApiPrefix}/resetpassword`),
  confirmEmail: () => (`${authApiPrefix}/confirmemail`),
  verifyChangePasswordToken: () => (`${authApiPrefix}/verifychangepasswordtoken`),
  changePassword: () => (`${authApiPrefix}/changepassword`),
}

export const geoEndpoints = {
  states: () => (`${baseUrl}/api/states`),
  cities: (stateId) => (`${baseUrl}/api/states/${stateId}/cities`),
}

export const usersEndpoints = {
  list: (skip, limit, search, cityId, view) => {
    let requestUrl = `${baseUrl}/api/users?skip=${skip}&limit=${limit}&preDefinedFilter=${view}`;

    if (!!search && !!search.query) {
      requestUrl = `${requestUrl}&searchOptions=${search.scope}&search=${search.query}`;
    }

    if (!!cityId) {
      requestUrl = `${requestUrl}&cityId=${cityId}`;
    }

    return requestUrl
  },
  promote: () => (`${baseUrl}/api/users/promote`),
  revoke: () => (`${baseUrl}/api/users/revoke`),
  delete: (userId) => (`${baseUrl}/api/users/${userId}`),
}

export const dataImportEndpoints = {
  dataImport: () => (`${baseUrl}/api/files/dataimport`),
}

export const userProfileEndpoints = {
  get: () => (`${baseUrl}/api/userProfile`),
  settings: () => (`${baseUrl}/api/users/profile`),
}

export const resourceRequestEndpoints = {
  root: () => `${baseUrl}/api/resourceRequest`
}

export const loanDetailsEndpoints = {
  get: (loanId) => (`${baseUrl}/api/loans/${loanId}`),
}

export const investmentDetailsEndpoint = {
  get: investmentId => (`${baseUrl}/api/investments/${investmentId}`),
  assetDetails: (investmentId, assetId) => (`${baseUrl}/api/investments/${investmentId}/assets/${assetId}`),
  accountStatements: investmentId => (`${baseUrl}/api/investments/${investmentId}/accountstatements`),
}

export const statementEndpoints = {
  moveToVault: statementId => (`${baseUrl}/api/accountstatements/${statementId}/movetovaultrequest`),
  downloadStatement: statementId => (`${baseUrl}/api/accountstatements/${statementId}/downloadFileRequest`),
  userStatements: () => (`${baseUrl}/api/statements`),
  scanRepository: () => (`${baseUrl}/api/accountstatements/scanRepository`)
}

export const dataImportSessionEndpoints = {
  list: () => (`${baseUrl}/api/dataimportsessions`),
  sessionJobs: (sessionId) => (`${baseUrl}/api/dataimportsessions/${sessionId}/jobs`),
  startSession: (sessionId) => (`${baseUrl}/api/dataimportsessions/${sessionId}/start`),
  createSession: () => (`${baseUrl}/api/dataimportsessions`),
  cancelSession: (sessionId) => (`${baseUrl}/api/dataimportsessions/${sessionId}/cancel`)
}

export const dataImportFilesEndpoints = {
  listBySessionId: (sessionId) => (`${baseUrl}/api/dataimportfiles?sessionId=${sessionId}`),
};

export const adminUserProfileEndpoints = {
  profile: (userId) => (`${baseUrl}/api/userprofile/${userId}/extended`),
  loans: (userId) => (`${baseUrl}/api/userprofile/${userId}/loans`),
  investments: (userId) => (`${baseUrl}/api/userprofile/${userId}/investments`),
  requests: (userId) => (`${baseUrl}/api/userprofile/${userId}/resourcerequests`),
  confirmEmail: (userId) => (`${baseUrl}/api/userprofile/${userId}/confirmEmail`),
  unlinkLoan: (userId, loanId) => (`${baseUrl}/api/userprofile/${userId}/loans/${loanId}/unlink`),
  unlinkInvestment: (userId, investmentId) => (`${baseUrl}/api/userprofile/${userId}/investments/${investmentId}/unlink`),
  deleteResourceRequest: (userId, requestId) => (`${baseUrl}/api/userprofile/${userId}/resourcerequests/${requestId}`),
};

export const syncJobsEndpoints = {
  getActiveRepositoryScanJob: () => (`${baseUrl}/api/syncjobs/repository-scan`),
};
