import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defaultRoutes } from './';

const AuthorizedRoute = ({
  render,
  user,
  requiredRole,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      !user.token
        ? <Redirect to={defaultRoutes.login} />
        : user.role !== requiredRole
          ? <Redirect to={defaultRoutes.root} />
          : render(props)
    )}
  />
);

AuthorizedRoute.propTypes = {
  requiredRole: PropTypes.string.isRequired,
  user: PropTypes.shape({
    token: PropTypes.string,
    role: PropTypes.string
  })
};

export default connect(({ user }) => ({ user }))(AuthorizedRoute);
