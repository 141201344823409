import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { routes, routeBuilder } from '../../routing';

import { Badge } from 'reactstrap';

import './UserDetailsCard.css';

class UserDetailsCard extends Component {
  render() {
    let {
      userDetails: {
        id,
        fullName,
        organizationName,
        emailAddress,
        registeredOn,
        isAdmin,
        city,
        county,
        state,
        loans,
        investments
      }
    } = this.props;

    var loanNodes = !!loans && loans.map((loan, index) => {
      return (
        <p key={index}>
          <strong>{loan.number}</strong>{loan.name}
        </p>
      );
    });

    var investmentNodes = !!investments && investments.map((investment, index) => {
      return (
        <p key={index}>
          <strong>{investment.number}</strong>{investment.name}
        </p>
      );
    });

    return (
      <div className="user-details">
        <h3>User details</h3>
        <label htmlFor="fullName">Full Name {isAdmin && <Badge color="primary">admin</Badge>} </label>
        <p id="fullName"><Link to={routeBuilder.userAccountLoans(id)}>{fullName}</Link></p>
        {
          !!organizationName &&
          <Fragment>
            <label htmlFor="organizationName">Organization Name</label>
            <p id="organizationName">{organizationName}</p>
          </Fragment>
        }
        <label>Address</label>
        <address>
          {city} <br/>
          <i>{state}</i>
        </address>
        <label>Email Address</label>
        <p>{emailAddress}</p>
        <label>Registration Date</label>
        <p>{moment(registeredOn).format('MMMM Do, YYYY')}</p>
        {
          !!loanNodes &&
          <div className="user-details-list">
            <label>Loans</label>
            { loanNodes.length > 0 && loanNodes }
            { loanNodes.length === 0 && <p>NO LOANS YET</p>}
          </div>
        }
        {
          !!investmentNodes &&
          <div className="user-details-list">
            <label>Investments</label>
            { investmentNodes.length > 0 && investmentNodes }
            { investmentNodes.length === 0 && <p>NO INVESTMETNS YET</p>}
          </div>
        }
      </div>
    )
  }
}

UserDetailsCard.propTypes = {
  userDetails: PropTypes.shape({
    id: PropTypes.number,
    fullName: PropTypes.string
  })
}

export default UserDetailsCard;
