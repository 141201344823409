import axios from 'axios';
import { loanDetailsEndpoints } from '../../const/backend';
import { actionTemplate, authHeaders } from '../shared';
import { LOG_OUT } from '../user/actions';
import {
  FETCH__LOAN,
  FETCH__LOAN__FAIL,
  FETCH__LOAN__SUCCESS,
} from './actions';

export const fetchLoan = (loanId, token) => dispatch => {
  dispatch(actionTemplate(FETCH__LOAN));

  axios.get(
    loanDetailsEndpoints.get(loanId), {
      headers: authHeaders(token)
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({...actionTemplate(FETCH__LOAN__SUCCESS), details: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__LOAN__FAIL))
  });
}