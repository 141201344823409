import React from 'react';
import { Button } from 'antd';

import './highlight.less';

const Highlight = ({
  headerText,
  contentText,
  className,
  actions
}) => (
  <div className={`${className} tmf-highlight`}>
    <div className="tmf-highlight__header">
      <div className="tmf-highlight__header-text">{headerText}</div>
      {
        actions && (
          <div className="tmf-highlight__actions">
            {
              actions.map(action => (
                <Button
                  type="link"
                  size="small"
                  onClick={action.action}
                >
                  {action.label}
                </Button>
              ))
            }
          </div>
        )
      }
    </div>
    <p>{contentText}</p>
  </div>
);

export default Highlight;
