import {
  REQUEST__MOVE_TO_VAULT,
  REQUEST__MOVE_TO_VAULT_FAIL,
  REQUEST__MOVE_TO_VAULT_SUCCESS,
  DOWNLOAD__VAULT_FILE,
  DOWNLOAD__VAULT_FILE__FAIL,
  DOWNLOAD__VAULT_FILE__SUCCESS,
  FETCH__USER_STATEMENTS,
  FETCH__USER_STATEMENTS__SUCCESS,
  FETCH__USER_STATEMENTS__FAIL,
} from './actions';

const defaultState = {
  isMoveToVaultRequested: false,
  isMoveToVaultFailed: false,
  fileIdRequesed: undefined,
  isDownloadRequested: false,
  isDownloadFailed: false,
  downloadFileId: undefined,
  userStatements: [],
  isUserStatementsRequested: false,
  isUserStatementsRequestFailed: false,
};

const statements = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST__MOVE_TO_VAULT:
      return {
        ...state,
        isMoveToVaultRequested: true,
        isMoveToVaultFailed: false,
        fileIdRequesed: action.fileId
      };
    case REQUEST__MOVE_TO_VAULT_FAIL:
      return {
        ...state,
        isMoveToVaultRequested: false,
        isMoveToVaultFailed: true
      };
    case REQUEST__MOVE_TO_VAULT_SUCCESS:
      return {
        ...state,
        isMoveToVaultRequested: false,
        fileIdRequesed: undefined
      };
    case DOWNLOAD__VAULT_FILE:
      return {
        ...state,
        isDownloadRequested: true,
        isDownloadFailed: false,
        downloadFileId: action.fileId
      };
    case DOWNLOAD__VAULT_FILE__FAIL:
      return {
        ...state,
        isDownloadRequested: false,
        isDownloadFailed: true,
        downloadFileId: undefined
      };
    case DOWNLOAD__VAULT_FILE__SUCCESS:
      return {
        ...state,
        isDownloadRequested: false,
        downloadFileId: undefined
      };
    case FETCH__USER_STATEMENTS:
      return {
        ...state,
        isUserStatementsRequested: true,
        isUserStatementsRequestFailed: false,
      };
    case FETCH__USER_STATEMENTS__SUCCESS:
      return {
        ...state,
        isUserStatementsRequested: false,
        userStatements: action.statements,
      };
    case FETCH__USER_STATEMENTS__FAIL:
      return {
        ...state,
        isUserStatementsRequested: false,
        isUserStatementsRequestFailed: true,
      };
    default:
      return state;
  }
}

export default statements;