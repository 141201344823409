import {
  FETCH__ADMIN_USER_PROFILE,
  FETCH__ADMIN_USER_PROFILE__FAIL,
  FETCH__ADMIN_USER_PROFILE__SUCCESS,
  CLEAR__ADMIN_USER_PROFILE,
  FETCH__USER_LOANS,
  FETCH__USER_LOANS__SUCCESS,
  FETCH__USER_LOANS__FAIL,
  FETCH__USER_ACCOUNTS,
  FETCH__USER_ACCOUNTS__FAIL,
  FETCH__USER_ACCOUNTS__SUCCESS,
  FETCH__USER_REQUESTS,
  FETCH__USER_REQUESTS__SUCCESS,
  FETCH__USER_REQUESTS__FAIL,
  CONFIRM__PROFILE__USER_EMAIL,
  CONFIRM__PROFILE__USER_EMAIL__SUCCESS,
  CONFIRM__PROFILE__USER_EMAIL__FAIL,
  UNLINK__LOAN,
  UNLINK__LOAN__SUCCESS,
  UNLINK__LOAN__FAIL,
  UNLINK__INVESTMENT,
  UNLINK__INVESTMENT__FAIL,
  UNLINK__INVESTMENT__SUCCESS,
  DELETE__PROFILE__RESOURCE_REQUEST,
  DELETE__PROFILE__RESOURCE_REQUEST__FAIL,
  DELETE__PROFILE__RESOURCE_REQUEST__SUCCESS
} from './actions';

const defaultState = {
  profile: null,
  isProfileFetched: false,
  isProfileFetchFailed: false,
  loans: null,
  isLoansFetched: false,
  isLoansFetchFailed: false,
  investments: null,
  isInvestmentsFetched: false,
  isInvestmentsFetchFailed: false,
  requests: null,
  isRequestsFetched: false,
  isRequestsFetchFailed: false,
  isConfirmEmailRequested: false,
  isConfirmEmailFailed: false,
  isUnlinkLoanFetched: false,
  isUnlinkLoanFailed: false,
  isUnlinkInvestmentFetched: false,
  isUnlinkInvestmentFailed: false,
  isDeleteResourceRequestFetched: false,
  isDeleteResourceRequestFailed: false
};

const adminUserProfile = (state = defaultState, action) => {
  switch (action.type)
  {
    case DELETE__PROFILE__RESOURCE_REQUEST:
      return {
        ...state,
        isDeleteResourceRequestFetched: true,
        isDeleteResourceRequestFailed: false
      };
    case DELETE__PROFILE__RESOURCE_REQUEST__FAIL:
      return {
        ...state,
        isDeleteResourceRequestFetched: false,
        isDeleteResourceRequestFailed: true
      };
    case DELETE__PROFILE__RESOURCE_REQUEST__SUCCESS:
      return {
        ...state,
        isDeleteResourceRequestFetched: false
      };
    case UNLINK__INVESTMENT:
      return {
        ...state,
        isUnlinkInvestmentFailed: false,
        isUnlinkInvestmentFetched: true
      };
    case UNLINK__INVESTMENT__FAIL:
      return {
        ...state,
        isUnlinkInvestmentFailed: true,
        isUnlinkInvestmentFetched: false
      };
    case UNLINK__INVESTMENT__SUCCESS:
      return {
        ...state,
        isUnlinkInvestmentFetched: false
      };
    case UNLINK__LOAN:
      return {
        ...state,
        isUnlinkLoanFailed: false,
        isUnlinkLoanFetched: true
      };
    case UNLINK__LOAN__FAIL:
      return {
        ...state,
        isUnlinkLoanFailed: true,
        isUnlinkLoanFetched: false
      };
    case UNLINK__LOAN__SUCCESS:
      return {
        ...state,
        isUnlinkLoanFetched: false
      };
    case CONFIRM__PROFILE__USER_EMAIL:
      return {
        ...state,
        isConfirmEmailFailed: false,
        isConfirmEmailRequested: true
      };
    case CONFIRM__PROFILE__USER_EMAIL__SUCCESS:
      return {
        ...state,
        isConfirmEmailRequested: false
      };
    case CONFIRM__PROFILE__USER_EMAIL__FAIL:
      return {
        ...state,
        isConfirmEmailFailed: true,
        isConfirmEmailRequested: false
      };
    case FETCH__USER_REQUESTS:
      return {
        ...state,
        isRequestsFetched: true,
        isRequestsFetchFailed: false
      };
    case FETCH__USER_REQUESTS__FAIL:
      return {
        ...state,
        isRequestsFetched: false,
        isRequestsFetchFailed: true
      };
    case FETCH__USER_REQUESTS__SUCCESS:
      return {
        ...state,
        isRequestsFetched: false,
        requests: action.data
      };
    case FETCH__USER_ACCOUNTS:
      return {
        ...state,
        isInvestmentsFetched: true,
        isInvestmentsFetchFailed: false
      };
    case FETCH__USER_ACCOUNTS__FAIL:
      return {
        ...state,
        isInvestmentsFetched: false,
        isInvestmentsFetchFailed: true
      };
    case FETCH__USER_ACCOUNTS__SUCCESS:
      return {
        ...state,
        isInvestmentsFetched: false,
        investments: action.data
      };
    case FETCH__ADMIN_USER_PROFILE:
      return {
        ...state,
        isProfileFetched: true,
        isProfileFetchFailed: false
      };
    case FETCH__ADMIN_USER_PROFILE__FAIL:
      return {
        ...state,
        isProfileFetched: false,
        isProfileFetchFailed: true
      };
    case FETCH__ADMIN_USER_PROFILE__SUCCESS:
      return {
        ...state,
        isProfileFetched: false,
        profile: action.data
      };
    case CLEAR__ADMIN_USER_PROFILE:
      return {
        ...state,
        ...defaultState
      };
    case FETCH__USER_LOANS:
      return {
        ...state,
        isLoansFetched: true,
        isLoansFetchFailed: false
      };
    case FETCH__USER_LOANS__FAIL:
      return {
        ...state,
        isLoansFetched: false,
        isLoansFetchFailed: true
      };
    case FETCH__USER_LOANS__SUCCESS:
      return {
        ...state,
        isLoansFetched: false,
        loans: action.data
      };
    default:
      return state;
  }
};

export default adminUserProfile;