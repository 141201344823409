import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { Input, Button, Select, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { fetchStates } from '../../store/geo/actionCreators';
import { fetchUserProfileSettings, resetProfileSettings, updateProfileSettings } from '../../store/userProfile/actionCreators';
import { initialFormValues, profileSettingsSchema } from './formik.config';
import FormControl from '../../common/FormControl/FormControl';
import registerFormLabels from '../../labels/register-form';

import './profile-settings.less';

const { Password } = Input;
const { Option } = Select;

const ProfileSettingsForm = ({
  fetchStates,
  fetchUserProfileSettings,
  updateProfileSettings,
  resetProfileSettings,
  geo: {
    states,
    isStatesRequested,
    isStatesRequestFailed,
  },
  userProfile: {
    isSettingsRequested,
    isSettingsUpdateInProgress,
    settings,
  },
  user: {
    token,
    email
  },
}) => {
  const [formValues, setFormValues] = useState(initialFormValues);

  useEffect(() => {
    if (!settings) {
      fetchStates();
      fetchUserProfileSettings(token);
    } else {
      setFormValues({
        ...initialFormValues,
        ...settings,
        email
      });
    }
  }, [settings]);

  useEffect(() => () => { resetProfileSettings(); }, [])

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: profileSettingsSchema,
    onSubmit: (values) => {
      updateProfileSettings(values, token)
        .then(() => {
          fetchUserProfileSettings(token)
            .then(() => {
              message.success('The changes have been saved', 5);
            })
        })
        .catch( () => {
          message.error('The changes have not been saved', 5);
        });
    }
  });

  const handleFilterOption = (inputValue, option) => {
    let { label } = option;

    return label
      .toLowerCase()
      .includes(inputValue.toLowerCase());
  };

  const handleStateChange = (value) => {
    formik.setFieldValue('stateId', value);
  };

  if (isSettingsRequested || !settings) {
    return (
      <Spin
        size="large"
        className="rsrq-details_spinner"
        indicator={
          <LoadingOutlined spin />
        }
      />
    );
  }

  return (
    <form onSubmit={formik.handleSubmit} className="profile-settings-form">
      <FormControl
        id="email"
        caption={registerFormLabels.controls.email.label}
        touched={formik.touched.email}
        error={formik.errors.email}
      >
        <Input type="email" id="email" disabled {...formik.getFieldProps('email')} />
      </FormControl>
      <FormControl
        id="fullName"
        caption={registerFormLabels.controls.fullName.label}
        touched={formik.touched.fullName}
        error={formik.errors.fullName}
      >
        <Input id="fullName" {...formik.getFieldProps('fullName')} disabled={isSettingsUpdateInProgress} />
      </FormControl>
      <FormControl
        id="organizationName"
        caption={registerFormLabels.controls.organization.label}
        touched={formik.touched.organization}
        error={formik.errors.organization}
      >
        <Input id="organizationName" {...formik.getFieldProps('organizationName')} disabled={isSettingsUpdateInProgress} />
      </FormControl>
      <FormControl
        id="stateId"
        caption={registerFormLabels.controls.state.label}
      >
        <Select
          id="stateId"
          showSearch
          disabled={!states || isStatesRequested || isStatesRequestFailed || isSettingsUpdateInProgress}
          value={formik.values.stateId}
          filterOption={handleFilterOption}
          loading={isStatesRequested}
          style={{ width: '100%' }}
          onBlur={formik.onBlur}
          onChange={handleStateChange}
        >
          {
            states && states.map((state) => (
              <Option
                key={`state-${state.id}`}
                value={state.id}
                label={state.name}
              >
                {state.name}
              </Option>
            ))
          }
        </Select>
      </FormControl>
      <FormControl
        id="city"
        caption={registerFormLabels.controls.city.label}
        touched={formik.touched.city}
        error={formik.errors.city}
      >
        <Input id="city" {...formik.getFieldProps('city')} />
      </FormControl>
      <FormControl
        id="password"
        caption={registerFormLabels.controls.password.label}
        touched={formik.touched.password}
        error={formik.errors.password}
      >
        <Password
          id="password"
          disabled={isSettingsUpdateInProgress}
          {...formik.getFieldProps('password')}
        />
      </FormControl>
      <FormControl
        id="confirmPassword"
        caption={registerFormLabels.controls.confirmPassword.label}
        touched={formik.touched.confirmPassword}
        error={formik.errors.confirmPassword}
        autoComplete="new-password"
      >
        <Password
          id="confirmPassword"
          autoComplete="new-password"
          disabled={isSettingsUpdateInProgress}
          {...formik.getFieldProps('confirmPassword')}
        />
      </FormControl>
      <div className="profile-settings_controls">
        <Button
          size='large'
          type='primary'
          htmlType='submit'
          loading={isSettingsUpdateInProgress}
        >
          Save changes
        </Button>
      </div>
    </form>
  );
};

export default connect(
  ({ user, geo, userProfile }) => ({ user, geo, userProfile }),
  { fetchStates, fetchUserProfileSettings, updateProfileSettings, resetProfileSettings }
)(ProfileSettingsForm);
