import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { verifyChangePasswordToken, changePassword, resetChangePasswordState } from '../../store/user/actionCreators';
import { Spin, Button, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import FormControl from '../../common/FormControl/FormControl';
import setNewPasswordLabels from '../../labels/set-new-password';
import { resetPasswordSchema } from './formik.config';
import { routes } from '../../routing';
import './reset-password.less';

const { Password } = Input;

const MODE_LOADER = 'MODE_LOADER';
const MODE_VERIFICATION_FAILED = 'MODE_VERIFICATION_FAILED';
const MODE_PASSWORD_CHANGE_FORM = 'MODE_PASSWORD_CHANGE_FORM';
const MODE_PASSWORD_CHANGE__SUCCESS = 'MODE_PASSWORD_CHANGE__SUCCESS';
const MODE_PASSWORD_CHANGE__FAIL = 'MODE_PASSWORD_CHANGE__FAIL';

const ResetPasswordForm = ({
  user: {
    isVerifyChangePasswordTokenRequested,
    isVerifyChangePasswordTokenRequestFailed,
    isChangePasswordRequested,
    isChangePasswordRequestFailed,
    changePasswordAttempts
  },
  location: {
    search
  },
  verifyChangePasswordToken,
  resetChangePasswordState,
  changePassword,
}) => {
  const formik = useFormik({
    initialValues: { password: '', confirmPassword: '' },
    validationSchema: resetPasswordSchema,
    onSubmit: ({ password }) => {
      let urlParams = new URLSearchParams(search);

      changePassword(
        urlParams.get('id'),
        urlParams.get('token'),
        password
      );
    }
  });
  const mode = classNames({
    [MODE_LOADER]: isVerifyChangePasswordTokenRequested,
    [MODE_VERIFICATION_FAILED]:
      !isVerifyChangePasswordTokenRequested
      && isVerifyChangePasswordTokenRequestFailed,
    [MODE_PASSWORD_CHANGE_FORM]:
      !isVerifyChangePasswordTokenRequestFailed
      && !isVerifyChangePasswordTokenRequested
      && !isChangePasswordRequestFailed
      && (isChangePasswordRequested || changePasswordAttempts === 0),
    [MODE_PASSWORD_CHANGE__SUCCESS]:
      !isChangePasswordRequested
      && !isChangePasswordRequestFailed
      && changePasswordAttempts > 0,
    [MODE_PASSWORD_CHANGE__FAIL]:
      !isChangePasswordRequested
      && isChangePasswordRequestFailed
      && changePasswordAttempts > 0
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(search);

    verifyChangePasswordToken(urlParams.get('id'), urlParams.get('token'));

    return () => { resetChangePasswordState(); }
  }, []);

  return (
    <div className="reset-password-form">
      <h1>{setNewPasswordLabels.header}</h1>
        {
          mode === MODE_LOADER &&
            <Spin
              className="reset-password-form_spinner"
              size='large'
              indicator={<LoadingOutlined spin />}
            />
        }
        {
          (mode === MODE_VERIFICATION_FAILED || mode === MODE_PASSWORD_CHANGE__FAIL) &&
            <>
              <h2>{setNewPasswordLabels.result.fail.subHeader}</h2>
              <p>{setNewPasswordLabels.result.fail.invalidTokenDescription}</p>
              <div className='reset-password-form_form-controls'>
                <Button type='link'>
                  <Link to={routes.login}>
                    {setNewPasswordLabels.buttons.goToLogin}
                  </Link>
                </Button>
              </div>
            </>
        }
        {
          mode === MODE_PASSWORD_CHANGE__SUCCESS &&
            <>
              <h2>{setNewPasswordLabels.result.success.subHeader}</h2>
              <div className='reset-password-form_form-controls'>
                <Button type='link'>
                  <Link to={routes.login}>
                    {setNewPasswordLabels.buttons.goToLogin}
                  </Link>
                </Button>
              </div>
            </>
        }
        {
          mode === MODE_PASSWORD_CHANGE_FORM &&
            <form onSubmit={formik.handleSubmit}>
              <FormControl
                id="password"
                caption="Password"
                touched={formik.touched.password}
                error={formik.errors.password}
              >
                <Password id="password" {...formik.getFieldProps('password')} />
              </FormControl>
              <FormControl
                id="confirmPassword"
                caption="Confirm Password"
                touched={formik.touched.confirmPassword}
                error={formik.errors.confirmPassword}
              >
                <Password id="confirmPassword" {...formik.getFieldProps('confirmPassword')} />
              </FormControl>
              <div className='reset-password-form_form-controls'>
                <Button
                  tabIndex={8}
                  type="primary"
                  htmlType="submit"
                  loading={isChangePasswordRequested}
                >
                  {setNewPasswordLabels.buttons.changePassword}
                </Button>
                <Button tabIndex={9} type="link">
                  <Link to={routes.login}>
                    {setNewPasswordLabels.buttons.goToLogin}
                  </Link>
                </Button>
              </div>
            </form>
        }
    </div>
  );
};

export default connect(
  ({ user }) => ({ user }),
  { verifyChangePasswordToken, changePassword, resetChangePasswordState }
)(ResetPasswordForm);
