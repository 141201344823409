import { FETCH__SESSIONS__SUCCESS } from '../dataImportSessions/actions';
import {
  FETCH__STATEMENT_SCAN_STATUS,
  FETCH__STATEMENT_SCAN_STATUS__FAIL,
  FETCH__STATEMENT_SCAN_STATUS__SUCCESS,
  START__STATEMENTS_SCAN,
  START__STATEMENTS_SCAN__FAIL,
  START__STATEMENTS_SCAN__SUCCESS
} from './actions';

const defaultState = {
  fetchStatementScanStatus: false,
  fetchStatementScanFailed: false,
  isScanInProgress: false,
  isStartingStatementsScan: false,
  startingStatementScanFailed: false,
};

const dashboard = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH__STATEMENT_SCAN_STATUS:
      return {
        ...state,
        fetchStatementScanStatus: true,
        fetchStatementScanFailed: false,
      };
    case FETCH__STATEMENT_SCAN_STATUS__SUCCESS:
      return {
        ...state,
        fetchStatementScanStatus: false,
        isScanInProgress: action.isScanInProgress,
      };
    case FETCH__STATEMENT_SCAN_STATUS__FAIL:
      return {
        ...state,
        fetchStatementScanStatus: false,
        fetchStatementScanFailed: true,
        isScanInProgress: false,
      };
    case START__STATEMENTS_SCAN:
      return {
        ...state,
        isStartingStatementsScan: true,
        startingStatementScanFailed: false,
      };
    case START__STATEMENTS_SCAN__SUCCESS:
      return {
        ...state,
        isStartingStatementsScan: false,
      };
    case START__STATEMENTS_SCAN__FAIL:
      return {
        ...state,
        isStartingStatementsScan: false,
        startingStatementScanFailed: true
      };
    default:
      return state;
  }
};

export default dashboard;