export const IMPORT_JOB_STATUS__PENDING = 0;
export const IMPORT_JOB_STATUS__IN_PROGRESS = 1;
export const IMPORT_JOB_STATUS__SUCCEDED = 2;
export const IMPORT_JOB_STATUS__FAILED = 3;
export const totalJobsNumber = 14;

export const importStageKeyProvider = (title) => title.toLowerCase().split(' ').join('-');

export const sessionStatuses = {
  created: 1,
  ready: 2,
  inProgress: 3,
  succeeded: 4,
  failed: 5
};

export const importStages = [{
  title: 'User data flow',
  description: 'description',
  steps: [{
    title: 'Accounts',
    status: IMPORT_JOB_STATUS__PENDING,
    dataImportJobType: 9,
    steps: [{
      title: 'Account Assets',
      status: IMPORT_JOB_STATUS__PENDING,
      dataImportJobType: 11,
      steps: [{
        title: 'Account Activities',
        status: IMPORT_JOB_STATUS__PENDING,
        dataImportJobType: 12
      }, {
        title: 'Account Asset Histories',
        status: IMPORT_JOB_STATUS__PENDING,
        dataImportJobType: 13
      }]
    }, {
        title: 'Account Activity Summaries',
        status: IMPORT_JOB_STATUS__PENDING,
        dataImportJobType: 10
      }]
  }, {
    title: 'Loans',
    status: IMPORT_JOB_STATUS__PENDING,
    dataImportJobType: 14,
    steps: [{
      title: 'Loan Lines',
      status: IMPORT_JOB_STATUS__PENDING,
      dataImportJobType: 16
    }]
  }]
}, {
  title: 'Unlinked data flow',
  description: 'description',
  steps: [{
    title: 'Accounts',
    status: IMPORT_JOB_STATUS__PENDING,
    dataImportJobType: 1,
    steps: [{
      title: 'Account Assets',
      status: IMPORT_JOB_STATUS__PENDING,
      dataImportJobType: 4,
      steps: [{
        title: 'Account Activities',
        status: IMPORT_JOB_STATUS__PENDING,
        dataImportJobType: 2
      }, {
        title: 'Account Asset Histories',
        status: IMPORT_JOB_STATUS__PENDING,
        dataImportJobType: 5
      }]
    }, {
        title: 'Account Activity Summaries',
        status: IMPORT_JOB_STATUS__PENDING,
        dataImportJobType: 3
      }]
  }, {
    title: 'Loans',
    status: IMPORT_JOB_STATUS__PENDING,
    dataImportJobType: 6,
    steps: [{
      title: 'Loan Lines',
      status: IMPORT_JOB_STATUS__PENDING,
      dataImportJobType: 8
    }]
  }]
}]