export const LOGIN_USER = 'LOGIN_USER';

export const FETCH_USER_TOKEN = 'FETCH_USER_TOKEN';
export const RECIEVE_USER_TOKEN = 'RECEIVE_USER_TOKEN';

export const REQUEST_RESOURCE_REQUESTS = 'REQUEST_RESOURCE_REQUESTS';
export const FETCH_RESOURCE_REQUESTS = 'FETCH_RESOURCE_REQUESTS';
export const RECEIVE_RESOURCE_REQUESTS = 'RECEIVE_RESOURCE_REQUESTS';

export const REQUEST_RSRQ_DETAILS = 'REQUEST_RSRQ_DETAILS';
export const RECEIVE_RSRQ_DETAILS = 'RECEIVE_RSRQ_DETAILS';
export const RSRQ_APPROVE = 'RSRQ_APPROVE';
export const HANDLE_RSRQ_APPROVE = 'HANDLE_RSRQ_APPROVE';
export const RSRQ_ACCEPT = 'RSRQ_ACCEPT';
export const HANDLE_RSRQ_ACCEPT = 'HANDLE_RSRQ_ACCEPT';
export const RSRQ_DECLINE = 'RSRQ_DECLINE';
export const HANDLE_RSRQ_DECLINE = 'HANDLE_RSRQ_DECLINE';
export const FETCH_RSRQ_RESOLUTION = 'FETCH_RSRQ_RESOLUTION';
export const HANDLE_RSRQ_RESOLUTION = 'HANDLE_RSRQ_RESOLUTION';
export const RRQ_ROLLBACK = 'RRQ_ROLLBACK';
export const RRQ_ROLLBACK__SUCCESS = 'RRQ_ROLLBACK__SUCCESS';
export const RRQ_ROLLBACK__FAIL = 'RRQ_ROLLBACK__FAIL';
export const RRQ_DELETE = 'RRQ_DELETE';
export const RRQ_DELETE_SUCCESS = 'RRQ_DELETE_SUCCESS';

export const FETCH_RES = 'FETCH_RES';
export const RECIEVE_RES = 'RECIEVE_RES';
export const CLEAR_RES_SEARCH = 'CLEAR_RES_SEARCH';