import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';


const VaultInvestmentView = ({ investmentView: { accountNumber, accountName, statements }, onDownloadClick }) => (
  <div className="vault_investments-list_item">
    <h3>{accountNumber} {accountName}</h3>
    {
      !!statements &&
      (
        <div>
          {
            statements.map((statement, index) => {
              return statement.isDownloadAvailable ?
                (<Button key={index} type="link" icon={<DownloadOutlined />} onClick={() => { onDownloadClick(statement.id) }}>
                  {moment(statement.statementDate).format("MM/DD/YYYY")}
                </Button>) :
                (null);
              })
          }
        </div>
      )
    }
  </div>
);

export default VaultInvestmentView;
