import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { listResourceRequests, deleteResourceRequest } from '../../actions/resourceRequestActions';
import ResourceRequestView from '../../components/ResourceRequestView/ResourceRequestView';
import AdminPage from '../../common/pages/admin-page/AdminPage';
import { routeBuilder } from '../../routing';


const ResourceRequestsPage = ({
  history,
  listResourceRequests,
  deleteResourceRequest,
  user: {
    token
  },
  resourceRequests: {
    items,
    isRequested
  },
}) => {
  let currentView = 'open';

  const handleViewChange = (viewKey) => {
    currentView = viewKey;
    token && listResourceRequests(viewKey, token);
  };

  useEffect(() => {
    handleViewChange(currentView);
  }, [currentView]);

  const handleItemDoubleClick = ({ currentTarget }) => {
    let requestId = currentTarget.getAttribute('data-item-id');
    history.push(routeBuilder.resourceRequestDetails(requestId));
  };

  const handleRefresh = (viewKey) => {
    token && listResourceRequests(viewKey, token);
  };

  const handleItemDelete = (requestId) => {
    token && deleteResourceRequest(requestId, token).then(() => {
      handleRefresh(currentView);
    });
  };

  return (<AdminPage>
    <ResourceRequestView
      resourceRequests={items}
      isItemsRequested={isRequested}
      onRefresh={handleRefresh}
      onViewChange={handleViewChange}
      onItemDoubleClick={handleItemDoubleClick}
      onItemDelete={handleItemDelete}
    />
  </AdminPage>)
};

export default connect(({ user, resourceRequests }) => ({ user, resourceRequests }), { listResourceRequests,
  deleteResourceRequest })(ResourceRequestsPage);
