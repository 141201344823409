import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Spin, Empty, Button, Statistic, Result } from 'antd';
import { LoadingOutlined, QuestionOutlined } from '@ant-design/icons';

import { routeBuilder } from '../../routing';
import { createResourceRequest } from '../../store/resourceRequests/actionCreators';
import { fetchUsersRequests, fetchUsersRequestsByUserId } from '../../store/resourceRequests/actionCreators';
import AddResourceModal from '../Modals/AddResourceModal';

import './loan-list.less';

const loanListLabels = {
  modals: {
    addResource: {
      title: 'Request Loan Info',
      descripion: 'Please enter desired Loan Number to be added. You may enter multiple account numbers by selecting ‘Add more’.',
      numberPlaceholderText: 'Enter loan number'
    }
  }
}

const statisticCommonProps = {
  precision: 2,
  prefix: '$'
};

const LoanList = ({
  loading, items, userResourceRequests: { isCreateResourceRequestInProgress },
  user: {
    token
  },
  createResourceRequest,
  fetchUsersRequests,
  fetchUsersRequestsByUserId,
  userId
}) => {
  const [isLoanRequestModalVisible, setLoanRequestModalVisible] = useState(false);

  const toggleModal = () => {
    setLoanRequestModalVisible(!isLoanRequestModalVisible);
  };

  const handleRequestsSubmit = (numbers) => {
    const uniqueNumbers = new Set(numbers);
    const promises = [];

    for (let loanNumber of uniqueNumbers) {
      promises.push(createResourceRequest(loanNumber, 0, userId, token));
    }

    Promise.all(promises).then(() => {
      setLoanRequestModalVisible(false);

      if (userId) {
        fetchUsersRequestsByUserId(userId, token);
      } else {
        fetchUsersRequests(token);
      }
    });
  };

  if (loading) {
    return (
      <Spin
        size="large"
        className="rsrq-details_spinner"
        indicator={
          <LoadingOutlined spin />
        }
      />
    );
  }

  if (items && items.length === 0) {
    return (
      <>
        <Empty
          className="loan-content--empty"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Loans found"
        >
          <Button
            type="primary"
            onClick={toggleModal}
          >
            Request Loan Info
          </Button>
        </Empty>
        <AddResourceModal
          loading={isCreateResourceRequestInProgress}
          visible={isLoanRequestModalVisible}
          onCancel={toggleModal}
          onSubmit={handleRequestsSubmit}
          title={loanListLabels.modals.addResource.title}
          description={loanListLabels.modals.addResource.descripion}
          numberPlaceholderText={loanListLabels.modals.addResource.numberPlaceholderText}
        />
      </>
    );
  }

  return (
    <div className="loans-list">
      {
        items && items.map((item) => (
          <div className="loans-list_item" key={item.id}>
            <div className="loans-list_item-header-container">
              <Link to={!userId ? routeBuilder.loanDetails(item.id) : routeBuilder.userAccountLoanDetails(userId, item.id)}>
                <h4 className="loans-list_item-header-container_link"><span className="number">{item.number}</span>{item.name}</h4>
              </Link>
            </div>
            <div className="loans-list_item-info">
              <Statistic {...statisticCommonProps} title="Original Amount" value={item.originalAmount} />
              <Statistic {...statisticCommonProps} title="Principal Balance" value={item.principalBalance} />
              <Statistic {...statisticCommonProps} title="Interest Balance" value={item.interestBalance} />
              <Statistic {...statisticCommonProps} title="Scheduled Payment" value={item.paymentAmount} />
              <Statistic precision={2} suffix="%" title="Rate" value={item.rate} />
            </div>
          </div>
        ))
      }
      {
        items && items.length > 0 && (
          <div className="loans-list_item loans-list_item--condensed">
            <Result
              title="Can't find Loan info?"
              subTitle="No worries! Request that information."
              icon={<QuestionOutlined />}
              extra={
                <Button type="ghost" onClick={toggleModal}>Request Loan Info</Button>
              }
            />
            <AddResourceModal
              loading={isCreateResourceRequestInProgress}
              visible={isLoanRequestModalVisible}
              onCancel={toggleModal}
              onSubmit={handleRequestsSubmit}
              title={loanListLabels.modals.addResource.title}
              description={loanListLabels.modals.addResource.descripion}
              numberPlaceholderText={loanListLabels.modals.addResource.numberPlaceholderText}
            />
          </div>
        )
      }
    </div>
  )
}

LoanList.defaultProps = {
  loading: false,
  items: []
};

export default connect(
  ({ userResourceRequests, user }) => ({ userResourceRequests, user }),
  { createResourceRequest, fetchUsersRequests, fetchUsersRequestsByUserId }
)(LoanList);
