import {
  FETCH__INVESTMENT_DETAILS,
  FETCH__INVESTMENT_DETAILS__FAIL,
  FETCH__INVESTMENT_DETAILS__SUCCESS,
  FETCH__ACCOUNT_STATEMENTS,
  FETCH__ACCOUNT_STATEMENTS__SUCCESS,
  FETCH__ACCOUNT_STATEMENTS__FAIL,
} from './actions';

const defaultState = {
  investmentDetails: undefined,
  isInvestmentDetailsFetched: false,
  isInvestmentDetailsFetchFailed: false,
  isStatementUpdateRequested: false,
  isStatementUpdateFailed: false
};

const investmentDetails = (state = defaultState, action) => {
  switch(action.type) {
    case FETCH__INVESTMENT_DETAILS:
      return {
        ...state,
        isInvestmentDetailsFetched: true,
        isInvestmentDetailsFetchFailed: false,
      };
    case FETCH__INVESTMENT_DETAILS__FAIL:
      return {
        ...state,
        isInvestmentDetailsFetched: false,
        isInvestmentDetailsFetchFailed: true,
      };
    case FETCH__INVESTMENT_DETAILS__SUCCESS:
      return {
        ...state,
        isInvestmentDetailsFetched: false,
        investmentDetails: action.details,
      };
    case FETCH__ACCOUNT_STATEMENTS:
      return {
        ...state,
        isStatementUpdateRequested: true,
        isStatementUpdateFailed: false
      };
    case FETCH__ACCOUNT_STATEMENTS__FAIL:
      return {
        ...state,
        isStatementUpdateRequested: false,
        isStatementUpdateFailed: true
      };
    case FETCH__ACCOUNT_STATEMENTS__SUCCESS:
      let { investmentDetails } = state;

      if (!investmentDetails) return state;

      return {
        ...state,
        isStatementUpdateRequested: false,
        investmentDetails: {
          ...investmentDetails,
          statements: action.statements
        }
      };
    default:
      return state;
  }
}

export default investmentDetails;