import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Input, Button } from 'antd';
import { useFormik } from 'formik';

import FormControl from '../../common/FormControl/FormControl';
import { resetPassword, resetResetPasswordState } from '../../store/user/actionCreators';
import restorePasswordLabels from '../../labels/reset-password';
import { restorePasswordSchema } from './formik.config';
import { routes } from '../../routing';

import './forgot-password.less';

const ForgotPasswordForm = ({
  user: {
    isResetPasswordRequested,
    isResetPasswordFailed,
    resetPasswordAttempts,
    token
  },
  resetPassword,
  resetResetPasswordState
}) => {
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: restorePasswordSchema,
    onSubmit: ({ email }) => {
      resetPassword(email);
    }
  });

  useEffect(() => {
    return () => { resetResetPasswordState() }
  }, [])

  if (token) {
    return (<Redirect to={routes.root} />);
  }

  if (!isResetPasswordRequested
    && !isResetPasswordFailed
    && resetPasswordAttempts > 0) {
    return (
      <div className="forgot-password-form">
        <h1>{restorePasswordLabels.result.header}</h1>
        <p>{restorePasswordLabels.result.description}</p>
        <div className="forgot-password-form_form-controls">
          <Button type='link'>
            <Link to={routes.login}>
              {restorePasswordLabels.buttons.goToLogin}
            </Link>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="forgot-password-form">
      <h1>{restorePasswordLabels.header}</h1>
      <p>{restorePasswordLabels.description}</p>
      <form onSubmit={formik.handleSubmit}>
        <FormControl
          id="email"
          caption="Email Address"
          touched={formik.touched.email}
          error={formik.errors.email}
        >
          <Input id="email" {...formik.getFieldProps('email')} />
        </FormControl>
        <div className="forgot-password-form_form-controls">
          <Button
            tabIndex={8}
            type="primary"
            htmlType="submit"
            loading={isResetPasswordRequested}
          >
            {restorePasswordLabels.buttons.resetPassword}
          </Button>
          <Button tabIndex={9} type="link">
            <Link to={routes.login}>
              {restorePasswordLabels.buttons.backToLogin}
            </Link>
          </Button>
        </div>
      </form>
    </div>
  );
}

export default connect(
  ({ user }) => ({ user }),
  { resetPassword, resetResetPasswordState }
)(ForgotPasswordForm);
