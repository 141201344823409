import * as Yup from 'yup';
import loginFormLabels from '../../labels/login-form';

export const initialFormValues = {
  email: '',
  password: ''
};

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(loginFormLabels.validation.email.wrongFormat)
    .required(loginFormLabels.validation.email.empty),
  password: Yup.string()
    .min(6, loginFormLabels.validation.password.tooShort)
    .required(loginFormLabels.validation.password.empty)
});

