import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchDataImportJobsBySessionId } from '../../store/dataImportSessions/actionCreators';
import { Button, Badge, Progress } from 'antd';

import './_dataImportProgress.scss';

import {
  IMPORT_JOB_STATUS__PENDING,
  IMPORT_JOB_STATUS__IN_PROGRESS,
  IMPORT_JOB_STATUS__SUCCEDED,
  IMPORT_JOB_STATUS__FAILED,
  importStageKeyProvider,
  importStages,
  sessionStatuses,
  totalJobsNumber
} from '../../const/data-import';

const importJobToBadgeStatusMap = {
  [IMPORT_JOB_STATUS__PENDING]: 'default',
  [IMPORT_JOB_STATUS__IN_PROGRESS]: 'processing',
  [IMPORT_JOB_STATUS__SUCCEDED]: 'success',
  [IMPORT_JOB_STATUS__FAILED]: 'error',
}

const importJobToBadgeCaptionMap = {
  [IMPORT_JOB_STATUS__PENDING]: 'Pending Start',
  [IMPORT_JOB_STATUS__IN_PROGRESS]: 'In Progress',
  [IMPORT_JOB_STATUS__SUCCEDED]: 'Completed',
  [IMPORT_JOB_STATUS__FAILED]: 'Failed',
}

class DataImportStep extends Component {
  render() {
    let { step, currentJobMap } = this.props;
    let currentJobs = Object.keys(currentJobMap);
    let currentJobStatus = currentJobs.indexOf(`${step.dataImportJobType}`) > -1
      ? currentJobMap[step.dataImportJobType].status
      : IMPORT_JOB_STATUS__PENDING;

    return (
      <div className="data-import-job">
        <div className="data-import-job__header">
          <h4>{step.title}</h4>
          <div className="data-import-job__header__subtitle">
            <Badge status={importJobToBadgeStatusMap[currentJobStatus]} text={importJobToBadgeCaptionMap[currentJobStatus]} />
          </div>
        </div>
        {
          !!step.steps && (
            <div className="data-import-job__subjob">
              {
                step.steps.map(
                  item =>
                    <DataImportStep
                      key={`internal-${item.dataImportJobType}`}
                      currentJobMap={currentJobMap}
                      step={item} />
                )
              }
            </div>
          )
        }
      </div>
    )
  }
}

class DataImportStage extends Component {
  render() {
    let { stage, currentJobMap } = this.props;

    return (
      <div className="dis-stage-item">
        <h3>{stage.title}</h3>
        <div className="dis-stage-item__jobs">
          {
            stage.steps.map((step) =>
              <DataImportStep
                key={`${importStageKeyProvider(stage.title)}-${step.dataImportJobType}`}
                currentJobMap={currentJobMap}
                step={step} />)
          }
        </div>
      </div>);
  }
}

class DataImportProgressStep extends Component {
  intervalId = null;
  percentage = 0;

  componentDidMount() {
    let { dataImportSessions: { selectedSession } } = this.props;

    if (selectedSession.status === sessionStatuses.inProgress) {
      this.intervalId = setInterval(this.refreshJobs, 5000);
    }

    this.refreshJobs();
  }

  componentWillUnmount() {
    if (this.intervalId !== null) {
      window.clearInterval(this.intervalId);
    }
  }

  refreshJobs = () => {
    let {
      user: { token },
      dataImportSessions: {
        selectedSession
      },
      fetchDataImportJobsBySessionId
    } = this.props;

    if (selectedSession.status === sessionStatuses.succeeded
      || selectedSession.status === sessionStatuses.failed) {
      window.clearInterval(this.intervalId);

      return;
    }

    fetchDataImportJobsBySessionId(token, selectedSession.id);
  }

  handleStartSessionClick = () => {
    let { onNextClick } = this.props;

    this.intervalId = setInterval(this.refreshJobs, 5000);

    onNextClick();
  }

  render() {
    let {
      onBackClick,
      onAllJobsCompleted,
      dataImportSessions: {
        sessionJobs,
        selectedSession,
        isStartSessionRequested
      }
    } = this.props;
    let canStartSession = selectedSession.status === sessionStatuses.ready,
        isSessionInProgress = isStartSessionRequested || selectedSession.status === sessionStatuses.inProgress;
    let sessionJobMap = Object.fromEntries(sessionJobs.map(item => [item.jobType, item]));
    let completedItemsCount = sessionJobs.filter(item => item.status === IMPORT_JOB_STATUS__SUCCEDED || item.status === IMPORT_JOB_STATUS__FAILED).length;
    let newPercentage = Math.round(completedItemsCount / (totalJobsNumber / 100));

    if (newPercentage !== this.percentage) {
      this.percentage = newPercentage;

      if (this.percentage === 100) {
        onAllJobsCompleted();
      }
    }

    return (
      <div className="dis-progress">
        {
          importStages.map(
            stage =>
              <DataImportStage
                key={importStageKeyProvider(stage.title)}
                currentJobMap={sessionJobMap}
                stage={stage} />)
        }
        <div className="dis-progress__status">
          {
            selectedSession.status === sessionStatuses.inProgress &&
            <Progress percent={this.percentage} status="active" />
          }
        </div>
        <div className="dis-progress__controls">
          <Button onClick={onBackClick} disabled={selectedSession.status === sessionStatuses.inProgress} >Upload another files</Button>
          <Button
            type="primary"
            disabled={!canStartSession}
            loading={isSessionInProgress}
            onClick={this.handleStartSessionClick}>
              Start Session
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user, dataImportSessions }) => ({ user, dataImportSessions }),
  { fetchDataImportJobsBySessionId }
)(DataImportProgressStep);
