import React from 'react';

import AuthLayout from '../../common/layout/auth/AuthLayout';
import RegisterForm from '../../auth/register/RegisterForm';
import registrationImg from '../../assets/images/registration_page_cp.jpg';

const RegisterPage = () => (
  <AuthLayout path={registrationImg}>
    <RegisterForm />
  </AuthLayout>
);

export default RegisterPage;
