import {
  FETCH__LOAN,
  FETCH__LOAN__FAIL,
  FETCH__LOAN__SUCCESS,
} from './actions';

const defaultState = {
  details: null,
  isLoanDetailsFetched: false,
  isLoanDetailsFetchFailed: false,
};

const loanDetails = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH__LOAN:
      return {
        ...state,
        isLoanDetailsFetched: true,
        isLoanDetailsFetchFailed: false,
      };
    case FETCH__LOAN__FAIL:
      return {
        ...state,
        isLoanDetailsFetched: false,
        isLoanDetailsFetchFailed: true,
      };
    case FETCH__LOAN__SUCCESS:
      return {
        ...state,
        isLoanDetailsFetched: false,
        details: action.details,
      };
    default:
      return state;
  }
}

export default loanDetails;