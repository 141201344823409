import {
  FETCH_USER_TOKEN,
  FETCH_USER_TOKEN__SUCCESS,
  FETCH_USER_TOKEN__FAILED,
  REGISTER_USER,
  REGISTER_USER__SUCCESS,
  REGISTER_USER__FAILED,
  RESTORE_PASSWORD,
  RESTORE_PASSWORD__SUCCESS,
  RESTORE_PASSWORD__FAILED,
  RESET_RESETPASSWORDSTATE,
  RESET_REGISTERSTATE,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL__SUCCESS,
  CONFIRM_EMAIL__FAILED,
  RESET_CONFIRMEMAILSTATE,
  VERIFY_RESET_PASSWORD_TOKEN,
  VERIFY_RESET_PASSWORD_TOKEN__SUCCESS,
  VERIFY_RESET_PASSWORD_TOKEN__FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD__SUCCESS,
  CHANGE_PASSWORD__FAILED,
  RESET_CHANGEPASSWORDSTATE,
  LOG_OUT,
  DELETE_USER,
  DELETE_USER__SUCCESS,
  DELETE_USER__FAIL
} from './actions';

const localStorageTokenKey = 'userToken'
const defaultState = {
  token: undefined,
  role: undefined,
  email: undefined,
  isTokenRequested: false,
  isTokenRequestFailed: false,
  isRegisterRequested: false,
  isRegisterFailed: false,
  registerAttempts: 0,
  isResetPasswordRequested: false,
  isResetPasswordFailed: false,
  resetPasswordAttempts: 0,
  isConfirmEmailRequested: false,
  isConfirmEmailRequestFailed: false,
  confirmEmailAttemptsCount: 0,
  isVerifyChangePasswordTokenRequested: false,
  isVerifyChangePasswordTokenRequestFailed: false,
  isChangePasswordRequested: false,
  isChangePasswordRequestFailed: false,
  changePasswordAttempts: 0,
  isUserDeletionInProgress: false,
  isUserDeletionFailed: false
};

const initDefaultState = () => {
  try {
    const token = localStorage.getItem(localStorageTokenKey);

    if (token === null) {
      return defaultState;
    }

    const {
      exp,
      role,
      email,
      uniqueIdentifier
    } = JSON.parse(atob(token.split('.')[1]));
    const date = new Date(0);
    date.setUTCSeconds(exp);

    if ((new Date()).getTime() > date.getTime()) {
      localStorage.removeItem(localStorageTokenKey);

      return defaultState;
    }

    return {
      ...defaultState,
      token,
      role,
      email,
      id: +uniqueIdentifier
    };
  } catch (err) {
    return defaultState;
  }
}

const processToken = (token) => {
  const { role, email, uniqueIdentifier } = JSON.parse(atob(token.split('.')[1]));
  localStorage.setItem(localStorageTokenKey, token);

  return {
    id: +uniqueIdentifier,
    token,
    role,
    email,
  };
}

const clearAuthData = () => {
  localStorage.removeItem(localStorageTokenKey);

  return {
    token: undefined,
    role: undefined,
    email: undefined,
    id: undefined
  };
}

const user = (state = initDefaultState(), action) => {
  switch (action.type) {
    case LOG_OUT:
      return {
        ...state,
        ...clearAuthData()
      };
    case FETCH_USER_TOKEN:
      return {
        ...state,
        isTokenRequested: true,
        isTokenRequestFailed: false,
      };
    case FETCH_USER_TOKEN__SUCCESS:
      let userTokenPart = processToken(action.token);

      return {
        ...state,
        ...userTokenPart,
        isTokenRequested: false
      };
    case FETCH_USER_TOKEN__FAILED:
      return {
        ...state,
        isTokenRequested: false,
        isTokenRequestFailed: true
      };
    case REGISTER_USER:
      return {
        ...state,
        isRegisterRequested: true,
        registerAttempts: ++state.registerAttempts,
        isRegisterFailed: false
      };
    case REGISTER_USER__SUCCESS:
      return {
        ...state,
        isRegisterRequested: false,
      };
    case REGISTER_USER__FAILED:
      return {
        ...state,
        isRegisterRequested: false,
        isRegisterFailed: true
      };
    case RESTORE_PASSWORD:
      return {
        ...state,
        isResetPasswordRequested: true,
        isResetPasswordFailed: false,
        resetPasswordAttempts: ++state.resetPasswordAttempts,
      };
    case RESTORE_PASSWORD__SUCCESS:
      return {
        ...state,
        isResetPasswordRequested: false,
      };
    case RESTORE_PASSWORD__FAILED:
      return {
        ...state,
        isResetPasswordRequested: false,
        isResetPasswordFailed: true
      };
    case RESET_RESETPASSWORDSTATE:
      return {
        ...state,
        isResetPasswordRequested: false,
        isResetPasswordFailed: false,
        resetPasswordAttempts: 0
      };
    case RESET_REGISTERSTATE:
      return {
        ...state,
        isRegisterRequested: false,
        isRegisterFailed: false,
        registerAttempts: 0,
      };
    case CONFIRM_EMAIL:
      return {
        ...state,
        isConfirmEmailRequested: true,
        isConfirmEmailRequestFailed: false,
        confirmEmailAttemptsCount: ++state.confirmEmailAttemptsCount
      };
    case CONFIRM_EMAIL__SUCCESS:
      return {
        ...state,
        isConfirmEmailRequested: false
      };
    case CONFIRM_EMAIL__FAILED:
      return {
        ...state,
        isConfirmEmailRequested: false,
        isConfirmEmailRequestFailed: true
      };
    case RESET_CONFIRMEMAILSTATE:
      return {
        ...state,
        isConfirmEmailRequested: false,
        isConfirmEmailRequestFailed: false,
        confirmEmailAttemptsCount: 0
      };
    case VERIFY_RESET_PASSWORD_TOKEN:
      return {
        ...state,
        isVerifyChangePasswordTokenRequested: true,
        isVerifyChangePasswordTokenRequestFailed: false,
      };
    case VERIFY_RESET_PASSWORD_TOKEN__FAILED:
      return {
        ...state,
        isVerifyChangePasswordTokenRequested: false,
        isVerifyChangePasswordTokenRequestFailed: true,
      };
    case VERIFY_RESET_PASSWORD_TOKEN__SUCCESS:
      return {
        ...state,
        isVerifyChangePasswordTokenRequested: false,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        isChangePasswordRequested: true,
        isChangePasswordRequestFailed: false,
        changePasswordAttempts: ++state.changePasswordAttempts,
      };
    case CHANGE_PASSWORD__FAILED:
      return {
        ...state,
        isChangePasswordRequested: false,
        isChangePasswordRequestFailed: true,
      };
    case CHANGE_PASSWORD__SUCCESS:
      return {
        ...state,
        isChangePasswordRequested: false,
      };
    case RESET_CHANGEPASSWORDSTATE:
      return {
        ...state,
        isChangePasswordRequested: false,
        isChangePasswordRequestFailed: false,
        isVerifyChangePasswordTokenRequested: false,
        isVerifyChangePasswordTokenRequestFailed: false,
        changePasswordAttempts: 0,
      };
    case DELETE_USER:
      return {
        ...state,
        isUserDeletionInProgress: true,
        isUserDeletionFailed: false
      };
    case DELETE_USER__SUCCESS:
      return {
        ...state,
        isUserDeletionInProgress: false
      };
    case DELETE_USER__FAIL:
      return {
        ...state,
        isUserDeletionInProgress: false,
        isUserDeletionFailed: true
      };
    default:
      return state;
  }
}

export default user;
