import React, { Component } from 'react';
import { Table, Button, Tag, Popover, Alert } from 'antd';
import { ReloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import './StatementTable.styles.less';

const { Column } = Table;

export default class StatementTable extends Component {
  state = {
    isDownloadInfoVisible: false
  };

  handleVisibleChange = visible => {
    this.setState({ isDownloadInfoVisible: visible });
  }

  renderDownloadInstructions = () => {
    return (
      <div className="statements-download-instructions_root">
        <ul className="statements-download-instructions">
          <li>Click <span className="link-like-text">Generate PDF</span></li>
          As a result, status changes from <Tag>Available</Tag> to <Tag color="cyan">Generating...</Tag>
          <li>Click <span className="link-like-text">Refresh</span> button on top of the statements table</li>
          <li>Wait until status changes to <Tag color="green">In Vault</Tag> and click <span className="link-like-text">Download</span></li>
        </ul>
        <Alert
          message="For your convenience generated PDFs are stored on the portal for 24 hours, so that it's not required to generate PDF every time you need it."
          type="info"
          showIcon />
      </div>
    )
  }

  render() {
    let { statements, onMoveToVault, onDownloadFile, onRefreshTable, fileIdMoving, downloadFileId, loading } = this.props;
    let statementsKeyed = statements.map((st, index) => ({ ...st, key:index }));

    return (
      <Table
        size="small"
        dataSource={statementsKeyed}
        pagination={{ hideOnSinglePage: true, pageSize: 25, showSizeChanger: false }}
        loading={loading}
        title={() => (
          <div className="account-statements-table_header">
            <Popover
              content={this.renderDownloadInstructions()}
              title="Statements download instructions"
              trigger="click"
              placement="bottomLeft"
              visible={this.state.isDownloadInfoVisible}
              onVisibleChange={this.handleVisibleChange}
            >
              <Button icon={<InfoCircleOutlined style={{paddingTop: '3px'}}/>} style={{display:'flex', justifyItems: 'center'}} type="link" size="small" >How to download statements?</Button>
            </Popover>
            <Button icon={<ReloadOutlined style={{paddingTop: '5px'}}/>} style={{display:'flex', justifyItems: 'center'}} size="small" type="link" loading={loading} onClick={onRefreshTable}>
              Refresh
            </Button>
          </div>
        )}
      >
        <Column
          title="Date"
          dataIndex="statementDate"
          render={(date) => moment(date).format('MM/DD/YYYY')}
        />
        <Column
          title="Status"
          dataIndex="isInVault"
          className="account-statements-table_status-column"
          render={(isInVault, record) => {
            const handleMoveToVault = () => {
              let { id } = record;

              onMoveToVault(id);
            }

            const handleDownload = () => {
              let { id } = record;

              onDownloadFile(id);
            }

            if (isInVault) {
              let isDownloadPreparing = downloadFileId === record.id;
              let buttonText = isDownloadPreparing ?
                'Preparing...' :
                'Download';

              return (
                <>
                  <Tag color="green">In Vault</Tag>
                  <Button
                    className="statement-table_button"
                    size="small"
                    type="link"
                    loading={isDownloadPreparing}
                    onClick={handleDownload}
                  >
                    {buttonText}
                  </Button>
                </>
              );
            }

            let { isRequested } = record;

            return isRequested ?
              (<Tag color="cyan">Generating...</Tag>) : (
                <>
                  <Tag>Available</Tag>
                  <Button
                    size="small"
                    type="link"
                    loading={fileIdMoving === record.id}
                    onClick={handleMoveToVault}
                    className="statement-table_button"
                  >
                    Generate PDF
                  </Button>
                </>);
          }}
        />
      </Table>
    );
  }
}
