import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { listRes, clearSearch } from '../../actions/resourceActions';

import { Input, Spin, Empty, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import BestMatchItem from './BestMatchItem';
import ViewSelector from '../ViewSelector/ViewSelector';

const { Search } = Input;

const views = [{
  key: 'all',
  title: 'All'
}, {
  key: 'loan',
  title: 'Loans'
}, {
  key: 'investment',
  title: 'Investments'
}];

const viewKeyMap = {
  "0": 'loan',
  "1": 'investment'
};

class BestMatchList extends Component {
  state = {
    view: viewKeyMap[this.props.searchBy],
    search: this.props.searchString,
    highlight: this.props.searchString
  }

  handleSearchChange = (e) => {
    let { target: { value } } = e;
    this.setState({ search: value });
  }

  componentDidMount () {
    let { view, search } = this.state;
    let { user: { token }, listRes } = this.props;

    listRes(view, search, token);
  }

  handleViewChange = (viewKey) => {
    let { search } = this.state;
    let { user: { token }, listRes } = this.props;

    this.setState({ view: viewKey });
    listRes(viewKey, search, token);
  }

  onSearch = (searchText) => {
    let { view } = this.state;
    let { user: { token }, listRes } = this.props;

    this.setState({ highlight: searchText })

    listRes(view, searchText, token);
  }

  componentWillUnmount() {
    let { clearSearch } = this.props;

    clearSearch();
  }

  render () {
    let {
      onMatchSelected,
      resources: {
        isLoading,
        items
      }
    } = this.props;

    let { view, search, highlight } = this.state;

    return (
      <div className="rsrq-best-matches-list">
        <h3>Best Matches</h3>
        <Search value={search} onChange={this.handleSearchChange} onSearch={this.onSearch}/>
        <ViewSelector
          views={views}
          activeViewKey={view}
          onViewChange={this.handleViewChange} />
        {
          isLoading &&
            <Fragment>
              <Spin
                size='large'
                className="rsrq-details-inner-spinner"
                indicator={<LoadingOutlined spin />}
              />
              <ul className="rsrq-best-matches-list--list">
                {
                  [0, 1, 2].map((item) => (<Skeleton key={item} active/>))
                }
              </ul>
            </Fragment>
        }
        {
          !isLoading &&
          <ul className="rsrq-best-matches-list--list">
          {
            items.map((item) => (
              <BestMatchItem
                key={item.id}
                item={item}
                search={highlight}
                onItemSelected={onMatchSelected} />
            ))
          }
          </ul>
        }
        {
          !isLoading && items.length === 0 &&
          <Empty 
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={`No items found by "${highlight}"`}/>
        }
      </div>
    );
  }
}

BestMatchList.propTypes = {
  matches: PropTypes.array,
  onMatchSelected: PropTypes.func,
  searchBy: PropTypes.number,
  searchString: PropTypes.string
};

BestMatchList.defaultProps = {
  matches: [],
  onMatchSelected: () => {},
  searchBy: 0,
  searchString: ''
};

export default connect(
  ({ user, resources }) => ({ user, resources }),
  { listRes, clearSearch }
)(BestMatchList);