import { FETCH_RES, RECIEVE_RES, CLEAR_RES_SEARCH } from './index';
import { LOG_OUT } from '../store/user/actions';
import axios from 'axios';

const firmUrl = window.location.origin;

const authHeaders = (token) => ({
  'Authorization': `Bearer ${token}`
});

const viewMap = {
  'all': 0,
  'investment': 1,
  'loan': 2
};

export const listRes = (viewKey, searchString, token) => dispatch => {
  dispatch({ type: FETCH_RES });

  axios.get(
    `${firmUrl}/api/resources`, {
      params: {
        view: viewMap[viewKey],
        search: searchString
      },
      headers: { ...authHeaders(token) }
    }).then(response => {
      if (response.status === 200) {
        dispatch({ type: RECIEVE_RES, items: response.data });
      }
    }).catch(response => {
      if (response.status === 401) {
        dispatch({ type: LOG_OUT });
      }
    });
};

export const clearSearch = () => ({ type: CLEAR_RES_SEARCH });
