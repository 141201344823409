import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AuthenticatedRoute from './AuthenticatedRoute';
import AuthorizedRoute from './AuthorizedRoute';
import { ConnectedHomePage } from '../pages/HomePage';
import NotFound from '../pages/NotFound';
import AdminLayout from '../pages/layouts/AdminLayout';
import ResourceRequestsPage from '../pages/resource-requests/ResourceRequestsPage';
import ResourceRequestDetailsPage from '../components/ResourceRequestDetailsPage/ResourceRequestDetailsPage';
import LoanDetailsPage from '../pages/loans/LoanDetailsPage';
import AdminUserProfilePage from '../pages/AdminUserProfilePage';
import LoansPage from '../pages/loans/LoansPage';
import InvestmentsPage from '../pages/investments/InvestmentsPage';
import InvestmentDetailsPage from '../pages/investments/InvestmentDetailsPage';
import AssetDetailsPage from '../pages/investments/AssetDetailsPage';
import ResourceRequestStatusPage from '../pages/resource-requests/ResourceRequestStatusPage';
import StatementsVaultPage from '../pages/statements/StatementsVaultPage';
import ProfileSettingsPage from '../pages/user/ProfileSettingsPage';
import UserManagementPage from '../pages/user/UserManagementPage';
import DataImportPage from '../pages/data-import/DataImportPage';
import LoginPage from '../pages/authentication/LoginPage';
import RegisterPage from '../pages/authentication/RegisterPage';
import ForgotPasswordPage from '../pages/authentication/ForgotPasswordPage';
import ConfirmEmailPage from '../pages/authentication/ConfirmEmailPage';
import ResetPasswordPage from '../pages/authentication/ResetPasswordPage';

import { adminRole } from '../const/user';

export const defaultRoutes = {
  root: '/',
  login: '/login',
};

export const routes = {
  root: '/',
  dataImport: '/data-import',
  resourceRequests: '/resource-requests',
  users: '/users',
  // Loans
  userLoans: '/loans',
  userAccountLoans: '/users/:userId/loans',
  loanDetails: '/loans/:loanId',
  userAccountLoanDetails: '/users/:userId/loans/:loanId',
  // Investments
  userInvestments: '/investments',
  investmentDetails: '/investments/:investmentId',
  assetDetails: '/investments/:investmentId/assets/:assetId',
  userAccountInvestments: '/users/:userId/investments',
  userAccountInvestmentDetails: '/users/:userId/investments/:investmentId',
  userAccountAssetDetails: '/users/:userId/investments/:investmentId/assets/:assetId',
  // Status
  userRequestStatus: '/status',
  userAccountStatus: '/users/:userId/status',
  // Statements
  userStatements: '/statements',
  userAccountStatements: '/users/:userId/statements',
  // Auth
  login: '/login',
  forgotPassword: '/forgot-password',
  register: '/register',
  confirmEmail: '/confirm-email',
  resetPassword: '/password-reset',
  profileSettings: '/profile'
};

export const routeBuilder = {
  resourceRequestDetails: (resourceRequestId) => `/resource-requests/${resourceRequestId}`,
  userProfile: (userId) => `/users/${userId}`,
  userProfileAdmin: (userId) => `/users/${userId}/profile`,
  userAccountLoans: (userId) => `/users/${userId}/loans`,
  loanDetails: (loanId) => `/loans/${loanId}`,
  userAccountLoanDetails: (userId, loanId) => `/users/${userId}/loans/${loanId}`,
  investmentDetails: (investmentId) => `/investments/${investmentId}`,
  assetDetails: (investmentId, assetId) => `/investments/${investmentId}/assets/${assetId}`,
  userAccountInvestments: (id) => `/users/${id}/investments`,
  userAccountInvestmentDetails: (userId, investmentId) => `/users/${userId}/investments/${investmentId}`,
  userAccountAssetDetails: (userId, investmentId, assetId) =>
    `/users/${userId}/investments/${investmentId}/assets/${assetId}`,
  userAccountStatus: (id) => `/users/${id}/status`,
  userAccountStatements: (id) => `/users/${id}/statements`,
};

export const AppRouting = () => (
  <Switch>
    <AuthenticatedRoute
      exact
      path={routes.profileSettings}
      render={(props) => <ProfileSettingsPage {...props} />}
    />
    <AuthenticatedRoute
      exact
      path={routes.userLoans}
      render={(props) => <LoansPage {...props} />}
    />
    <AuthorizedRoute
      exact
      requiredRole={adminRole}
      path={routes.userAccountLoans}
      render={(props) => <LoansPage {...props} />}
    />
    <AuthenticatedRoute
      exact
      path={routes.userInvestments}
      render={(props) => <InvestmentsPage {...props} />}
    />
    <AuthorizedRoute
      exact
      requiredRole={adminRole}
      path={routes.userAccountInvestments}
      render={(props) => <InvestmentsPage {...props} />}
    />
    <AuthenticatedRoute
      path={routes.userRequestStatus}
      render={(props) => <ResourceRequestStatusPage {...props} />}
    />
    <AuthorizedRoute
      requiredRole={adminRole}
      path={routes.userAccountStatus}
      render={(props) => <ResourceRequestStatusPage {...props} />}
    />
    <AuthenticatedRoute
      path={routes.userStatements}
      render={(props) => <StatementsVaultPage {...props} />}
    />
    <AuthorizedRoute
      requiredRole={adminRole}
      path={routes.userAccountStatements}
      render={(props) => <StatementsVaultPage {...props} />}
    />
    <AuthenticatedRoute
      exact
      path={routes.loanDetails}
      render={(props) => <LoanDetailsPage {...props} />}
    />
    <AuthorizedRoute
      requiredRole={adminRole}
      path={routes.userAccountLoanDetails}
      render={(props) => <LoanDetailsPage {...props} />}
    />
    <AuthenticatedRoute
      exact
      path={routes.investmentDetails}
      render={(props) => <InvestmentDetailsPage {...props} />}
    />
    <AuthorizedRoute
      exact
      requiredRole={adminRole}
      path={routes.userAccountInvestmentDetails}
      render={(props) => <InvestmentDetailsPage {...props} />}
    />
    <AuthenticatedRoute
      path={routes.assetDetails}
      render={(props) => <AssetDetailsPage {...props} />}
    />
    <AuthorizedRoute
      requiredRole={adminRole}
      path={routes.userAccountAssetDetails}
      render={(props) => <AssetDetailsPage {...props} />}
    />
    <AuthenticatedRoute
      exact
      path={routes.root}
      component={ConnectedHomePage}
    />
    <AuthorizedRoute
      requiredRole={adminRole}
      path={routes.users}
      exact
      render={(props) => <UserManagementPage {...props} />}
    />
    <AuthorizedRoute
      requiredRole={adminRole}
      path={routes.resourceRequests}
      exact
      render={(props) => <ResourceRequestsPage {...props} />}
    />
    <AuthorizedRoute
      requiredRole={adminRole}
      path={`${routes.resourceRequests}/:requestId`}
      render={(props) => <AdminLayout {...props} component={ResourceRequestDetailsPage} />}
    />
    <AuthorizedRoute
      requiredRole={adminRole}
      path={routes.dataImport}
      render={(props) => <DataImportPage {...props} />}
    />
    <AuthorizedRoute
      requiredRole={adminRole}
      path={`${routes.users}/:userId/profile`}
      render={(props) => <AdminLayout {...props} component={AdminUserProfilePage} />}
    />
    <Route
      path={routes.login}
      render={(props) => <LoginPage {...props} />}
    />
    <Route
      path={routes.register}
      render={(props) => <RegisterPage {...props} />}
    />
    <Route
      path={routes.forgotPassword}
      render={(props) => <ForgotPasswordPage {...props} />}
    />
    <Route
      path={routes.confirmEmail}
      render={(props) => <ConfirmEmailPage {...props} />}
    />
    <Route
      path={routes.resetPassword}
      render={(props) => <ResetPasswordPage {...props} />}
    />
    <Route component={NotFound} />
  </Switch>
);
