import React from 'react';
import classnames from 'classnames';

import './siteLogo.less';

import Logo from './TMF-logo-default-gold.svg';
import WiLogo from './Weslean_Impact_gold_navy.svg'

const SiteLogo = ({ className, isAnyWiAssets }) => {
  const rootClasses = classnames([className, 'site-logo_wrapper']);

  console.log(isAnyWiAssets);

  return (
    <div className={rootClasses}>
      <img src={isAnyWiAssets ? WiLogo : Logo} alt="website logo"/>
    </div>
  );
};

export default SiteLogo;
