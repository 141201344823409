const restorePasswordLabels = {
  header: 'Restore Access',
  description: 'Please, provide an email which you used while register. We\'ll send you an email with instructions how to restore an access to your account.',
  validation: {
    email: {
      required: 'It\'s required',
      wrongFormat: 'Wrong format'
    }
  },
  controls: {
    email: {
      label: 'Email Address',
      placeholder: 'example: mail@mail.com'
    }
  },
  buttons: {
    backToLogin: 'Back to Login',
    resetPassword: 'Reset Password',
    goToLogin: 'Go to Login page'
  },
  result: {
    header: 'Password was reset!',
    description: 'We\'ve sent you an email with further instructions how to reset your password'
  }
};

export default restorePasswordLabels;
