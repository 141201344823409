import React from 'react';
import { Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import './form-control.less';

const { Text } = Typography;

const FormControl = ({
  id,
  caption,
  touched,
  error,
  children
}) => {
  return (
    <div className="tmf-form-control">
      <div className="tmf-form-control_caption">
        <label htmlFor={id}>{caption}</label>
      </div>
      <div className="tmf-form-control_error-message">
        {
          touched && error && (
            <>
              <ExclamationCircleOutlined />
              <span>{error}</span>
            </>
          )
        }
      </div>
      <div className="tmf-form-control_main">
        {children}
      </div>
    </div>
  );
}

export default FormControl;
