import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Result, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { routes, routeBuilder } from '../../routing';
import { fetchInvestmentDetails, updateStatements } from '../../store/investmentDetails/actionCreators';
import { moveToVault, downloadFile } from '../../store/statements/actionCreators';
import UserAccountPage from '../../common/pages/user-account-page/UserAccountPage';
import InvestmentYearSummary from '../../components/InvestmentYearSummary/InvestmentYearSummary';
import AssetTable from '../../components/AssetTable/AssetTable';
import AccountActivityTable from '../../components/AccountActivityTable/AccountActivityTable';
import StatementTable from '../../components/StatementTable/StatementTable';

import './investment-details.less';

const InvestmentDetailsPage = ({
  match: {
    params: {
      userId,
      investmentId,
    }
  },
  user: {
    token
  },
  investmentDetails: {
    isInvestmentDetailsFetched,
    isInvestmentDetailsFetchFailed,
    isStatementUpdateRequested,
    investmentDetails
  },
  statements: {
    fileIdRequesed,
    downloadFileId,
  },
  fetchInvestmentDetails,
  moveToVault,
  updateStatements,
  downloadFile
}) => {
  const backLink = !userId
    ? `${routes.userInvestments}`
    : `${routeBuilder.userProfile(userId)}/investments`;

  useEffect(() => {
    loadInvestmentDetails(investmentId, token);
  }, [token, investmentId]);

  const loadInvestmentDetails = () => {
    fetchInvestmentDetails && fetchInvestmentDetails(investmentId, token);
  }

  const handleFileDownload = (statementId) => {
    downloadFile(statementId, token);
  }

  const handleMoveToVault = async (statementId) => {
    await moveToVault(statementId, token);
    await updateStatements(investmentId, token);
  }

  const handleStatementsTableRefresh = () => {
    updateStatements(investmentId, token);
  }

  if (isInvestmentDetailsFetched) {
    return (
      <Spin
        size="large"
        className="rsrq-details_spinner"
        indicator={
          <LoadingOutlined spin />
        }
      />
    );
  }

  if (isInvestmentDetailsFetchFailed || !investmentDetails) {
    return (
      <Result
        status="error"
        title="Something went wrong"
        subTitle="Please try to refresh page a bit later. If situation still take place - contact support"
        extra={(
          <Button type="ghost" onClick={() => loadInvestmentDetails()}>Refresh Page</Button>
        )}
      />
    );
  }

  return (
    <UserAccountPage
      userId={userId}
      loadProfile
    >
      <div className="investment-details">
        <div className="investment-details_header">
          <h3>
            <Link className="investment-details_number" to={backLink}>Investments</Link>
            <span className="investment-details_number">/</span>
            <span className="investment-details_number">{investmentDetails.number}</span>
            {investmentDetails.name}
          </h3>
        </div>
        <div className="investment-details_container">
          <div className="statements-table">
            <h3>Statements</h3>
            <StatementTable
              loading={isStatementUpdateRequested}
              onDownloadFile={handleFileDownload}
              onRefreshTable={handleStatementsTableRefresh}
              onMoveToVault={handleMoveToVault}
              fileIdMoving={fileIdRequesed}
              downloadFileId={downloadFileId}
              statements={investmentDetails.statements}
            />
          </div>
          <div>
            <div>
              <h3>Assets</h3>
              <AssetTable assets={investmentDetails.assets} urlContext={{ userId, investmentId }} />
            </div>
            <div>
              <h3>Account Activity</h3>
              <AccountActivityTable activities={investmentDetails.activities} />
            </div>
          </div>
        </div>
      </div>
    </UserAccountPage>
  );
};

export default connect(
  ({ user, investmentDetails, statements }) => ({ user, investmentDetails, statements }),
  { fetchInvestmentDetails, moveToVault, downloadFile, updateStatements }
)(InvestmentDetailsPage);
