import React from 'react';
import { NavLink } from 'react-router-dom';

import './navigation-menu.less'

const Navigation = ({ items }) => {
  return (
    <ul className="navigation_menu">
      {
        items && items.map(([label, navLinkProps], index) => (
          <li key={`navigation_menu-item-${index}`}>
            <NavLink
              className="navigation_menu-item"
              activeClassName="navigation_menu-item--active"
              {...navLinkProps}
            >
              {label}
            </NavLink>
          </li>
        ))
      }
    </ul>
  );
}

export default Navigation;
