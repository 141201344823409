import React, { Component } from 'react';
import { Upload, message, Button, Avatar } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { dataImportEndpoints } from '../../const/backend'
import { authHeaders } from '../../store/shared';
import FileItem from './FileItem';
import { FileOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const fileInfos = [{
  fileName: 'account.csv',
  caption: 'Accounts',
  description: 'CSV file should contain next headers: acct_no, acct_name, book_date',
  isUploaded: false
}, {
  fileName: 'activity_summary.csv',
  caption: 'Activity Summaries',
  description: 'CSV file should contain next headers: acct_no, category, amount, order',
  isUploaded: false
}, {
  fileName: 'asset.csv',
  caption: 'Assets',
  description: 'CSV file should contain next headers: acct_no, asset_type, asset_no, symbol, asset_name, rate, return, maturity, cost, receivable, units, unit_price, mkt_value',
  isUploaded: false
}, {
  fileName: 'activity.csv',
  caption: 'Activities',
  description: 'CSV file should contain next headers: acct_no, asset_no, reference, category, trans_date, trans_desc, amount, units',
  isUploaded: false
}, {
  fileName: 'asset_history.csv',
  caption: 'Asset Histories',
  description: 'CSV file should contain next headers: acct_no, asset_no, book_date, cost, market, asset_name, asset_type',
  isUploaded: false
}, {
  fileName: 'loanhist_header.csv',
  caption: 'Loans',
  description: 'CSV file should contain next headers: loan_numbe, fund, ilotype, ilono, name, attn, address, city, st, zip, pmtamt, int_rate, int_rec, origamt, prinbal, loan_name, pmt_date, ins_desc, ins_exp, process_da',
  isUploaded: false
}, {
  fileName: 'loanhist_lines.csv',
  caption: 'Loan Lines',
  description: 'CSV file should contain next headers: ilono, ilotype, fund, trandate, reference, begprin, begint, end_prin, end_int, tranint, tranprin',
  isUploaded: false
}];

export default class UploadFilesStep extends Component {
  files = {
    'account.csv': false,
    'activity_summary.csv': false,
    'asset.csv': false,
    'activity.csv': false,
    'asset_history.csv': false,
    'loanhist_header.csv': false,
    'loanhist_lines.csv': false
  };

  importJobTypeToFileIdMap = {
    '1': 'account.csv',
    '2': 'activity.csv',
    '3': 'activity_summary.csv',
    '4': 'asset.csv',
    '5': 'asset_history.csv',
    '6': 'loanhist_header.csv',
    '7': 'loanhist_history.csv',
    '8': 'loanhist_lines.csv'
  };

  constructor(props) {
    super(props);

    let { uploadedFiles } = props;
    let filesUploaded = uploadedFiles.map(item => this.importJobTypeToFileIdMap[item]);
    let isNextStepAvailable = this.processUploadedFilesList(filesUploaded);

    this.state = { filesUploaded, isNextStepAvailable };
  }

  processUploadedFilesList = (filesUploaded) => {
    filesUploaded.forEach(item => this.files[item] = true);

    return Object.getOwnPropertyNames(this.files).every(item => this.files[item]);
  }

  handleFileUploaded(filename) {
    let { onAllFilesUploaded } = this.props;
    let { filesUploaded } = this.state;
    let nameLowercased = filename.toLowerCase();

    this.files[nameLowercased] = true;
    filesUploaded.push(nameLowercased);

    if (!Object.getOwnPropertyNames(this.files).some(name => !this.files[name])) {
      onAllFilesUploaded();
      this.setState({ isNextStepAvailable: true, filesUploaded });
    } else {
      this.setState({ ...this.state, filesUploaded });
    }
  }

  render() {
    let that = this;
    let { token, onNextClick, onCancel } = this.props;
    let { filesUploaded, isNextStepAvailable } = this.state;
    let dragger1Props = {
      name: 'file',
      multiple: true,
      action: dataImportEndpoints.dataImport(),
      headers: { ...authHeaders(token)},
      onChange(info) {
        const { status, name } = info.file;

        if (status !== 'uploading') {
          console.log(info.file, info.fileList);
        }

        if (status === 'done') {
          message.success(`${name} file uploaded successfully.`);

          that.handleFileUploaded(name);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
      <div className="dis-wizard-step">
        <div className="dis-wizard-step__content">
          <div>
            <Dragger {...dragger1Props} className="dis-wizard__uploader">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Drop one or more files
              </p>
            </Dragger>
          </div>
          <div className="dis-wizard-step__upload-files">
          {
            fileInfos.map(item => (
              <FileItem
                key={item.fileName}
                fileInfo={item}
                uploaded={filesUploaded.indexOf(item.fileName) > -1} />
            ))
          }
          </div>
        </div>
        <div className="dis-wizard-step__footer">
          <div className="legend">
            <Avatar size={32} icon={<FileOutlined />} style={{ backgroundColor: '#28a745'}} />
            <p>Uploaded</p>
            <Avatar size={32} icon={<FileOutlined />} style={{ backgroundColor: '#6c757d'}} />
            <p>Requires upload</p>
          </div>
          <div>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" onClick={onNextClick} disabled={!isNextStepAvailable}>Next</Button>
          </div>
        </div>
      </div>
    );
  }
}
