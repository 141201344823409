import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table, Tag, Button, Popconfirm, Tooltip } from 'antd';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { routeBuilder } from '../../routing';

const { Column } = Table;

export class UsersListPageable extends Component {
  onRow = (record, rowIndex) => {
    let { onUserDoubleClick } = this.props;

    return {
      onDoubleClick: event => { onUserDoubleClick(record.id) }
    };
  }

  render() {
    let {
      users,
      loading,
      itemsPerPage,
      itemsTotal,
      onPageChanged,
      onPromoteUser,
      onRevokeAccess
    } = this.props;
    let pagingProps = {
      defaultPageSize: itemsPerPage,
      hideOnSinglePage: true,
      showSizeChanger: false,
      size: 'small',
      total: itemsTotal,
      onChange: onPageChanged
    };

    return (
      <div className="user-list">
        <Table
          rowKey="id"
          size="middle"
          onRow={this.onRow}
          loading={loading}
          pagination={pagingProps}
          dataSource={users}
        >
          <Column title="Full Name" dataIndex="fullName" key="fullName" />
          <Column title="Organization" dataIndex="organizationName" key="organizationName" />
          <Column
            className="user-list_email"
            title="Email Address"
            dataIndex="email"
            key="email"
            render={(email, record) => {
              return record.isEmailConfirmed ?
                <span>{email}</span> :
                <>
                  <span>{email}</span>
                  <Tooltip placement="top" title="Email wasn't confirmed yet. Login attempts will be blocked.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
            }}
          />
          <Column title="City" dataIndex="city" key="city"/>
          <Column
            title="Last Login On"
            dataIndex="lastLoginDate"
            render={(lastLoginDate) => (!!lastLoginDate ?
              moment(lastLoginDate).format("MM/DD/YYYY hh:mm A") :
              'Unknown')
            }
          />
          <Column title="..." dataIndex="isAdmin" key="isAdmin" render={(text, record) => {
            return record.isAdmin
              ? <Popconfirm
                  title={`Do you want to cut admin rights for ${record.fullName}?`}
                  okText="Yes, I do!"
                  cancelText="Nope"
                  placement="topRight"
                  onConfirm={() => onRevokeAccess(record.id)}
                >
                  <Tag color="geekblue">ADMIN<CloseOutlined /></Tag>
                </Popconfirm>
              : <Popconfirm
                  placement="topRight"
                  title={`Do you want to promote ${record.fullName} to admin?`}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => onPromoteUser(record.id)}
                >
                  <Button type="link" className="user-list_inline-button">Promote...</Button>
                </Popconfirm>
          }}/>
          <Column title='' render={(text, record) => {
            return (
              <Link to={routeBuilder.userProfileAdmin(record.id)}>View Profile</Link>
            );
          }}/>
        </Table>
      </div>
    );
  }
}

UsersListPageable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    fullName: PropTypes.string,
    organizationName: PropTypes.string,
    email: PropTypes.string,
    city: PropTypes.string,
    isAdmin: PropTypes.bool
  })),
  loading: PropTypes.bool,
  onUserDoubleClick: PropTypes.func,
  itemsPerPage: PropTypes.number,
  itemsTotal: PropTypes.number,
  onPageChanged: PropTypes.func,
  onPromoteUser: PropTypes.func,
  onRevokeAccess: PropTypes.func,
}

UsersListPageable.defaultProps = {
  users: [],
  onUserDoubleClick: () => {},
  loading: false,
  itemsPerPage: 20,
  itemsTotal: 0,
  onPageChanged: () => {},
  onPromoteUser: () => {},
  onRevokeAccess: () => {},
}
