import React from 'react';
import moment from 'moment';
import { Table } from 'antd';

import { wrapNegativeAndAlign } from '../../utility';

import './loan-lines-table.less';

const { Column } = Table;

const LoanLinesTable = ({ lines }) => {
  const indexedLines = lines.map((x, index) => ({
    ...x,
    key: index,
    amount: x.transactionInterest + x.transactionPrincipal
  }));

  return (
    <Table
      className="loan-line-table"
      size="middle"
      dataSource={indexedLines}
      pagination={{
        pageSize: 30,
        hideOnSinglePage: true,
        showSizeChanger: false
      }}
    >
      <Column title="Date" dataIndex="transactionDate" key="transactionDate" render={val => moment(val).format('MM/DD/YYYY')} />
      {
        indexedLines && indexedLines.length > 0 && indexedLines.some(line => !!line.reference)
          ? <Column title="Reference" dataIndex="reference" key="reference" />
          : null
      }
      <Column
        title="Transaction Amount"
        dataIndex="amount"
        key="amount"
        className="number-column"
        render={(num) => (wrapNegativeAndAlign(num))}
      />
      <Column
        title="Transaction Principal"
        dataIndex="transactionPrincipal"
        key="transactionPrincipal"
        className="number-column"
        render={(num) => (wrapNegativeAndAlign(num))}
      />
      <Column
        title="Transaction Interest"
        dataIndex="transactionInterest"
        key="transactionInterest"
        className="number-column"
        render={(num) => (wrapNegativeAndAlign(num))}
      />
      <Column
        title="Principal Balance"
        dataIndex="principalEnd"
        key="principalEnd"
        className="number-column"
        render={(num) => (wrapNegativeAndAlign(num))}
      />
      <Column
        title="Interest Balance"
        dataIndex="interestEnd"
        key="interestEnd"
        className="number-column"
        render={num => wrapNegativeAndAlign(num)}
      />
    </Table>
  );
}

export default LoanLinesTable;
