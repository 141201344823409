import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { logout } from '../../../store/user/actionCreators';
import { fetchUserProfile } from '../../../store/userProfile/actionCreators';
import { routes, routeBuilder } from '../../../routing';
import userHeaderLabels from '../../../labels/user-header';
import PortalLayout from '../../layout/portal-layout/PortalLayout';
import UserData from '../../HeaderComponents/UserData/UserData';
import Navigation from '../../HeaderComponents/NavigationMenu/NavigationMenu';
import ProfileMenu from '../../HeaderComponents/ProfileMenu/ProfileMenu';

import { adminRole, userRole } from '../../../const/user';

const userAccountRoutes = (userId) => {
  return {
    [adminRole]: {
      loans: routeBuilder.userAccountLoans(userId),
      investments: routeBuilder.userAccountInvestments(userId),
      status: routeBuilder.userAccountStatus(userId),
      statements: routeBuilder.userAccountStatements(userId),
    },
    [userRole]: {
      loans: routes.userLoans,
      investments: routes.userInvestments,
      status: routes.userRequestStatus,
      statements: routes.userStatements,
    }
  }
};

const UserAccountPage = ({
  user: {
    token,
    role,
    email: currentUserEmail
  },
  userProfile: {
    isUserProfileRequested,
    actualizationDate,
    fullName,
    emailAddress: profileEmailAddress
  },
  children,
  userId,
  loadProfile = false,
  isLoading,
  fetchUserProfile,
  logout
}) => {
  const isAllLoading = isLoading || isUserProfileRequested;
  const currentRoutes = userAccountRoutes(userId);
  const navigationItems = Object.keys(userHeaderLabels.labelsLinks)
    .map((key) => [
      userHeaderLabels.labelsLinks[key],
      { to: currentRoutes[role][key] }
    ]);

  useEffect(() => {
    loadProfile && fetchUserProfile(token, userId);
  }, [token, userId, loadProfile]);

  return (
    <PortalLayout
      userMenu={<ProfileMenu onLogout={logout} />}
      logoAside={
        !isAllLoading && role === adminRole && currentUserEmail !== profileEmailAddress
          ? <UserData name={fullName} email={profileEmailAddress} />
          : null
      }
      navigation={<Navigation items={navigationItems} />}
      userProfileAside={
        !isAllLoading && actualizationDate &&
          new Date(actualizationDate).getFullYear() > 1900 && (
            <span className="main-heading_profile-as-of-date">
              as of {moment(actualizationDate).format('MM/DD/YYYY')}
            </span>
          )
      }
    >
      {children}
    </PortalLayout>
  );
}

export default connect(
  ({ user, userProfile }) => ({ user, userProfile }),
  { logout, fetchUserProfile }
)(UserAccountPage);
