import {
  FETCH__USER_PROFILE__FAIL,
  FETCH__USER_PROFILE__SUCCESS,
  FETCH__USER_PROFILE,
  FETCH__USER_PROFILE_SETTINGS,
  FETCH__USER_PROFILE_SETTINGS__FAIL,
  FETCH__USER_PROFILE_SETTINGS__SUCCESS,
  UPDATE__USER_PROFILE_SETTINGS,
  UPDATE__USER_PROFILE_SETTINGS__FAIL,
  UPDATE__USER_PROFILE_SETTINGS__SUCCESS,
  RESET__USER_PROFILE_SETTINGS,
} from './actions';

const defaultState = {
  isUserProfileRequested: false,
  isUserProfileRequestFailed: false,
  actualizationDate: undefined,
  fullName: undefined,
  emailAddress: undefined,
  loans: [],
  investments: [],
  requests: [],
  settings: undefined,
  isSettingsRequested: false,
  isSettingsRequestFailed: false,
  isSettingsUpdateInProgress: false,
  isSettingsUpdateFailed: false
};

const userProfile = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH__USER_PROFILE:
      return {
        ...state,
        isUserProfileRequested: true,
        isUserProfileRequestFailed: false
      };
    case FETCH__USER_PROFILE__SUCCESS:
      return {
        ...state,
        fullName: action.data.fullName,
        emailAddress: action.data.emailAddress,
        isUserProfileRequested: false,
        actualizationDate: action.data.actualizationDate,
        loans: action.data.loans,
        investments: action.data.investments,
        isAnyWiAssets: action.data.isAnyWiAssets,
      };
    case FETCH__USER_PROFILE__FAIL:
      return {
        ...state,
        isUserProfileRequested: false,
        isUserProfileRequestFailed: true
      };
    case FETCH__USER_PROFILE_SETTINGS:
      return {
        ...state,
        isSettingsRequested: true,
        isSettingsRequestFailed: false,
      };
    case FETCH__USER_PROFILE_SETTINGS__SUCCESS:
      return {
        ...state,
        isSettingsRequested: false,
        settings: action.data,
      };
    case FETCH__USER_PROFILE_SETTINGS__FAIL:
      return {
        ...state,
        isSettingsRequested: false,
        isSettingsRequestFailed: true
      };
    case UPDATE__USER_PROFILE_SETTINGS:
      return {
        ...state,
        isSettingsUpdateInProgress: true,
        isSettingsUpdateFailed: false,
      };
    case UPDATE__USER_PROFILE_SETTINGS__SUCCESS:
      return {
        ...state,
        isSettingsUpdateInProgress: false
      };
    case UPDATE__USER_PROFILE_SETTINGS__FAIL:
      return {
        ...state,
        isSettingsUpdateInProgress: false,
        isSettingsUpdateFailed: true,
      };
    case RESET__USER_PROFILE_SETTINGS:
      return {
        state,
        ...defaultState,
      };
    default:
      return state;
  }
}

export default userProfile;
