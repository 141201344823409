import axios from 'axios';
import { investmentDetailsEndpoint } from '../../const/backend';
import { actionTemplate, authHeaders } from '../shared';
import { LOG_OUT } from '../user/actions';
import {
  FETCH__INVESTMENT_DETAILS,
  FETCH__INVESTMENT_DETAILS__FAIL,
  FETCH__INVESTMENT_DETAILS__SUCCESS,
  FETCH__ACCOUNT_STATEMENTS,
  FETCH__ACCOUNT_STATEMENTS__SUCCESS,
  FETCH__ACCOUNT_STATEMENTS__FAIL
} from './actions';

export const fetchInvestmentDetails = (investmentId, token) => dispatch => {
  dispatch(actionTemplate(FETCH__INVESTMENT_DETAILS));

  axios.get(
    investmentDetailsEndpoint.get(investmentId),
    { headers: authHeaders(token) }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ ...actionTemplate(FETCH__INVESTMENT_DETAILS__SUCCESS), details: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__INVESTMENT_DETAILS__FAIL));
  });
}

export const updateStatements = (investmentId, token) => dispatch => {
  dispatch(actionTemplate(FETCH__ACCOUNT_STATEMENTS));

  axios.get(
    investmentDetailsEndpoint.accountStatements(investmentId),
    { headers: { ...authHeaders(token) } }
  ).then(response => {
    if (response.status === 200) {
      dispatch({
        ...actionTemplate(FETCH__ACCOUNT_STATEMENTS__SUCCESS),
        statements: response.data
      });
    }
  }).catch(({ response }) => {
    dispatch(actionTemplate(FETCH__ACCOUNT_STATEMENTS__FAIL));

    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));
    }
  });
};