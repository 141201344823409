import {
  FETCH__USER_LIST__FAIL,
  FETCH__USER_LIST__SUCCESS,
  FETCH__USER_LIST,
  PROMOTE__USER__FAIL,
  PROMOTE__USER__SUCCESS,
  PROMOTE__USER,
  REVOKE__ADMIN_RIGHTS__FAIL,
  REVOKE__ADMIN_RIGHTS__SUCCESS,
  REVOKE__ADMIN_RIGHTS,
} from './actions';

const defaultState = {
  isUsersFetched: false,
  isUsersFetchFailed: false,
  users: undefined,
  pageInfo: undefined,
  isPromoteUserRequested: false,
  isPromoteUserFailed: false,
  isRevokeAccessRequested: false,
  isRevokeAccessFailed: false
}

const userManagement = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH__USER_LIST:
      return {
        ...state,
        isUsersFetched: true,
        isUsersFetchFailed: false,
      };
    case FETCH__USER_LIST__SUCCESS:
      return {
        ...state,
        isUsersFetched: false,
        users: action.data.users,
        pageInfo: action.data.pageInfo,
      };
    case FETCH__USER_LIST__FAIL:
      return {
        ...state,
        isUsersFetched: false,
        isUsersFetchFailed: true,
      };
    case PROMOTE__USER:
      return {
        ...state,
        isPromoteUserRequested: true,
        isPromoteUserFailed: false,
      };
    case PROMOTE__USER__FAIL:
      return {
        ...state,
        isPromoteUserRequested: false,
        isPromoteUserFailed: true,
      };
    case PROMOTE__USER__SUCCESS:
      return {
        ...state,
        isPromoteUserRequested: false,
      };
    case REVOKE__ADMIN_RIGHTS:
      return {
        ...state,
        isRevokeAccessRequested: true,
        isRevokeAccessFailed: false,
      };
    case REVOKE__ADMIN_RIGHTS__FAIL:
      return {
        ...state,
        isRevokeAccessRequested: false,
        isRevokeAccessFailed: true,
      };
    case REVOKE__ADMIN_RIGHTS__SUCCESS:
      return {
        ...state,
        isRevokeAccessRequested: false,
      };
    default:
      return state;
  }
}

export default userManagement;