import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchUserProfile } from '../../store/userProfile/actionCreators'
import UserAccountPage from '../../common/pages/user-account-page/UserAccountPage';
import InvestmentsList from '../../components/InvestmentList/InvestmentList';

const InvestmentsPage = ({
  match: {
    params: {
      userId
    }
  },
  user: {
    token,
  },
  userProfile: {
    isUserProfileRequested,
    investments
  },
  fetchUserProfile
}) => {
  useEffect(() => {
    fetchUserProfile(token, userId ? userId : null);
  }, [token, userId]);

  return (
    <UserAccountPage
      userId={userId}
      isLoading={isUserProfileRequested}
    >
      <InvestmentsList
        loading={isUserProfileRequested}
        userId={userId}
        items={investments}
      />
    </UserAccountPage>
  );
}

export default connect(
  ({ user, userProfile }) => ({ user, userProfile }),
  { fetchUserProfile }
)(InvestmentsPage);
