export const REQUEST__MOVE_TO_VAULT = 'REQUEST__MOVE_TO_VAULT';
export const REQUEST__MOVE_TO_VAULT_SUCCESS = 'REQUEST__MOVE_TO_VAULT_SUCCESS';
export const REQUEST__MOVE_TO_VAULT_FAIL = 'REQUEST__MOVE_TO_VAULT_FAIL';

export const DOWNLOAD__VAULT_FILE = 'DOWNLOAD__VAULT_FILE';
export const DOWNLOAD__VAULT_FILE__SUCCESS = 'DOWNLOAD__VAULT_FILE__SUCCESS';
export const DOWNLOAD__VAULT_FILE__FAIL = 'DOWNLOAD__VAULT_FILE__FAIL';

export const FETCH__USER_STATEMENTS = 'FETCH__USER_STATEMENTS';
export const FETCH__USER_STATEMENTS__SUCCESS = 'FETCH__USER_STATEMENTS__SUCCESS';
export const FETCH__USER_STATEMENTS__FAIL = 'FETCH__USER_STATEMENTS__FAIL';