import axios from 'axios';
import { actionTemplate, authHeaders } from '../shared';
import { LOG_OUT } from '../user/actions';
import { dataImportSessionEndpoints } from '../../const/backend';
import {
  FETCH__SESSIONS,
  FETCH__SESSIONS__SUCCESS,
  FETCH__SESSIONS__FAILED,
  FETCH__SESSION_JOBS,
  FETCH__SESSION_JOBS__FAIL,
  FETCH__SESSION_JOBS__SUCCESS,
  START__SESSION,
  START__SESSION__FAIL,
  START__SESSION__SUCCESS,
  UPDATE__SELECTED_SESSION,
  CLEAR__SELECTED_SESSION,
  CREATE__SESSION,
  CREATE__SESSION__SUCCESS,
  CREATE__SESSION__FAIL,
  CANCEL__SESSION,
  CANCEL__SESSION__FAIL,
  CANCEL__SESSION__SUCCESS
} from './actions';

export const cancelSession = sessionId => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(CANCEL__SESSION));

  return axios.delete(
    dataImportSessionEndpoints.cancelSession(sessionId),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch(actionTemplate(CANCEL__SESSION__SUCCESS));
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(CANCEL__SESSION__FAIL));
  });
}

export const fetchDataImportSessions = token => dispatch => {
  dispatch(actionTemplate(FETCH__SESSIONS));

  return axios.get(
    dataImportSessionEndpoints.list(),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch({...actionTemplate(FETCH__SESSIONS__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__SESSIONS__FAILED));
  });
}

export const fetchDataImportJobsBySessionId = (token, sessionId) => dispatch => {
  dispatch(actionTemplate(FETCH__SESSION_JOBS));

  return axios.get(
    dataImportSessionEndpoints.sessionJobs(sessionId),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch({...actionTemplate(FETCH__SESSION_JOBS__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__SESSION_JOBS__FAIL));
  });
}

export const startSession = (token, sessionId) => dispatch => {
  if (!sessionId) return;

  dispatch(actionTemplate(START__SESSION));

  return axios.post(
    dataImportSessionEndpoints.startSession(sessionId),
    null,
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch({...actionTemplate(START__SESSION__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(START__SESSION__FAIL));
  });
}

export const updateSelectedSession = session => dispatch => {
  dispatch({...actionTemplate(UPDATE__SELECTED_SESSION), data: session });
}

export const clearSelectedSession = () => dispatch => {
  dispatch(actionTemplate(CLEAR__SELECTED_SESSION));
}

export const createDataImportSession = () => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(CREATE__SESSION));

  return axios.post(
    dataImportSessionEndpoints.createSession(),
    null,
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 201) {
      dispatch({...actionTemplate(CREATE__SESSION__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(CREATE__SESSION__FAIL));
  });
}