import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../store/user/actionCreators';

import { Header } from '../../components/Header/Header';

import './styles/admin-layout.scss';

class AdminLayout extends PureComponent {
  render() {
    let { component: Component, history, match, logout } = this.props;

    return (
      <div className="admin-root">
        <Header onLogout={logout} />
        <div className="admin-root_content">
          <Component history={history} match={match} />
        </div>
      </div>
    );
  }
}

export default connect(null, { logout })(AdminLayout);
