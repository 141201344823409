import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tooltip, Empty } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { wrapNegativeAndAlign } from '../../utility';
import moment from 'moment';
import PropTypes from 'prop-types';
import { routeBuilder } from '../../routing';

import './_assetTable.scss';

const { Column } = Table;

const AssetTable = ({ assets, urlContext: { userId, investmentId } }) => {
  if (!assets || assets.length === 0) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Summary data is not available" />
    );
  }

  return (
    <Table
      rowKey="id"
      size="small"
      dataSource={assets}
      pagination={{
        hideOnSinglePage: true,
        showSizeChanger: false
      }}
    >
      <Column
        title="Asset #"
        key="number"
        dataIndex="number"
        render={(number, record) => {
          return (
            <Link
              className="inv-details_asset-details-link"
              to={
                !!userId
                  ? routeBuilder.userAccountAssetDetails(userId, investmentId, record.id)
                  : routeBuilder.assetDetails(investmentId, record.id)
              }
            >
              {number}
            </Link>
          );
        }}
      />
      <Column dataIndex="description" key="description" title="Description" />
      <Column
        className="assets-table_number"
        dataIndex="cost"
        key="cost"
        title="Cost"
        render={(num) => wrapNegativeAndAlign(num)}
      />
      <Column
        className="assets-table_number"
        title="Market Value"
        dataIndex="marketValue"
        key="marketValue"
        render={(num, record) => {
          return !record.isSymbolExists ? (
            wrapNegativeAndAlign(num)
          ) : (
            <>
              <Tooltip
                placement="top"
                title="Market Values determined as of the last day of previous month."
              >
                <InfoCircleOutlined />
              </Tooltip>
              {wrapNegativeAndAlign(num)}
            </>
          );
        }}
      />
      <Column
        className="assets-table_number"
        dataIndex="receivable"
        key="receivable"
        title="Accrued Int."
        render={(num) => wrapNegativeAndAlign(num)}
      />
      <Column
        className="assets-table_number"
        title="Rate"
        dataIndex="rate"
        key="rate"
        render={(num) => `${num.toFixed(4)}%`}
      />
      <Column
        title="Maturity"
        dataIndex="maturity"
        key="maturity"
        render={(date) => (!!date ? moment(date).format('MM/DD/YYYY') : '')}
      />
    </Table>
  );
};

export default AssetTable;

AssetTable.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      cost: PropTypes.number,
      description: PropTypes.string,
      marketValue: PropTypes.number,
      maturity: PropTypes.date,
      number: PropTypes.string,
      rate: PropTypes.number,
      receivable: PropTypes.number,
    })
  ),
  urlContext: PropTypes.shape({
    userId: PropTypes.string,
    investmentId: PropTypes.string.isRequired,
  }).isRequired,
};
