import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin,  Result, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getResourceRequestDetails, approveRequest, declineRequest, acceptRequest } from '../../actions/resourceRequestActions';

import UserDetailsCard from '../UserDetailsCard/UserDetailsCard';
import ResourceRequestCard from '../ResourceRequestCard/ResourceRequestCard';
import ResourceRequestControlPanel from '../ResourceRequestDetailsPage/ResourceRequestControlPanel';
import BestMatchList from './BestMatchList';
import ApprovedRequestDetails from './ApprovedRequestDetails';

import './_resourceRequestDetails.scss';

class ResourceRequestDetailsPage extends Component {
  state = {
    selectedItem: null
  }

  componentDidMount() {
    this.retireveItemDetails();
  }

  retireveItemDetails() {
    let {
      getResourceRequestDetails,
      match: {
        params: {
          requestId 
        } 
      },
      user: {
        token
      }
    } = this.props;

    token && getResourceRequestDetails(requestId, token);
  }

  handleGoBack = () => {
    let { history } = this.props;

    history.goBack();
  }

  handleMatchSelected = (e) => {
    let { currentTarget } = e;
    let { resources: { items } } = this.props;

    if (!currentTarget) {
      return;
    }

    let filtered = items.filter(item => item.id === +currentTarget.getAttribute('data-item-id'));

    if (filtered.length > 0) {
      this.setState({ selectedItem: filtered[0] });
    }
  }

  handleApprove = () => {
    let { selectedItem: { id, resourceType }} = this.state;
    let {
      match: {
        params: {
          requestId
        }
      }, 
      approveRequest,
      user: { token }
    } = this.props;

    approveRequest(requestId, resourceType, id, token);
  }

  handleDecline = (text) => {
    let {
      match: {
        params: {
          requestId
        }
      }, 
      declineRequest,
      user: { token }
    } = this.props;

    declineRequest(requestId, text, token);
  }

  handleAccept = () => {
    let {
      match: {
        params: {
          requestId
        }
      }, 
      acceptRequest,
      user: { token }
    } = this.props;

    acceptRequest(requestId, token);
  }

  onDropSelection = () => {
    this.setState({ selectedItem: null });
  }

  resolveStatusBlock = (itemDetails) => {
    switch (itemDetails.status) {
      case 0:
        return (
          <Result
            status="warning"
            title="Nobody is working on request"
            subTitle="By clicking on `Accept` below you can start to do that"
            extra={
              <Button
                type="primary"
                key="accept"
                onClick={this.handleAccept}>
                  Accept
              </Button>
            }
          />
        );

      case 1:
        return (
          <BestMatchList
            matches={itemDetails.matches}
            searchBy={itemDetails.type}
            searchString={itemDetails.resourceNumber}
            onMatchSelected={this.handleMatchSelected} />
        );
      case 2:
        return (
          <Result
            title="Request was declined"
            subTitle={`Message sent to customer: "${itemDetails.remark}"`}
          />
        );
      case 3:
        return (
          <ApprovedRequestDetails requestId={itemDetails.id} />
        );
      default:
        return null;
    }
  }

  render() {
    let {
      resourceRequests: {
        isRequested,
        itemDetails
      },
      user: { token }
    } = this.props;
    let { selectedItem } = this.state;

    let isDataReady = !!token && !!itemDetails && !isRequested;

    // if (!itemDetails && !isRequested) {
    //   this.retireveItemDetails();
    // }

    return (
      <div className="rsrq-details">
        {
          !isDataReady ? (
            <Spin size='large' className="rsrq-details_spinner" indicator={<LoadingOutlined spin />} />
          ) : (
            <>
              <div className="rsrq-details_user-info">
                <UserDetailsCard userDetails={itemDetails.userProfile} />
              </div>
              <div className="rsrq-details_status-panel">
                { this.resolveStatusBlock(itemDetails) }
              </div>
              <div className="rsrq-details_request-panel">
                <ResourceRequestCard requestDetails={itemDetails} />
                <ResourceRequestControlPanel
                  itemToLink={selectedItem}
                  onDropSelection={this.onDropSelection}
                  onApprove={this.handleApprove}
                  onDecline={this.handleDecline}
                  onAccept={this.handleAccept}
                  requestStatus={itemDetails.status}/>
              </div>
            </>
          )
        }
      </div>
    );
  }
}

export default connect(
  ({ user, resourceRequests, resources }) => ({ user, resourceRequests, resources }),
  { getResourceRequestDetails, approveRequest, declineRequest, acceptRequest }
)(ResourceRequestDetailsPage);