import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ViewSelector.less';

const ViewSelector = (props) => {
  const { views, activeViewKey, onViewChange } = props;
  const [currentViewKey, setViewKey] = useState(activeViewKey);

  const handleViewChange = (key) => {
    onViewChange(key);
    setViewKey(key);
  };
  return (
    <div className="view-selector">
      {views.map(({ key, title }) => {
        let classes = classNames('view-selector--item', {
          selected: key === currentViewKey,
        });

        return (
          <span key={key} onClick={() => handleViewChange(key)} className={classes}>
            {title}
          </span>
        );
      })}
    </div>
  );
};

ViewSelector.propTypes = {
  views: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  onViewChange: PropTypes.func,
  activeViewKey: PropTypes.string,
};

ViewSelector.defaultProps = {
  onViewChange: () => {},
  activeViewKey: '',
};

export default ViewSelector;
