import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { routes, routeBuilder } from '../routing';
import { Link } from 'react-router-dom';
import { resourceRequestNumberTemplate } from '../utility';
import { promoteToAdminAsync, demoteToRegularAsync } from '../store/userManagement/actionCreators';
import { Spin, Empty, Timeline, Button, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  fetchAdminUserProfile, clearAdminUserProfile,
  fetchUserInvestments, fetchUserLoans, fetchUserRequests,
  confirmEmail, unlinkLoan, unlinkInvestment, deleteResourceRequest
} from '../store/adminUserProfile/actionCreators';
import { deleteUser } from '../store/user/actionCreators';

import Highlight from '../components/Highlight/Highlight';

import './styles/_adminUserProfile.less';

const LoanListBasic = ({
  loans,
  loading,
  userId,
  onUnlink
}) => (
  <div className="user-admin-profile__loans">
    <h3>Loans</h3>
    <div>
      {
        loading &&
          <Spin
            size="large"
            className="rsrq-details_spinner"
            indicator={
              <LoadingOutlined spin />
            } />
      }
      {
        !loading && loans && loans.map(loan => (
          <div className="basic-list-item" key={`loanitem${loan.id}`}>
            <h4><Link to={routeBuilder.userAccountLoanDetails(userId, loan.id)}>{loan.number} {loan.name}</Link></h4>
            <div className="basic-list-item__controls">
              <Button type="danger" ghost size="small" onClick={() => { onUnlink(loan.id); }}>Unlink</Button>
            </div>
          </div>
        ))
      }
      {
        !loading && (loans === null || loans.length === 0) &&
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Loans Linked"
          />
      }
    </div>
  </div>
);

const InvestmentListBasic = ({
  loading,
  investments,
  userId,
  onUnlink
}) => (
  <div className="user-admin-profile__investments">
    <h3>Investments</h3>
    <div>
      {
        loading &&
          <Spin
            size="large"
            className="rsrq-details_spinner"
            indicator={
              <LoadingOutlined spin />
            }
          />
      }
      {
        !loading && investments && investments.length > 0 && investments.map(investment => (
          <div className="basic-list-item" key={`investment${investment.id}`}>
            <h4><Link to={routeBuilder.userAccountInvestmentDetails(userId, investment.id)}>{investment.number} {investment.name}</Link></h4>
            <div className="basic-list-item__controls">
              <Button type="danger" ghost size="small" onClick={() => { onUnlink(investment.id) }}>Unlink</Button>
            </div>
          </div>
        ))
      }
      {
        !loading && (investments === null || investments.length === 0) &&
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Investments Linked"
          />
      }
    </div>
  </div>
);

const statusMap = {
  '0': 'open',
  '1': 'accepted',
  '2': 'declined',
  '3': 'approved'
}

const statusColorMap = {
  '0': 'blue',
  '1': 'cyan',
  '2': 'red',
  '3': 'green'
}

const requestTypeMap = {
  '0': 'Loan',
  '1': 'Investment'
}

const ResourceRequestListBasic = ({
  requests,
  loading,
  onDelete
}) => (
  <div className="user-admin-profile__resource-requests">
    <h3>Resource Requests</h3>
    <div>
      {
        loading &&
          <Spin
            size="large"
            className="rsrq-details_spinner"
            indicator={
              <LoadingOutlined spin />
            }
          />
      }
      {
        !loading && requests && requests.length > 0 &&
          <div className="request-list-item__heading">
            <span>Request Number</span>
            <span>Requested resource number</span>
            <span>Status</span>
            <span>Actions</span>
          </div>
      }
      {
        !loading && requests && requests.length > 0 && requests.map(request => (
          <div className="request-list-item" key={`request${request.id}`}>
            <h4>
              <Link to={routeBuilder.resourceRequestDetails(request.id)}>
                {`${resourceRequestNumberTemplate(request.id)}:${requestTypeMap[request.resourceType]}`}
              </Link>
            </h4>
            <span>{request.resourceNumber}</span>
            <Tag color={statusColorMap[request.status]}>{statusMap[request.status]}</Tag>
            <div className="basic-list-item__controls">
              <Button
                type="danger"
                ghost
                size="small"
                onClick={() => { onDelete(request.id); }}
                disabled={request.status === 3 || request.status === 2}
              >
                Delete
              </Button>
            </div>
          </div>
        ))
      }
      {
        !loading && requests && requests.length === 0 &&
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Resource Requests"
          />
      }
    </div>
  </div>
);

const LoginHistoryBasic = ({ logins }) => (
  <div className="user-admin-profile__login-history">
    <h3>Login History</h3>
    {
      logins && logins.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Logins"
        />
      )
    }
    {
      logins && (
        <Timeline>
          {
            logins.map(({ loginDate }) => (
              <Timeline.Item key={loginDate}>
                {moment(loginDate).format('MM/DD/YYYY HH:mm A')}
              </Timeline.Item>
            ))
          }
        </Timeline>
      )
    }
  </div>
);

const AdminUserProfilePage = ({
  history,
  match: {
    params: {
      userId
    }
  },
  adminUserProfile: {
    profile,
    isProfileFetched,
    loans,
    isLoansFetched,
    investments,
    isInvestmentsFetched,
    requests,
    isRequestsFetched
  },
  user: {
    id: currentUserId,
    token,
    isUserDeletionInProgress
  },
  fetchAdminUserProfile,
  fetchUserInvestments,
  fetchUserLoans,
  fetchUserRequests,
  clearAdminUserProfile,
  promoteToAdminAsync,
  demoteToRegularAsync,
  confirmEmail,
  unlinkLoan,
  unlinkInvestment,
  deleteResourceRequest,
  deleteUser
}) => {
  useEffect(() => {
    fetchAdminUserProfile(userId)
      .then(() => {
        fetchUserInvestments(userId);
        fetchUserLoans(userId);
        fetchUserRequests(userId);
      });
    return clearAdminUserProfile();
  }, [])

  const promoteToAdmin = () => {
    promoteToAdminAsync &&
      promoteToAdminAsync(profile.id).then(() => {
        fetchAdminUserProfile(profile.id);
      });
  };

  const demoteToRegular = () => {
    demoteToRegularAsync &&
      demoteToRegularAsync(profile.id).then(() => {
        fetchAdminUserProfile(profile.id);
      });
  };

  const handleConfirmEmail = () => {
    confirmEmail &&
      confirmEmail(profile.id).then(() => {
        fetchAdminUserProfile(profile.id);
      });
  };

  const handleLoanUnlink = (loanId) => {
    unlinkLoan &&
      unlinkLoan(profile.id, loanId).then(() => {
        fetchUserLoans(profile.id);
        fetchUserRequests(profile.id);
      });
  };

  const handleInvestmentUnlink = (investmentId) => {
    unlinkInvestment
      && unlinkInvestment(profile.id, investmentId).then(() => {
        fetchUserInvestments(profile.id);
        fetchUserRequests(profile.id);
      });
  };

  const handleResourceRequestDelete = (requestId) => {
    deleteResourceRequest
      && deleteResourceRequest(profile.id, requestId).then(() => {
        fetchUserRequests(profile.id);
      });
  };

  const handleUserDelete = () => {
    deleteUser(token, userId).then(() => {
      history.push(routes.users);
    });
  };

  if (isProfileFetched || !profile) {
    return (
      <Spin
        size="large"
        className="rsrq-details_spinner"
        indicator={
          <LoadingOutlined spin />
        }
      />
    );
  }

  const accountTypeActions = profile.isAdmin
    ? [{ label: 'Downgrade to Regular', action: demoteToRegular }]
    : [{ label: 'Promote to Admin', action: promoteToAdmin }];

  const emailStatusActions = !profile.isEmailConfirmed
    ? [{ label: 'Confirm Email', action: handleConfirmEmail }]
    : null;

  return (
    <div className="user-admin-profile">
      <div className="user-admin-profile__header">
        <h2>
          <Link to={routes.users}>Users / </Link>
          <span>{!!profile && profile.fullName}</span>
        </h2>
        <div className="right-stack-panel">
          {
            currentUserId !== profile.id && (
              <Button
                type="danger"
                loading={isUserDeletionInProgress}
                onClick={handleUserDelete}
              >
                Delete User
              </Button>
            )
          }
        </div>
      </div>
      <div className="user-admin-profile__wrapper-highlights">
        <div className="user-admin-profile__highlights">
          <Highlight headerText="Account Type" className="item" actions={accountTypeActions} contentText={profile.isAdmin ? 'Admin' : 'Regular' } />
          <Highlight headerText="Registered On" className="item" contentText={moment(profile.registeredOn).format('MM/DD/YYYY')} />
          <Highlight headerText="Email Status" className="item" actions={emailStatusActions} contentText={profile.isEmailConfirmed ? 'Confirmed' : 'Need Confirmation'} />
          <Highlight headerText="Email Address" className="item" contentText={profile.email} />
          <Highlight headerText="Organization" className="item" contentText={!!profile.organizationName ? profile.organizationName : 'N/A'} />
        </div>
      </div>
      <LoginHistoryBasic logins={profile.userLogins} />
      <div className="user-admin-profile__data">
        <LoanListBasic onUnlink={handleLoanUnlink} userId={userId} loans={loans} loading={isLoansFetched} />
        <InvestmentListBasic onUnlink={handleInvestmentUnlink} userId={userId} investments={investments} loading={isInvestmentsFetched} />
      </div>
      <ResourceRequestListBasic onDelete={handleResourceRequestDelete} userId={userId} requests={requests} loading={isRequestsFetched} />
    </div>
  );
};

export default connect(
  ({ adminUserProfile, user }) => ({ adminUserProfile, user }),
  {
    fetchAdminUserProfile,
    clearAdminUserProfile,
    fetchUserInvestments,
    fetchUserLoans,
    fetchUserRequests,
    promoteToAdminAsync,
    demoteToRegularAsync,
    confirmEmail,
    unlinkLoan,
    unlinkInvestment,
    deleteResourceRequest,
    deleteUser
  })(AdminUserProfilePage);
