export const FETCH__SESSIONS = 'FETCH__SESSIONS';
export const FETCH__SESSIONS__SUCCESS = 'FETCH__SESSIONS__SUCCESS';
export const FETCH__SESSIONS__FAILED = 'FETCH__SESSIONS__FAILED';

export const FETCH__SESSION_JOBS = 'FETCH__SESSION_JOBS';
export const FETCH__SESSION_JOBS__SUCCESS = 'FETCH__SESSION_JOBS__SUCCESS';
export const FETCH__SESSION_JOBS__FAIL = 'FETCH__SESSION_JOBS__FAIL';

export const START__SESSION = 'START__SESSION';
export const START__SESSION__SUCCESS = 'START__SESSION__SUCCESS';
export const START__SESSION__FAIL = 'START__SESSION__FAIL';

export const UPDATE__SELECTED_SESSION = 'UPDATE__SELECTED_SESSION';
export const CLEAR__SELECTED_SESSION = 'CLEAR__SELECTED_SESSION';

export const CREATE__SESSION = 'CREATE__SESSION';
export const CREATE__SESSION__SUCCESS = 'CREATE__SESSION__SUCCESS';
export const CREATE__SESSION__FAIL = 'CREATE__SESSION__FAIL';

export const CANCEL__SESSION = 'CANCEL__SESSION';
export const CANCEL__SESSION__SUCCESS = 'CANCEL__SESSION__SUCCESS';
export const CANCEL__SESSION__FAIL = 'CANCEL__SESSION__FAIL';