import React from 'react';

import AuthLayout from '../../common/layout/auth/AuthLayout';
import ConfirmEmailForm from '../../auth/confirm-email/ConfirmEmail';
import ConfirmEmailImg from '../../assets/images/sunset.jpg';

const ConfirmEmailPage = ({ location }) => (
  <AuthLayout path={ConfirmEmailImg}>
    <ConfirmEmailForm location={location}/>
  </AuthLayout>
);

export default ConfirmEmailPage;
