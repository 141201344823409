import {
  REQUEST__MOVE_TO_VAULT,
  REQUEST__MOVE_TO_VAULT_FAIL,
  REQUEST__MOVE_TO_VAULT_SUCCESS,
  DOWNLOAD__VAULT_FILE,
  DOWNLOAD__VAULT_FILE__FAIL,
  DOWNLOAD__VAULT_FILE__SUCCESS,
  FETCH__USER_STATEMENTS,
  FETCH__USER_STATEMENTS__SUCCESS,
  FETCH__USER_STATEMENTS__FAIL,
} from './actions';
import { LOG_OUT } from '../user/actions';
import { authHeaders, actionTemplate, userAndAuthHeaders } from '../shared';
import axios from 'axios';
import { statementEndpoints } from '../../const/backend';
import FileSaver  from 'file-saver';

const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export const moveToVault = (statementId, token) => dispatch => {
  dispatch({ ...actionTemplate(REQUEST__MOVE_TO_VAULT), fileId: statementId });

  return new Promise((resolve, reject) => {
    axios.post(
      statementEndpoints.moveToVault(statementId),
      { statementId: statementId },
      { headers: { ...authHeaders(token) }}
    ).then(response => {
      if (response.status === 200) {
        dispatch(actionTemplate(REQUEST__MOVE_TO_VAULT_SUCCESS));
        resolve();
      }
    }).catch(({ response }) => {
      if (response.status === 401) {
        dispatch(actionTemplate(LOG_OUT));
        reject();

        return;
      }

      dispatch(actionTemplate(REQUEST__MOVE_TO_VAULT_FAIL))
      reject();
    });
  })
}

export const downloadFile = (statementId, token) => dispatch => {
  dispatch({ ...actionTemplate(DOWNLOAD__VAULT_FILE), fileId: statementId });

  return new Promise((resolve, reject) => {
    axios.get(
      statementEndpoints.downloadStatement(statementId), {
        responseType: 'blob',
        timeout: 30000,
        headers: { ...authHeaders(token) }
      }
    ).then(response => {
      if (response.status === 200) {
        let matches = filenameRegex.exec(response.headers['content-disposition']);
        let filename = matches[1].replace(/['"]/g, '');

        dispatch(actionTemplate(DOWNLOAD__VAULT_FILE__SUCCESS));
        FileSaver.saveAs(response.data, filename);
        resolve();
      }
    }).catch(({ response }) => {
      dispatch(actionTemplate(DOWNLOAD__VAULT_FILE__FAIL));
      reject();
    });
  });
}

export const fetchUsersStatements = (userId, token) => dispatch => {
  dispatch(actionTemplate(FETCH__USER_STATEMENTS));

  const headers = !userId
    ? authHeaders(token)
    : userAndAuthHeaders(token, userId);

  axios.get(
    statementEndpoints.userStatements(),
    { headers }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ ...actionTemplate(FETCH__USER_STATEMENTS__SUCCESS), statements: response.data })
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__USER_STATEMENTS__FAIL));
  });
}
