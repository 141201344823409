import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import { Alert, Input, Button } from 'antd';
import { useFormik } from 'formik';

import FormControl from '../../common/FormControl/FormControl';
import { routes } from '../../routing';
import { login } from '../../store/user/actionCreators'
import loginFormLabels from '../../labels/login-form';
import { initialFormValues, LoginSchema } from './const';

import './login-form.less';

const { Password } = Input;

const LoginForm = ({
  login,
  user: {
    token,
    isTokenRequested,
    isTokenRequestFailed
  }
}) => {
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: LoginSchema,
    onSubmit: ({ email, password }) => {
      login && login(email, password);
    }
  });

  if (token) {
    return <Redirect to={routes.root} />
  }

  return (
    <div className="tmf-login-form">
      <h1>{loginFormLabels.header}</h1>
      <div className="tmf-login-form_main">
        <Alert
          className={classNames('tmf-login-form_alert', { 'hidden': !isTokenRequestFailed })}
          type="error"
          showIcon
          message={loginFormLabels.validation.loginFailed}
        />
        <form onSubmit={formik.handleSubmit}>
          <FormControl
            id="email"
            caption="Email Address"
            touched={formik.touched.email}
            error={formik.errors.email}
          >
            <Input id="email" {...formik.getFieldProps('email')} />
          </FormControl>
          <FormControl
            id="password"
            caption="Password"
            touched={formik.touched.password}
            error={formik.errors.password}
          >
            <Password id="password" {...formik.getFieldProps('password')} />
          </FormControl>
          <div className="tmf-login-form_links">
            <Link to={routes.forgotPassword}>Forgot Password?</Link>
            <span>Not a user? <Link to={routes.register}>Sign-Up</Link></span>
          </div>
          <div className="tmf-login-form_controls">
            <Button
              type="primary"
              htmlType="submit"
              loading={isTokenRequested}
            >
              Log In
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(
  ({ user }) => ({ user }),
  { login }
)(LoginForm);
