import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { routes } from '../../routing';
import PropTypes from 'prop-types';
import SiteLogo from '../../common/SiteLogo/SiteLogo';
import UserLogo from '../../common/UserLogo/UserLogo'
import mainHeaderLabels from '../../labels/main-header';

import './header.less';

const { Item, Divider } = Menu;

export class Header extends Component {
  render() {
    let { onEditProfile, onLogout } = this.props;
    const menu = (
      <Menu>
        <Item key="0" onClick={onEditProfile}>
          <Link to={routes.profileSettings}>{mainHeaderLabels.userMenu.editProfile}</Link>
        </Item>
        <Divider />
        <Item key="1" onClick={onLogout}>
          {mainHeaderLabels.userMenu.logOut}
        </Item>
      </Menu>
    );

    return (
      <div className="header-wrapper">
        <header className="main-heading">
          <div className="main-heading_credits">
            <Link className="main-heading_home-link" to={routes.root}>
              <SiteLogo />
            </Link>
            <h2>{mainHeaderLabels.subHeader}</h2>
          </div>
          <div className="main-heading_profile">
            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
              <div className="main-heading_profile-icon">
                <UserLogo />
              </div>
            </Dropdown>
          </div>
          <div className="main-heading_nav">
            <nav>
              <ul className="main-heading_menu">
                <li>
                  <NavLink
                    exact
                    className="main-heading_menu-item"
                    activeClassName="main-heading_menu-item--active"
                    to={routes.root}
                  >
                    {mainHeaderLabels.links.dashboard}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="main-heading_menu-item"
                    activeClassName="main-heading_menu-item--active"
                    to={routes.users}
                  >
                    {mainHeaderLabels.links.users}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="main-heading_menu-item"
                    activeClassName="main-heading_menu-item--active"
                    to={routes.resourceRequests}
                  >
                    {mainHeaderLabels.links.requests}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="main-heading_menu-item"
                    activeClassName="main-heading_menu-item--active"
                    to={routes.dataImport}
                    exact
                  >
                    {mainHeaderLabels.links.dataImport}
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </div>
    );
  }
}

Header.propTypes = {
  onEditProfile: PropTypes.func,
  onLogout: PropTypes.func,
};

Header.defaultProps = {
  onEditProfile: () => {},
  onLogout: () => {},
};
