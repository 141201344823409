import axios from 'axios';
import { actionTemplate, userAndAuthHeaders, authHeaders } from '../shared';
import { resourceRequestEndpoints } from '../../const/backend';
import { LOG_OUT } from '../user/actions';
import {
  FETCH__USERS_RRQ_LIST,
  FETCH__USERS_RRQ_LIST__SUCCESS,
  FETCH__USERS_RRQ_LIST__FAIL,
  CREATE__RRQ,
  CREATE__RRQ__FAIL,
  CREATE__RRQ__SUCCESS,
} from './actions';

export const fetchUsersRequestsByUserId = (userId, token) => dispatch => {
  dispatch(actionTemplate(FETCH__USERS_RRQ_LIST));

  axios.get(
    resourceRequestEndpoints.root(), {
      headers: userAndAuthHeaders(token, userId)
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ ...actionTemplate(FETCH__USERS_RRQ_LIST__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__USERS_RRQ_LIST__FAIL));
  });
}

export const fetchUsersRequests = (token) => dispatch => {
  dispatch(actionTemplate(FETCH__USERS_RRQ_LIST));

  axios.get(
    resourceRequestEndpoints.root(), {
      headers: authHeaders(token)
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ ...actionTemplate(FETCH__USERS_RRQ_LIST__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__USERS_RRQ_LIST__FAIL));
  });
}

export const createResourceRequest = (number, requestType, userId, token) => dispatch => {
  dispatch(actionTemplate(CREATE__RRQ));

  axios.post(
    resourceRequestEndpoints.root(), {
      requestType,
      number
    }, {
      headers: !!userId ? userAndAuthHeaders(token, userId) : authHeaders(token)
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch(actionTemplate(CREATE__RRQ__SUCCESS));
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(CREATE__RRQ__FAIL));
  });
}

