import { RECIEVE_RES, FETCH_RES, CLEAR_RES_SEARCH } from '../actions';

const defaultState = {
  items: [],
  isLoading: false,
};

const resources = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_RES:
      return { ...state, isLoading: true, };
    case RECIEVE_RES:
      return {
        ...state,
        isLoading: false,
        items: action.items,
      };
    case CLEAR_RES_SEARCH:
      return {
        ...state,
        items: []
      };
    default:
      return state;
  }
}

export default resources;

