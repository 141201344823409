import React, { PureComponent } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { dataImportSessionNumberTemplate } from '../../utility';

const statusMap = {
  '1': 'created',
  '2': 'inprogress',
  '3': 'inprogress',
  '4': 'success'
};

const statusLabelMap = {
  '1': 'Waiting for Files',
  '2': 'Ready to start',
  '3': 'In Progress',
  '4': 'Succeeded',
  '5': 'Failed'
}

export default class DataImoprtSessionListItem extends PureComponent {
  handleClick = () => {
    let { session, onClick } = this.props;

    !!onClick && onClick(session);
  }

  render() {
    var { session, selected } = this.props;
    let rootClasses = classNames(
      'data-import-sessions__list-item', {
        [`data-import-sessions__list-item--${statusMap[session.status]}`]: true,
        'data-import-sessions__list-item--selected': selected
      }
    );
    let descriptionClasses = classNames(
      'data-import-sessions__list-item-description', {
        [`data-import-sessions__list-item-description--${statusMap[session.status]}`]: true
      }
    )
    let descriptionContent;
    
    switch (session.status)
    {
      case 1:
      case 2:
      case 3:
        descriptionContent = (
          <p>{statusLabelMap[session.status]}</p>
        );

        break;
      case 4:
      case 5:
        descriptionContent = (
          <p>{`${statusLabelMap[session.status]} on ${moment(session.completedOn).format('MMMM Do YYYY HH:mm:ss')}`}</p>
        );

        break;
    }

    return (
      <div className={rootClasses} onClick={this.handleClick}>
        <div className="data-import-sessions__list-item-header">
          <h3>{dataImportSessionNumberTemplate(session.id)}</h3>
          <p>{`on ${moment(session.createdOn).format('MMMM Do YYYY')}`}</p>
        </div>
        <div className={descriptionClasses}>{descriptionContent}</div>
      </div>
    );
  }
};
