import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchUsersRequests, fetchUsersRequestsByUserId } from '../../store/resourceRequests/actionCreators';
import { statuses as resourceRequestStatuses } from '../../const/resource-request';
import { resourceRequestNumberTemplate } from '../../utility';
import { Spin, Result, Table, Modal, Comment, Avatar, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { routes, routeBuilder } from '../../routing';
import { adminRole, userRole } from '../../const/user';

import './_userProfile.scss';

const { Column } = Table;

const statusMap = {
  '0': 'Open',
  '1': 'Accepted',
  '2': 'Declined',
  '3': 'Approved'
}

class ResourceRequestList extends Component {
  componentDidMount() {
    let { user: { token }, fetchUsersRequests, fetchUsersRequestsByUserId, userId } = this.props;

    if (!!userId) {
      fetchUsersRequestsByUserId(userId, token);
    } else {
      fetchUsersRequests(token);
    }
  }

  render() {
    let {
      userResourceRequests: {
        isUsersRequestsFetched,
        isUsersRequestFetchFailed,
        items
      },
      user: {
        role
      }
    } = this.props;

    if (isUsersRequestsFetched) {
      return (
        <Spin
          size="large"
          className="rsrq-details_spinner"
          indicator={
            <LoadingOutlined spin />
          }
        />
      );
    }

    if (isUsersRequestFetchFailed) {
      return (
        <Result
          status="error"
          title="Failed to retrieve requests"
          subTitle="Please try again a bit later"
        />
      );
    }

    let itemsActive = !!items && items.filter((item) => item.status !== resourceRequestStatuses.approved);

    return (
      <div className="rrq-list">
        {
          role === adminRole &&
            <Alert
              className="rrq-admin-alert"
              message="As an Aministrator..."
              description="...you see more info on this page such as 'Internal Number' colum or exact status of a request (for user it merged to 'Pending Approval' for 'Open' and 'Accepted')"/>
        }
        <p className="rrq-table-description"><sup>*</sup>double click on 'Declined' requests to see details</p>
        <Table
          size="middle"
          rowKey="id"
          className="rrq-table"
          dataSource={itemsActive}
          pagination={{
            hideOnSinglePage: true
          }}
          onRow={(record) => {
            if (record.status !== 2) return;

            return {
              onDoubleClick: event => {
                Modal.info({
                  title: 'Request was declined',
                  className: 'rejected-rrq_modal',
                  content: (
                    <>
                      <p className="rejected-rrq_modal_description">Your request was declined. Please take a look at the details below:</p>
                      <Comment
                        className="rejected-rrq_comment"
                        author={record.processedBy.fullName}
                        content={<p>{record.remark}</p>}
                        avatar={<Avatar>TMF</Avatar>}
                        datetime={<span>{moment(record.lastModifiedOn).fromNow()}</span>}
                      />
                    </>
                  )
                })
              }
            }
          }}
        >
          {
            role === adminRole && (
              <Column
                title="Internal Number"
                dataIndex="id"
                render={id => (
                  <Link to={routeBuilder.resourceRequestDetails(id)}>{resourceRequestNumberTemplate(id)}</Link>
                )}
              />
            )
          }
          <Column title="Request Type" dataIndex="requestType" render={(type) => (<span>{type === 0 ? 'Loan' : 'Investment' }</span>)}/>
          <Column title="Number Requested" dataIndex="number" />
          <Column
            title="Status"
            dataIndex="status"
            render={(status) => (role === userRole ?
              <span>{status > 1 ? statusMap[status] : 'Pending Approval'}</span> :
              <span>{statusMap[status]}</span>
            )}
          />
          <Column
            title="Created On"
            dataIndex="createdOn"
            render={(createdOn) => (
              <div>
                <span>{moment(createdOn).format('MM/DD/YYYY')}</span>
                <span className="rrq-list__item-date__rel-date">{`(${moment(createdOn).fromNow()})`}</span>
              </div>
            )}
          />
          <Column
            title="Last Modified On"
            dataIndex="lastModifiedOn"
            render={(lastModifiedOn) => (
              <div>
                <span>{moment(lastModifiedOn).format('MM/DD/YYYY')}</span>
                <span className="rrq-list__item-date__rel-date">{`(${moment(lastModifiedOn).fromNow()})`}</span>
              </div>
            )}
          />
        </Table>
      </div>
    );
  }
}

export default connect(
  ({ user, userResourceRequests }) => ({ user, userResourceRequests }),
  { fetchUsersRequests, fetchUsersRequestsByUserId }
)(ResourceRequestList);
