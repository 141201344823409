import React from 'react';

import SiteLogo from '../../SiteLogo/SiteLogo';

import './auth-layout.less';

const AuthLayout = ({
  children,
  path,
}) => (
  <div className="auth-root">
    <div className="auth-root__wrapper">
      <img className="auth-root__image" src={`${path}`} alt="image" />
    </div>

    <div className="auth-root__content">
      <SiteLogo className="auth-root__logo" />
      {children}
    </div>
  </div>
);

export default AuthLayout;
