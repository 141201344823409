import axios from 'axios';
import { authorizedRequestHeaders, usersEndpoints } from '../../const/backend';
import { LOG_OUT } from '../user/actions';
import {
  FETCH__USER_LIST__FAIL,
  FETCH__USER_LIST__SUCCESS,
  FETCH__USER_LIST,
  PROMOTE__USER__FAIL,
  PROMOTE__USER__SUCCESS,
  PROMOTE__USER,
  REVOKE__ADMIN_RIGHTS__FAIL,
  REVOKE__ADMIN_RIGHTS__SUCCESS,
  REVOKE__ADMIN_RIGHTS,
} from './actions';

const actionTemplate = (type) => ({ type });

export const fetchUsers = (skip, limit, search, cityId, view, token) => dispatch => {
  dispatch(actionTemplate(FETCH__USER_LIST));

  axios.get(
    usersEndpoints.list(skip, limit, search, cityId, view), {
      headers: { ...authorizedRequestHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({
        ...actionTemplate(FETCH__USER_LIST__SUCCESS),
        data: response.data
      });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__USER_LIST__FAIL));
  });
}

export const promoteUser = (userId, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(actionTemplate(PROMOTE__USER));
  
    axios.post(
      usersEndpoints.promote(), {
        userId
      }, {
        headers: { ...authorizedRequestHeaders(token) }
      }
    ).then(response => {
      if (response.status === 200) {
        dispatch(actionTemplate(PROMOTE__USER__SUCCESS));
        resolve(true);

        return;
      }
      reject(false);
    }).catch(({ response }) => {
      if (response.status === 401) {
        dispatch(actionTemplate(LOG_OUT));
        reject(false);

        return;
      }

      dispatch(actionTemplate(PROMOTE__USER__FAIL));
      reject(false);
    });
  });
}

export const promoteToAdminAsync = (userId) => (dispatch, getState) => {
  let { user: { token }} = getState();
  dispatch(actionTemplate(PROMOTE__USER));

  return axios.post(
    usersEndpoints.promote(), {
      userId
    }, {
      headers: { ...authorizedRequestHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch(actionTemplate(PROMOTE__USER__SUCCESS));

      return;
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(PROMOTE__USER__FAIL));
  });
};

export const demoteToRegularAsync = (userId) => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(REVOKE__ADMIN_RIGHTS));

  return axios.post(
    usersEndpoints.revoke(), {
      userId
    }, {
      headers: { ...authorizedRequestHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch(actionTemplate(REVOKE__ADMIN_RIGHTS__SUCCESS));
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(REVOKE__ADMIN_RIGHTS__FAIL));
  });
};

export const revokeAccess = (userId, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(actionTemplate(REVOKE__ADMIN_RIGHTS));

    axios.post(
      usersEndpoints.revoke(), {
        userId
      }, {
        headers: { ...authorizedRequestHeaders(token) }
      }
    ).then(response => {
      if (response.status === 200) {
        dispatch(actionTemplate(REVOKE__ADMIN_RIGHTS__SUCCESS));
        resolve(true);
      }
    }).catch(({ response }) => {
      dispatch(actionTemplate(REVOKE__ADMIN_RIGHTS__FAIL));
      if (response.status === 401) {
        dispatch(actionTemplate(LOG_OUT));
        reject(false);

        return;
      }

      reject(false);
    });
  });
}