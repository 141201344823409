import axios from 'axios';
import { authHeaders, actionTemplate } from '../shared';
import { statementEndpoints, syncJobsEndpoints } from '../../const/backend';
import {
  FETCH__STATEMENT_SCAN_STATUS,
  FETCH__STATEMENT_SCAN_STATUS__FAIL,
  FETCH__STATEMENT_SCAN_STATUS__SUCCESS,
  START__STATEMENTS_SCAN,
  START__STATEMENTS_SCAN__FAIL,
  START__STATEMENTS_SCAN__SUCCESS
} from './actions';
import { LOG_OUT } from '../user/actions';

export const startRepositoryScan = (token) => dispatch => {
  dispatch(actionTemplate(START__STATEMENTS_SCAN));

  return axios.post(
    statementEndpoints.scanRepository(),
    {},
    { headers: { ...authHeaders(token) }}
  ).then((response) => {
    if (response.status === 200) {
      dispatch(actionTemplate(START__STATEMENTS_SCAN__SUCCESS));
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(START__STATEMENTS_SCAN__FAIL));
  });
};

export const fetchStatementScanStatus = (token) => dispatch => {
  dispatch(actionTemplate(FETCH__STATEMENT_SCAN_STATUS));

  return axios.get(
    syncJobsEndpoints.getActiveRepositoryScanJob(),
    { headers: { ...authHeaders(token) }}
  ).then((response) => {
    if (response.status === 200) {
      dispatch({ 
        ...actionTemplate(FETCH__STATEMENT_SCAN_STATUS__SUCCESS),
        isScanInProgress: true,
      });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    if (response.status === 404) {
      dispatch({ 
        ...actionTemplate(FETCH__STATEMENT_SCAN_STATUS__SUCCESS),
        isScanInProgress: false,
      });

      return;
    }

    dispatch(actionTemplate(FETCH__STATEMENT_SCAN_STATUS__FAIL));
  });
};

