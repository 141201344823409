import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { wrapNegativeAndAlign } from '../../utility';

import './_accountActivityTable.scss';

const { Column } = Table;

const AccountActivityTable = ({ activities }) => {
  const keyedActivities = activities.map((item, index) => ({
    ...item,
    key: `acc-activity-${index}`
  }));

  return (
    <div className="acc-activity-table">
      <Table
        dataSource={keyedActivities}
        size="middle"
        pagination={{
          pageSize: 30,
          hideOnSinglePage: true,
          showSizeChanger: false
        }}
      >
        <Column title="Asset #" dataIndex="assetNumber" key="assetNumber" />
        <Column title="Date" dataIndex="date" key="date" render={(date) => moment(date).format('MM/DD/YYYY')} />
        <Column title="Category" dataIndex="categoryDescription" key="categoryDescription" />
        <Column title="Notes" dataIndex="note" key="note" />
        <Column title="Reference" dataIndex="reference" key="reference" />
        <Column title="Amount" dataIndex="amount" key="amount" render={num => wrapNegativeAndAlign(num)} className="acc-activity-table_number-cell"/>
      </Table>
    </div>
  );
}

export default AccountActivityTable;
