import React from 'react';
import { connect } from 'react-redux';

import { routes } from '../../../routing';
import { logout } from '../../../store/user/actionCreators';
import mainHeaderLabels from '../../../labels/main-header';
import PortalLayout from '../../layout/portal-layout/PortalLayout';
import Navigation from '../../HeaderComponents/NavigationMenu/NavigationMenu';
import ProfileMenu from '../../HeaderComponents/ProfileMenu/ProfileMenu';

import './admin-page.less';

const AdminPage = ({
  children,
  logout,
}) => {
  const adminNavItems = [
    [mainHeaderLabels.links.dashboard, { to: routes.root, exact: true }],
    [mainHeaderLabels.links.users, { to: routes.users }],
    [mainHeaderLabels.links.requests, { to: routes.resourceRequests }],
    [mainHeaderLabels.links.dataImport, { to: routes.dataImport, exact: true }]
  ];

  return (
    <PortalLayout
      userMenu={<ProfileMenu onLogout={logout} />}
      logoAside={
        <div className="admin-header-label">
          <h2>{mainHeaderLabels.subHeader}</h2>
        </div>
      }
      navigation={<Navigation items={adminNavItems} />}
    >
      {children}
    </PortalLayout>
  );
};

export default connect(
  () => ({}),
  { logout }
)(AdminPage);
