import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import './styles/add-resource.scss';

export default class AddResourceModal extends Component {
  state = {
    numbers: [''],
  };

  handleNumberChange = ({ target }) => {
    let { numbers } = this.state;
    let itemIndex = target.getAttribute('data-key');

    numbers[itemIndex] = target.value;

    this.setState({ numbers: numbers });
  };

  handleNumberDelete = ({ target }) => {
    let { numbers } = this.state;
    let itemIndex = +target.getAttribute('data-key');

    numbers = numbers.filter((item, index) => index !== itemIndex);

    this.setState({ numbers });
  };

  handleAddMore = () => {
    let { numbers } = this.state;
    numbers.push('');

    this.setState({ numbers });
  };

  handleOk = () => {
    let { numbers } = this.state;
    let { onSubmit } = this.props;

    numbers = numbers.filter((number) => number !== '');

    onSubmit(numbers);
  };

  handleAfterClose = () => {
    this.setState({ numbers: [''] });
  };

  render() {
    let { title, visible, description, onCancel, loading, numberPlaceholderText } = this.props;
    let { numbers } = this.state;

    return (
      <Modal
        className="add-resource-modal"
        visible={visible}
        onCancel={onCancel}
        title={title}
        okText="Request"
        destroyOnClose="true"
        confirmLoading={loading}
        afterClose={this.handleAfterClose}
        onOk={this.handleOk}
      >
        <p>{description}</p>
        <p>
          Your request is currently being reviewed. To check the status of your request, log into
          the portal and select the Status tab.
        </p>
        {numbers.map((number, index) => {
          return (
            <div key={index} className="resource-number">
              <Input
                data-key={index}
                className="resource-number__input"
                placeholder={numberPlaceholderText}
                value={number}
                onChange={this.handleNumberChange}
                allowClear
                suffix={
                  <Button
                    disabled={numbers.length === 1}
                    size="small"
                    type="link"
                    icon={<DeleteOutlined />}
                    data-key={index}
                    onClick={this.handleNumberDelete}
                  />
                }
              />
            </div>
          );
        })}
        {numbers.length < 5 && (
          <div className="rrq__add-more">
            <Button disabled={numbers.length > 4} type="link" onClick={this.handleAddMore}>
              Add more...
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}

AddResourceModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  numberPlaceholderText: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

AddResourceModal.defaultProps = {
  title: 'Resource Request Modal',
  description: 'Description',
  numberPlaceholderText: 'Enter resource number',
  visible: false,
  loading: false,
  onCancel: () => {},
  onSubmit: () => {},
};
