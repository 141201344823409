import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createResourceRequest } from '../../store/resourceRequests/actionCreators';
import { fetchUsersRequests, fetchUsersRequestsByUserId } from '../../store/resourceRequests/actionCreators';
import moment from 'moment';
import { Link } from 'react-router-dom'
import { Spin, Empty, Button, Table, Result } from 'antd';
import { LoadingOutlined, QuestionOutlined } from '@ant-design/icons';
import AddResourceModal from '../Modals/AddResourceModal';
import { routeBuilder } from '../../routing';
import { wrapNegativeAndAlign } from '../../utility';

import './_investmentList.scss';

const { Column } = Table;

const investmentListLabels = {
  modals: {
    addResource: {
      title: 'Request Investment Information',
      description: 'Please enter desired Account Number to be added. You may enter multiple account numbers by selecting ‘Add more’.',
      numberPlaceholderText: 'Enter account number'
    }
  }
}

class InvestmentList extends Component {
  state = {
    isAddResourceModalVisible: false,
  }

  toggleModal = () => {
    let { isAddResourceModalVisible } = this.state;

    this.setState({ isAddResourceModalVisible: !isAddResourceModalVisible });
  }

  handleRequestsSubmit = numbers => {
    let { user: { token }, createResourceRequest, fetchUsersRequestsByUserId, fetchUsersRequests, userId } = this.props;
    let uniqueNumbers = new Set(numbers);
    let promises = [];

    for (let investmentNumber of uniqueNumbers) {
      promises.push(createResourceRequest(investmentNumber, 1, userId, token));
    }

    Promise.all(promises).then(() => {
      this.setState({ isAddResourceModalVisible: false }, () => {
        if (!!userId) {
          fetchUsersRequestsByUserId(userId, token);
        } else {
          fetchUsersRequests(token);
        }
      });
    });
  }

  render() {
    let { isAddResourceModalVisible } = this.state;
    let {
      loading,
      items,
      userId,
      userResourceRequests: {
        isCreateResourceRequestInProgress
      }
    } = this.props;

    if (loading) {
      return (
        <Spin
          size="large"
          className="rsrq-details_spinner"
          indicator={
            <LoadingOutlined spin />
          }
        />
      );
    }

    if (!!items && items.length === 0) {
      return (
        <>
          <Empty
            className="loan-content--empty"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Investments found">
              <Button
                type="primary"
                onClick={this.toggleModal}
              >
                Request Investment Info
              </Button>
          </Empty>
          <AddResourceModal
            loading={isCreateResourceRequestInProgress}
            visible={isAddResourceModalVisible}
            onCancel={this.toggleModal}
            onSubmit={this.handleRequestsSubmit}
            title={investmentListLabels.modals.addResource.title}
            description={investmentListLabels.modals.addResource.description}
            numberPlaceholderText={investmentListLabels.modals.addResource.numberPlaceholderText}
          />
        </>
      );
    }

    return (
      <div className="investment-list">
        {
          !!items && items.map((item) => (
            <div className="investment-list_item" key={item.id}>
              <Link to={!!userId ? routeBuilder.userAccountInvestmentDetails(userId, item.id) : routeBuilder.investmentDetails(item.id) }>
                <h4 className="investment-list_item-header">
                  <span className="link">{item.number}</span>
                  <span className="name">{item.name}</span>
                </h4>
              </Link>
              <Table dataSource={item.assets} size="small" rowKey="id" pagination={ {hideOnSinglePage: true}}>
                <Column
                  title="Asset #"
                  dataIndex="number"
                  key="number"
                  render={(number, record) => {
                    return (
                      <Link className="asset-link" to={
                        !!userId ?
                          routeBuilder.userAccountAssetDetails(userId, item.id, record.id) :
                          routeBuilder.assetDetails(item.id, record.id)
                        }
                      >
                        {number}
                      </Link>);
                  }}
                />
                <Column title="Description" dataIndex="name" key="name" />
                <Column className="investment-list_asset-number-cell" title="Cost" dataIndex="cost" key="cost" render={num => wrapNegativeAndAlign(num)} />
                <Column className="investment-list_asset-number-cell" title="Market Value" dataIndex="marketValue" key="marketValue" render={num => wrapNegativeAndAlign(num)} />
                <Column className="investment-list_asset-number-cell" title="Accrued Int." dataIndex="receivable" key="receivable" render={num => wrapNegativeAndAlign(num)}/>
                <Column className="investment-list_asset-number-cell" title="Rate" dataIndex="rate" key="rate" render={num => `${num.toFixed(4)}%`}/>
                <Column
                  title="Maturity"
                  dataIndex="maturity"
                  key="maturity"
                  render={(value) => (<span>{!!value ? moment(value).format('DD/MM/YYYY') : '--'}</span>)} />
              </Table>
            </div>
          ))
        }
        {
          !!items && items.length > 0 && (
            <div className="investment-list_item investment-list_item--lastone">
               <Result
                title="Can't find Investment info?"
                subTitle="No worries! Request that information."
                icon={<QuestionOutlined />}
                extra={
                  <Button type="ghost" onClick={this.toggleModal}>Request Investment Info</Button>
                }
              />
              <AddResourceModal
                loading={isCreateResourceRequestInProgress}
                visible={isAddResourceModalVisible}
                onCancel={this.toggleModal}
                onSubmit={this.handleRequestsSubmit}
                title={investmentListLabels.modals.addResource.title}
                description={investmentListLabels.modals.addResource.description}
                numberPlaceholderText={investmentListLabels.modals.addResource.numberPlaceholderText}
              />
            </div>
          )
        }
      </div>
    );
  }
}

InvestmentList.defaultProps = {
  loading: false
};

export default connect(
  ({ user, userResourceRequests }) => ({ user, userResourceRequests }),
  { createResourceRequest, fetchUsersRequestsByUserId, fetchUsersRequests }
)(InvestmentList);
