import {
  FETCH_STATES,
  FETCH_STATES_SUCCESS,
  FETCH_STATES_FAILED,
  FETCH_CITIES,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILED,
} from './actions';

const defaultState = {
  states: [],
  cities: [],
  isStatesRequested: false,
  isStatesRequestFailed: false,
  isCitiesRequested: false,
  isCitiesRequestFailed: false
};

const geo = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_STATES:
      return {
        ...state,
        isStatesRequestFailed: false,
        isStatesRequested: true
      };
    case FETCH_STATES_SUCCESS:
      return {
        ...state,
        isStatesRequested: false,
        states: action.states
      };
    case FETCH_STATES_FAILED:
      return {
        ...state,
        isStatesRequestFailed: true
      };
    case FETCH_CITIES:
      return {
        ...state,
        isCitiesRequested: true,
        isCitiesRequestFailed: false
      };
    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        isCitiesRequested: false,
        cities: action.cities
      };
    case FETCH_CITIES_FAILED:
      return {
        ...state,
        isCitiesRequested: false,
        isCitiesRequestFailed: true
      };
    default:
      return state;
  }
};

export default geo;