export const FETCH_USER_TOKEN = 'FETCH_USER_TOKEN';
export const FETCH_USER_TOKEN__SUCCESS = 'FETCH_USER_TOKEN__SUCCESS';
export const FETCH_USER_TOKEN__FAILED = 'FETCH_USER_TOKEN__FAILED';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER__SUCCESS = 'REGISTER_USER__SUCCESS';
export const REGISTER_USER__FAILED = 'REGISTER_USER__FAILED';

export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const RESTORE_PASSWORD__SUCCESS = 'RESTORE_PASSWORD__SUCCESS';
export const RESTORE_PASSWORD__FAILED = 'RESTORE_PASSWORD__FAILED';

export const RESET_RESETPASSWORDSTATE = 'RESET_RESETPASSWORDSTATE';
export const RESET_REGISTERSTATE = 'RESET_REGISTERSTATE';

export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_EMAIL__SUCCESS = 'CONFIRM_EMAIL__SUCCESS';
export const CONFIRM_EMAIL__FAILED = 'CONFIRM_EMAIL__FAILED';

export const RESET_CONFIRMEMAILSTATE = 'RESET_CONFIRMEMAILSTATE';

export const VERIFY_RESET_PASSWORD_TOKEN = 'VERIFY_RESET_PASSWORD_TOKEN';
export const VERIFY_RESET_PASSWORD_TOKEN__SUCCESS = 'VERIFY_RESET_PASSWORD_TOKEN__SUCCESS';
export const VERIFY_RESET_PASSWORD_TOKEN__FAILED = 'VERIFY_RESET_PASSWORD_TOKEN__FAILED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD__SUCCESS = 'CHANGE_PASSWORD__SUCCESS';
export const CHANGE_PASSWORD__FAILED = 'CHANGE_PASSWORD__FAILED';

export const RESET_CHANGEPASSWORDSTATE = 'RESET_CHANGEPASSWORDSTATE';

export const STORE_EMAIL = 'STORE_EMAIL';
export const LOG_OUT = 'LOG_OUT';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER__SUCCESS = 'DELETE_USER__SUCCESS';
export const DELETE_USER__FAIL = 'DELETE_USER__FAIL';
