import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { startRepositoryScan, fetchStatementScanStatus } from '../store/dashboard/actionCreators';
import { Result, Spin, Button } from 'antd';
import { ScanOutlined } from '@ant-design/icons';

import DashboardCard from '../components/DashboardCard/DashboardCard';

import './styles/_adminDashboard.scss';

class AdminDashboardPage extends PureComponent {
  intervalId = 0;

  componentDidMount() {
    let {
      user: {
        token
      },
      fetchStatementScanStatus
    } = this.props;

    fetchStatementScanStatus(token).then(() => {
      this.intervalId = setInterval(() => {
        let {
          dashboard: {
            isScanInProgress
          },
        } = this.props;

        fetchStatementScanStatus(token);

        !isScanInProgress && clearInterval(this.intervalId);
      },
      5000);
    });
  };

  componentWillUnmount() {
    !!this.intervalId && clearInterval(this.intervalId);
  };

  handleStartScan = () => {
    let {
      user: {
        token
      },
      startRepositoryScan,
      fetchStatementScanStatus
    } = this.props;

    startRepositoryScan(token).then(() => {
      fetchStatementScanStatus(token);

      this.intervalId = setInterval(() => {
        let {
          dashboard: {
            isScanInProgress
          },
        } = this.props;

        fetchStatementScanStatus(token);

        !isScanInProgress && clearInterval(this.intervalId);
      },
      5000);
    });
  };

  render() {
    let {
      dashboard: {
        fetchStatementScanStatus,
        isScanInProgress,
        isStartingStatementsScan
      }
    } = this.props;

    let tipText = fetchStatementScanStatus
      ? 'Checking job status...'
      : isScanInProgress
        ? 'Scanning Statements Repository...'
        : '';

    return (
      <div className="admin__dashboard">
        <DashboardCard title="Statements Repository Scan">
          {

          }
          <Spin spinning={fetchStatementScanStatus || isScanInProgress} tip={tipText}>
            <Result
              icon={<ScanOutlined />}
              title="Want to see new statements on the portal?"
              subTitle="Please click on a button down below to achieve that. It might take up to 15 minutes. Process status will be displayed here."
              extra={
                <Button
                  onClick={this.handleStartScan}
                  disabled={isScanInProgress}
                  loading={isStartingStatementsScan}
                  type="primary">
                    Start Scan
                </Button>}
            />
          </Spin>
        </DashboardCard>
      </div>
    );
  }
};

export default connect (
  ({ user, dashboard }) => ({ user, dashboard }),
  { startRepositoryScan, fetchStatementScanStatus }
)(AdminDashboardPage);