import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { retrieveRequestResolution, rollbackRequest } from '../../actions/resourceRequestActions';

import { Result, Spin, Button } from 'antd';
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons';

class ApprovedRequestDetails extends Component {
  componentDidMount() {
    let {
      user: {
        token
      },
      requestId,
      retrieveRequestResolution
    } = this.props;

    retrieveRequestResolution(requestId, token)
  }

  handleRollback = () => {
    let {
      user: {
        token
      },
      requestId,
      rollbackRequest
    } = this.props;

    rollbackRequest(requestId, token);
  }

  render() {
    let {
      resourceRequests: {
        resolution
      }
    } = this.props;

    return (
      <Result
        icon={<CheckOutlined />}
        title="Request was approved"
        subTitle={
          <Fragment>
            <h3>Linked resource</h3>
            {
              !!resolution && !!resolution.item &&
              <div className="rsrq-resolution_resource-card">
                <h6>{resolution.item.linkedWith.resourceType === 1 ? 'Investment' : 'Loan' }</h6>
                <p>{resolution.item.linkedWith.number}: {resolution.item.linkedWith.name}</p>
              </div>
            }
            {
              !resolution &&
              <Spin indicator={<LoadingOutlined spin />} />
            }
          </Fragment>
        }
        extra={
          <Button type="primary" onClick={this.handleRollback}>Rollback</Button>
        }
       />
    );
  }
}

export default connect(
  ({ user, resourceRequests }) => ({ user, resourceRequests }),
  { retrieveRequestResolution, rollbackRequest }
)(ApprovedRequestDetails);

