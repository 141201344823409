import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUsers, promoteUser, revokeAccess } from '../../store/userManagement/actionCreators';
import ViewSelector from '../ViewSelector/ViewSelector';
import UserSearchPanel from './UsersSearchPanel';
import CityFilterPanel from './CityFilterPanel';
import { UsersListPageable } from './UsersListPageable';
import { Tag, message } from 'antd';

import { allChecked, allUnchecked, filterFlags } from '../../const/user-search';
import { routeBuilder } from '../../routing';

import './_userView.scss';

export let usersGroup = [
  { id: 0, name: 'All Users' },
  { id: 1, name: 'Registered Today' },
  { id: 2, name: 'Registered This Week' },
  { id: 3, name: 'Requires Approval' },
  { id: 4, name: 'Administrators' }
];

const userViews = [{
  key: 'all',
  title: 'All Users'
}, {
  key: 'registered-today',
  title: 'Registered Today'
}, {
  key: 'registered-this-week',
  title: 'Registered This Week'
}, {
  key: 'administrators',
  title: 'Administrators'
}];

const viewMap = {
  'all': 0,
  'registered-today': 1,
  'registered-this-week': 2,
  'administrators': 4
};

const ITEMS_PER_PAGE = 20;

class UsersView extends Component {
  state = {
    cityId: undefined,
    view: 'all',
    searchScope: 0b1111,
    searchQuery: '',
    filterApplied: {
      cityName: '',
      searchQuery: ''
    }
  }

  userActions = {
    promoteUser: undefined,
    revokeAccess: undefined
  };

  componentDidMount() {
    let { fetchUsers, user: { token } } = this.props;
    let { cityId, view, searchScope, searchQuery } = this.state;
    fetchUsers(0,
      ITEMS_PER_PAGE,
      { scope: searchScope, query: searchQuery },
      cityId,
      viewMap[view],
      token);
  }

  handleViewChange = (viewKey) => {
    this.setState({ view: viewKey }, () => {
      this.handleQueryChange();
    });
  }

  handleCityChange = (cityId) => {
    this.setState({ cityId });
  }

  handleSearchParamsChanged = ({ searchOptions, searchQuery }) => {
    this.setState({
      searchScope: searchOptions,
      searchQuery
    });
  }

  handleQueryChange = () => {
    let { fetchUsers, user: { token }, geo: { cities } } = this.props;
    let { cityId, view, searchScope, searchQuery } = this.state;
    let city = cities.filter(city => city.id === cityId);
    let cityName = !!city && city.length > 0
      ? city[0].name
      : '';

    this.setState({ filterApplied: { searchQuery, cityName }});

    fetchUsers(0,
      ITEMS_PER_PAGE,
      { scope: searchScope, query: searchQuery },
      cityId,
      viewMap[view],
      token);
  }

  handlePageChange = (page, pageSize) => {
    let { fetchUsers, user: { token } } = this.props;
    let { cityId, view, searchScope, searchQuery } = this.state;

    fetchUsers(page - 1,
      pageSize,
      { scope: searchScope, query: searchQuery },
      cityId,
      viewMap[view],
      token);
  }

  handleSearchCheckAll = () => {
    let { searchScope } = this.state;

    this.setState({ searchScope: searchScope | allChecked})
  }

  handleSearchUncheckAll = () => {
    let { searchScope } = this.state;

    this.setState({ searchScope: searchScope & allUnchecked});
  }

  handleSearchScopeChanged = ({ target: { name }}) => {
    let { searchScope } = this.state;
    this.setState({
      searchScope: searchScope ^ filterFlags[name]
    });
  }

  handleSearchQueryChanged = ({ target: { value }}) => {
    this.setState({ searchQuery: value });
  }

  handleQueryClear = () => {
    this.setState({ searchQuery: '', filterApplied: { searchQuery: '' }},
    this.handleQueryChange);
  }

  handleCityClear = () => {
    this.setState( { cityId: undefined, filterApplied: { cityName: '' }},
      this.handleQueryChange);
  }

  handlePromote = (userId) => {
    let { user: { token } } = this.props;
    let { promoteUser } = this.props;
    let hideProgress = message.loading("Promoting user...", 0);

    promoteUser(userId, token)
      .then((isSuccess) => {
        hideProgress();
        if (isSuccess) {
          message.success("User was promoted successfully");
          this.handleQueryChange();

          return;
        }
      })
      .catch(isSuccess => {
        hideProgress();
        message.error('User promotion failed. Please try again later');
      });
  }

  handleRevoke = (userId) => {
    let { user: { token } } = this.props;
    let { revokeAccess } = this.props;
    let hideProgress = message.loading("Revoking access", 0);

    revokeAccess(userId, token)
      .then((isSuccess) => {
        hideProgress();
        if (isSuccess) {
          message.success("Access was revoked sucessfully");
          this.handleQueryChange();
        }
      })
      .catch((isSuccess) => {
        hideProgress();
        message.error("Revoke access failed! Try again later");
      });
  }

  handleUserDoubleClick = (userId) => {
    let { history } = this.props;

    history.push(routeBuilder.userAccountLoans(userId));
  }

  render() {
    let {
      userManagement: {
        isUsersFetched,
        users,
        pageInfo
      }
    } = this.props;
    let { filterApplied, searchScope, searchQuery, cityId } = this.state;

    return (
      <div className="users-view">
        <div className="users-view_filtering">
          <UserSearchPanel
            onSearch={this.handleQueryChange}
            onCheckAll={this.handleSearchCheckAll}
            onUncheckAll={this.handleSearchUncheckAll}
            onScopeChanged={this.handleSearchScopeChanged}
            onQueryChanged={this.handleSearchQueryChanged}
            searchScope={searchScope}
            searchQuery={searchQuery}
          />
          <CityFilterPanel
            cityId={cityId}
            onCityChanged={this.handleCityChange}
            onApplyFilter={this.handleQueryChange}
          />
        </div>
        <div className="users-view_list-header">
          <ViewSelector
            views={userViews}
            activeViewKey="all"
            onViewChange={this.handleViewChange}
          />
          <div className="search-tags">
            {
              !!filterApplied && !!filterApplied.searchQuery &&
                <Tag color="blue" closable onClose={this.handleQueryClear} >{filterApplied.searchQuery}</Tag>
            }
            {
              !!filterApplied && !!filterApplied.cityName &&
              <Tag color="blue" closable onClose={this.handleCityClear} >{filterApplied.cityName}</Tag>
            }
          </div>
        </div>
        <UsersListPageable
          users={users}
          loading={isUsersFetched}
          itemsPerPage={ITEMS_PER_PAGE}
          itemsTotal={!!pageInfo ? pageInfo.itemsCount : 0}
          onPageChanged={this.handlePageChange}
          onPromoteUser={this.handlePromote}
          onRevokeAccess={this.handleRevoke}
          onUserDoubleClick={this.handleUserDoubleClick}
        />
      </div>
    );
  }
}

export default connect(
  ({ userManagement, user, geo }) => ({ userManagement, user, geo }),
  { fetchUsers, promoteUser, revokeAccess }
)(UsersView);
