import {
  REQUEST_RESOURCE_REQUESTS,
  RECEIVE_RESOURCE_REQUESTS,
  REQUEST_RSRQ_DETAILS,
  RECEIVE_RSRQ_DETAILS,
  RSRQ_APPROVE,
  HANDLE_RSRQ_APPROVE,
  RSRQ_ACCEPT,
  HANDLE_RSRQ_ACCEPT,
  RSRQ_DECLINE,
  HANDLE_RSRQ_DECLINE,
  FETCH_RSRQ_RESOLUTION,
  HANDLE_RSRQ_RESOLUTION,
  RRQ_DELETE,
  RRQ_DELETE_SUCCESS
} from './index';
import axios from 'axios';
import { message } from 'antd';


const firmUrl = window.location.origin;
const viewMap = {
  'all': 0,
  'completed': 1,
  'open': 2,
  'mine': 3
};

const authHeaders = (token) => ({
  'Authorization': `Bearer ${token}`
});

export const listResourceRequests = (viewKey, token) => dispatch => {
  dispatch({ type: REQUEST_RESOURCE_REQUESTS });

  axios.get(
    `${firmUrl}/api/resourcerequest`, {
      params: {
        view: viewMap[viewKey]
      },
      headers: { ...authHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({
        type: RECEIVE_RESOURCE_REQUESTS,
        items: response.data });
    }
  })
}

export const getResourceRequestDetails = (id, token) => dispatch => {
  dispatch({ type: REQUEST_RSRQ_DETAILS });

  axios.get(
    `${firmUrl}/api/resourcerequest/${id}`, {
      headers: { ...authHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({
        type: RECEIVE_RSRQ_DETAILS,
        item: response.data
      });
    }
  });
}

export const approveRequest = (id, resourceType, itemId, token) => dispatch => {
  dispatch({ type: RSRQ_APPROVE });

  axios.post(
    `${firmUrl}/api/resourcerequest/${id}/events`, {
      eventId: 2,
        payload: {
          itemId,
          resourceType
        }
    }, {
      headers: { ...authHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: HANDLE_RSRQ_APPROVE })
      dispatch(getResourceRequestDetails(id, token));
      message.success('Request was sucessfully approved', 5);
    }
  });
}

export const acceptRequest = (id, token) => dispatch => {
  dispatch({ type: RSRQ_ACCEPT });

  axios.post(
    `${firmUrl}/api/resourcerequest/${id}/events`, {
      eventId: 0
    }, {
      headers: { ...authHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: HANDLE_RSRQ_ACCEPT });
      dispatch(getResourceRequestDetails(id, token));
      message.success('Request was successfully accepted', 5);
    }
  });
}

export const rollbackRequest = (id, token) => dispatch => {
  dispatch({ type: RSRQ_ACCEPT });

  axios.post(
    `${firmUrl}/api/resourcerequest/${id}/events`, {
      eventId: 3
    }, {
      headers: { ...authHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: HANDLE_RSRQ_ACCEPT });
      dispatch(getResourceRequestDetails(id, token));
      message.success('Request was successfully rolled back', 5);
    }
  });
}

export const declineRequest = (id, message, token) => dispatch => {
  dispatch({ type: RSRQ_DECLINE });

  axios.post(
    `${firmUrl}/api/resourcerequest/${id}/events`, {
      eventId: 1,
      payload: { 
        message: message 
      }
    }, {
      headers: { ...authHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: HANDLE_RSRQ_DECLINE });
      dispatch(getResourceRequestDetails(id, token));
    }
  });
}

export const retrieveRequestResolution = (id, token) => dispatch => {
  dispatch({ type: FETCH_RSRQ_RESOLUTION });

  axios.get(
    `${firmUrl}/api/resourcerequest/${id}/resolution`, {
      headers: { ...authHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({
        type: HANDLE_RSRQ_RESOLUTION,
        item: response.data
      });
    }
  });
}

export const deleteResourceRequest = (requestId, token) => dispatch => {
  dispatch({ type: RRQ_DELETE });

  return axios.delete(
    `${firmUrl}/api/resourcerequest/${requestId}`, {
      headers: { ...authHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: RRQ_DELETE_SUCCESS })
    }
  });
}