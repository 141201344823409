export const FETCH__USER_LIST = 'FETCH__USER_LIST';
export const FETCH__USER_LIST__SUCCESS = 'FETCH__USER_LIST__SUCCESS';
export const FETCH__USER_LIST__FAIL = 'FETCH__USER_LIST__FAIL';

export const PROMOTE__USER = 'PROMOTE__USER';
export const PROMOTE__USER__SUCCESS = 'PROMOTE__USER__SUCCESS';
export const PROMOTE__USER__FAIL = 'PROMOTE__USER__FAIL';

export const REVOKE__ADMIN_RIGHTS = 'REVOKE__ADMIN_RIGHTS';
export const REVOKE__ADMIN_RIGHTS__SUCCESS = 'REVOKE__ADMIN_RIGHTS__SUCCESS';
export const REVOKE__ADMIN_RIGHTS__FAIL = 'REVOKE__ADMIN_RIGHTS__FAIL';