import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, Redirect } from 'react-router-dom';

import { routes } from '../../routing';
import { confirmEmail, resetConfirmEmailState } from '../../store/user/actionCreators';
import confirmEmailLabels from '../../labels/confirm-email';

import './confirm-email.less';

const ConfirmEmailForm = ({
  user: {
    isConfirmEmailRequested,
    isConfirmEmailRequestFailed,
    confirmEmailAttemptsCount,
    token
  },
  location: {
    search
  },
  confirmEmail,
  resetConfirmEmailState
}) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const id = urlParams.get('id'),
          token = urlParams.get('token');

    confirmEmail(id, token);

    return () => { resetConfirmEmailState(); };
  }, []);


  if (!!token) {
    return (<Redirect to={routes.root} />);
  }

  return (
    <div className="confirm-email-form">
      <h1>{confirmEmailLabels.header}</h1>
      {
        isConfirmEmailRequested && (
          <Spin
            className="confirm-email-form_spinner"
            size='large'
            indicator={<LoadingOutlined spin />}
          />
        )
      }
      {
        isConfirmEmailRequestFailed && (
          <>
            <h2>{confirmEmailLabels.result.fail.subHeader}</h2>
            <div className="confirm-email-form_controls">
              <Button type='link'>
                <Link to={routes.login}>
                  {confirmEmailLabels.buttons.goToLogin}
                </Link>
              </Button>
            </div>
          </>
        )
      }
      {
        !isConfirmEmailRequested
        && confirmEmailAttemptsCount > 0
        && !isConfirmEmailRequestFailed && (
          <>
            <h2>{confirmEmailLabels.result.success.subHeader}</h2>
            <div className="confirm-email-form_controls">
              <Button type='link'>
                <Link to={routes.login}>
                  {confirmEmailLabels.buttons.goToLogin}
                </Link>
              </Button>
            </div>
          </>
        )
      }
    </div>
  );
};

export default connect(
  ({ user }) => ({ user }),
  { confirmEmail, resetConfirmEmailState }
)(ConfirmEmailForm);
