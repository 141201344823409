import React from 'react';

import UserAccountPage from '../../common/pages/user-account-page/UserAccountPage';
import ResourceRequestList from '../../components/UserProfile/ResourceRequestsList';

const ResourceRequestStatusPage = ({
  match: {
    params: {
      userId
    }
  }
}) => {
  return (
    <UserAccountPage
      userId={userId}
      loadProfile
    >
      <ResourceRequestList userId={userId} />
    </UserAccountPage>
  );
};

export default ResourceRequestStatusPage;
