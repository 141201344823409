import React, { Component } from 'react';
import { Result } from 'antd';

export default class DataImportResultStep extends Component {
  render() {
    return (
      <div>
         <Result
            status="success"
            title="Data Import Session was successfully completed"
         />
      </div>
    );
  }
}