import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUsersStatements, downloadFile } from '../../store/statements/actionCreators';

import { Spin, Result, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import VaultInvestmentView from './VaultInvestmentView';

import './_statementsVault.scss';

class StatementsVault extends Component {
  componentDidMount() {
    let {
      user: {
        token
      },
      userId,
      fetchUsersStatements
    } = this.props;

    fetchUsersStatements(userId, token);
  }

  handleFileDownload = statementId => {
    let {
      user: {
        token
      },
      downloadFile
    } = this.props;

    downloadFile(statementId, token);
  }

  render() {
    let {
      statements: {
        isUserStatementsRequested,
        userStatements
      }
    } = this.props;

    if (isUserStatementsRequested) {
      return (
        <Spin
          size="large"
          className="rsrq-details_spinner"
          indicator={
            <LoadingOutlined spin />
          }
        />
      );
    }

    if (!!userStatements) {
      let hasItemsToDownload = userStatements.map(statement => {
        return statement.statements.some(x => x.isDownloadAvailable);
      });

      let isThereNothingToDownload = hasItemsToDownload.every(item => !item);

      if (isThereNothingToDownload) {
        return (
          <Result
            className="vault_how-to"
            status="info"
            title="That's place for your statements"
            subTitle="If you want to download statements you need to generate them first. Please, follow next steps"
          >
            <ul>
              <li>Go to 'Investments' tab of your account</li>
              <li>Choose an Investment and open its details</li>
              <li>Initiate PDF generation by clicking on 'Generate PDF' button near desired statement from 'Statemets' table</li>
            </ul>
            <Alert
              message="After that you can download statements right from Investment details page or you can find statements aggregated from all investments here"
              type="info"
              showIcon />
          </Result>
        )
      }
    }

    return (
      <div className="vault_investments-list">
      {
        !!userStatements && userStatements.map((statement, index) => {
          let hasItemsToDownload = statement.statements.some(x => x.isDownloadAvailable);

          return hasItemsToDownload && <VaultInvestmentView onDownloadClick={this.handleFileDownload} investmentView={statement} key={index} />;
        })
      }
      </div>
    );
  }
}

export default connect(
  ({ user, statements }) => ({ user, statements }),
  { fetchUsersStatements, downloadFile }
)(StatementsVault);