import axios from 'axios';
import { actionTemplate, authHeaders } from '../shared';
import { LOG_OUT } from '../user/actions';
import { adminUserProfileEndpoints } from '../../const/backend';
import {
  FETCH__ADMIN_USER_PROFILE,
  FETCH__ADMIN_USER_PROFILE__FAIL,
  FETCH__ADMIN_USER_PROFILE__SUCCESS,
  CLEAR__ADMIN_USER_PROFILE,
  FETCH__USER_LOANS,
  FETCH__USER_LOANS__SUCCESS,
  FETCH__USER_LOANS__FAIL,
  FETCH__USER_ACCOUNTS,
  FETCH__USER_ACCOUNTS__FAIL,
  FETCH__USER_ACCOUNTS__SUCCESS,
  FETCH__USER_REQUESTS,
  FETCH__USER_REQUESTS__SUCCESS,
  FETCH__USER_REQUESTS__FAIL,
  CONFIRM__PROFILE__USER_EMAIL,
  CONFIRM__PROFILE__USER_EMAIL__SUCCESS,
  CONFIRM__PROFILE__USER_EMAIL__FAIL,
  UNLINK__LOAN,
  UNLINK__LOAN__SUCCESS,
  UNLINK__LOAN__FAIL,
  UNLINK__INVESTMENT,
  UNLINK__INVESTMENT__FAIL,
  UNLINK__INVESTMENT__SUCCESS,
  DELETE__PROFILE__RESOURCE_REQUEST,
  DELETE__PROFILE__RESOURCE_REQUEST__FAIL,
  DELETE__PROFILE__RESOURCE_REQUEST__SUCCESS
} from './actions';

export const confirmEmail = (userId) => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(CONFIRM__PROFILE__USER_EMAIL));

  return axios.patch(
    adminUserProfileEndpoints.confirmEmail(userId),
    null,
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch(actionTemplate(CONFIRM__PROFILE__USER_EMAIL__SUCCESS));
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(CONFIRM__PROFILE__USER_EMAIL__FAIL));
  });
}

export const unlinkLoan = (userId, loanId) => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(UNLINK__LOAN));

  return axios.delete(
    adminUserProfileEndpoints.unlinkLoan(userId, loanId),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch(actionTemplate(UNLINK__LOAN__SUCCESS));
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(UNLINK__LOAN__FAIL));
  });
}
export const unlinkInvestment = (userId, investmentId) => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(UNLINK__INVESTMENT));

  return axios.delete(
    adminUserProfileEndpoints.unlinkInvestment(userId, investmentId),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch(actionTemplate(UNLINK__INVESTMENT__SUCCESS));
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(UNLINK__INVESTMENT__FAIL));
  });
}

export const deleteResourceRequest = (userId, requestId) => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(DELETE__PROFILE__RESOURCE_REQUEST));

  return axios.delete(
    adminUserProfileEndpoints.deleteResourceRequest(userId, requestId),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch(actionTemplate(DELETE__PROFILE__RESOURCE_REQUEST__FAIL));
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(DELETE__PROFILE__RESOURCE_REQUEST__SUCCESS));
  });
}

export const fetchAdminUserProfile = (userId) => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(FETCH__ADMIN_USER_PROFILE));

  return axios.get(
    adminUserProfileEndpoints.profile(userId),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch({...actionTemplate(FETCH__ADMIN_USER_PROFILE__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__ADMIN_USER_PROFILE__FAIL));
  });
}

export const clearAdminUserProfile = () => dispatch => {
  dispatch(actionTemplate(CLEAR__ADMIN_USER_PROFILE));
}

export const fetchUserLoans = (userId) => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(FETCH__USER_LOANS));

  return axios.get(
    adminUserProfileEndpoints.loans(userId),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch({...actionTemplate(FETCH__USER_LOANS__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__USER_LOANS__FAIL));
  });
}

export const fetchUserInvestments = (userId) => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(FETCH__USER_ACCOUNTS));

  return axios.get(
    adminUserProfileEndpoints.investments(userId),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch({...actionTemplate(FETCH__USER_ACCOUNTS__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__USER_ACCOUNTS__FAIL));
  });
}

export const fetchUserRequests = (userId) => (dispatch, getState) => {
  let { user: { token }} = getState();

  dispatch(actionTemplate(FETCH__USER_REQUESTS));

  return axios.get(
    adminUserProfileEndpoints.requests(userId),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch({...actionTemplate(FETCH__USER_REQUESTS__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__USER_REQUESTS__FAIL));
  });
}

