const setNewPasswordLabels = {
  header: 'Reset password',
  validation: {
    password: {
      minLength: 'At least 8 characters long',
      capitalLetterRequired: 'At least one capital letter',
      digitReuired: 'At least one digit',
      specialCharacterRequired: 'At least one special character',
      required: 'Can\'t be empty',
    },
    confirmPassword: {
      required: 'Can\'t be empty',
      sameAsPassword: 'Doesn\'t match',
    }
  },
  result: {
    fail: {
      subHeader: 'Something went wrong',
      invalidTokenDescription: 'It seems like your link is expired. Please retry attempt or contact support.'
    },
    success: {
      subHeader: 'New password was set'
    }
  },
  buttons: {
    goToLogin: 'Go to Login page',
    changePassword: 'Change password'
  },
  controls: {
    password: {
      label: 'New Password',
      placeholder: 'Enter new password'
    },
    confirmPassword: {
      label: 'Confirm Password',
      placeholder: 'Repeat entered password'
    }
  }
}

export default setNewPasswordLabels;
