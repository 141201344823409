import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchDataImportFiles } from '../../store/dataImportFiles/actionCreators';
import { startSession, fetchDataImportJobsBySessionId, clearSelectedSession, fetchDataImportSessions, cancelSession } from '../../store/dataImportSessions/actionCreators';
import { Steps, Spin, Button } from 'antd';
import { LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import UploadFilesStep from './UploadFilesStep';
import DataImportProgressStep from './DataImportProgressStep';
import DataImportResultStep from './DataImportResultStep';
import { dataImportSessionNumberTemplate } from '../../utility';
import { sessionStatuses } from '../../const/data-import';

import './_dataImportWizard.scss';


const { Step } = Steps;

class DataImportWizard extends Component {
  constructor(props) {
    super(props);

    let {
      dataImportSessions: {
        selectedSession: { status }
      }
    } = this.props;

    this.state = {
      currentStep: status === sessionStatuses.created ?
        0 :
        status === sessionStatuses.succeeded ? 2 : 1
    };
  }

  componentDidMount() {
    let {
      user: { token },
      dataImportSessions: {
        selectedSession: { id }
      },
      fetchDataImportFiles } = this.props;

    fetchDataImportFiles(token, id);
  }

  handleAllFilesUploaded = () => {
    let {
      user: { token },
      dataImportSessions: {
        selectedSession
      },
      fetchDataImportSessions,
      fetchDataImportFiles
    } = this.props;

    fetchDataImportSessions(token).then(() => fetchDataImportFiles(token, selectedSession.id));
  }

  handleNextStepClick = () => {
    let { currentStep } = this.state;

    currentStep++;
    this.setState({ ...this.state, currentStep });
  }

  handlePreviousStepClick = () => {
    let { currentStep } = this.state;

    currentStep--;
    this.setState({ ...this.state, currentStep });
  }

  handleSessionStart = () => {
    let {
      user: {
        token
      },
      dataImportSessions: {
        selectedSession
      },
      startSession,
      fetchDataImportSessions,
      fetchDataImportJobsBySessionId
    } = this.props;

    startSession(token, selectedSession.id).then(() => {
      fetchDataImportSessions(token).then(() => fetchDataImportJobsBySessionId(token, selectedSession.id));
    });
  }

  handleAllJobsCompleted = () => {
    let { user: { token }, fetchDataImportSessions } = this.props;

    fetchDataImportSessions(token).then(() => {
      let { dataImportSessions: { selectedSession } } = this.props;

      if (selectedSession.status === sessionStatuses.succeeded) {
        this.setState({ ...this.state, currentStep: 2 });
      }
    })
  }

  handleCloseClick = () => {
    let { clearSelectedSession } = this.props;

    clearSelectedSession();
  }

  handleCancelSession = () => {
    let {
      user: {
        token
      },
      dataImportSessions: {
        selectedSession
      },
      cancelSession,
      clearSelectedSession,
      fetchDataImportSessions
    } = this.props;

    !!cancelSession
      && cancelSession(selectedSession.id).then(() => {
        debugger;
        clearSelectedSession();
        fetchDataImportSessions(token);
      })
  }

  render() {
    let {
      user: {
        token
      },
      dataImportFiles: {
        isItemsFetched,
        items
      },
      dataImportSessions: {
        selectedSession
      }
    } = this.props;
    let { currentStep } = this.state;

    currentStep = selectedSession.status === 1 ? 0 : selectedSession.status === 4 ? 2 : currentStep;

    let stepContent = (
      <Spin
        size="large"
        className="rsrq-details_spinner"
        indicator={
          <LoadingOutlined spin />
        }
      />
    );

    if (!isItemsFetched) {
      switch (currentStep) {
        case 0:
          stepContent = (
            <UploadFilesStep
              uploadedFiles={items.map(item => item.dataImportJobType)}
              token={token}
              onCancel={this.handleCancelSession}
              onNextClick={this.handleNextStepClick}
              onAllFilesUploaded={this.handleAllFilesUploaded} />);
          break;
        case 1:
          stepContent = (
            <DataImportProgressStep
              onAllJobsCompleted={this.handleAllJobsCompleted}
              onBackClick={this.handlePreviousStepClick}
              onNextClick={this.handleSessionStart} />
          );

          break;
        case 2:
          stepContent = (
            <DataImportResultStep />
          );

          break;
        default:
          break;
      }
    }

    return (
      <div className="dis-wizard">
        <div className="dis-wizard__header">
          <h2>Data Import Session: {dataImportSessionNumberTemplate(selectedSession.id)}</h2>
          {
            !!selectedSession && selectedSession.status === 4 &&
              <Button shape="circle" icon={<CloseOutlined />} onClick={this.handleCloseClick} />
          }
        </div>
        <Steps current={currentStep}>
          <Step title="File Upload" description="Upload all necessary files to continue" />
          <Step title="Run Import" description="Start import session and monitor progress" />
          <Step title="Import Result" description="Enjoy" />
        </Steps>
        {stepContent}
      </div>
    );
  }
}

export default connect(
  ({ user, dataImportFiles, dataImportSessions }) => ({ user, dataImportFiles, dataImportSessions }),
  { fetchDataImportFiles, startSession, fetchDataImportJobsBySessionId, fetchDataImportSessions, clearSelectedSession, cancelSession }
)(DataImportWizard);
