import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import { wrapNegativeAndAlign } from '../../utility';

import './_assetStatementTable.scss';

const { Column } = Table;

const AssetStatementsTable = ({ statements }) => {
  const keyedStatements = statements.map((item, index) => ({ ...item, key: index }));
  const rootClasses = classNames('asset-statements-table_root', {
    'asset-statements-table_root--no-one-page': !!statements && statements.length > 20,
  });

  return (
    <Table
      className={rootClasses}
      dataSource={keyedStatements}
      size="small"
      pagination={{
        pageSize: 20,
        hideOnSinglePage: true,
        position: 'top',
        showSizeChanger: false
      }}
    >
      <Column
        title="Date"
        dataIndex="date"
        key="date"
        render={(date) => moment(date).format('MM/DD/YYYY')}
      />
      <Column
        title="Cost"
        dataIndex="cost"
        key="cost"
        render={(num) => wrapNegativeAndAlign(num)}
        className="asset-statements-table_number-cell"
      />
      <Column
        title="Market Value"
        dataIndex="marketValue"
        key="marketValue"
        render={(num) => wrapNegativeAndAlign(num)}
        className="asset-statements-table_number-cell"
      />
    </Table>
  );
};

export default AssetStatementsTable;

AssetStatementsTable.propTypes = {
  statements: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.date,
      cost: PropTypes.number,
      marketValue: PropTypes.marketValue,
    })
  ),
};
