import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { routes } from '../../../routing';
import SiteLogo from '../../SiteLogo/SiteLogo';

import './portal-layout.less';

const PortalLayout = ({
  children,
  logoAside,
  navigation,
  userProfileAside,
  userMenu,
  userProfile: {
    isAnyWiAssets
  }
}) => {
  return (
    <div className="portal-layout">
      <div className="header-wrapper">
        <header className="portal-layout_header">
          <div className="portal-layout_header_logo">
            <Link to={routes.root}>
              <SiteLogo isAnyWiAssets={isAnyWiAssets} />
            </Link>
            <div className="portal-layout_header_logo-aside">
              {logoAside}
            </div>
          </div>
          <div className="portal-layout_header_user-profile">
            {userProfileAside}
            {userMenu}
          </div>
          <div className="portal-layout_header_nav">
            <nav>
              {navigation}
            </nav>
          </div>
        </header>
      </div>
      <div className="portal-layout_content">
        {children}
      </div>
    </div>
  );
};

export default connect(
  ({ userProfile }) => ({ userProfile })
)(PortalLayout);
