import * as Yup from 'yup';
import registerFormLabels from '../../labels/register-form';

export const formInitialValues = {
  fullName: '',
  organization: '',
  stateId: 45,
  city: '',
  email: '',
  password: '',
  confirmPassword: ''
};

export const registerSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, registerFormLabels.validation.fullName.tooShort)
    .max(350, registerFormLabels.validation.fullName.tooLong)
    .required(registerFormLabels.validation.fullName.required),
  organization: Yup.string()
    .max(300, registerFormLabels.validation.organizationName.tooLong)
    .notRequired(),
  stateId: Yup.number()
    .notRequired(),
  city: Yup.string()
    .required(registerFormLabels.validation.city.required)
    .max(50, registerFormLabels.validation.city.tooLong),
  email: Yup.string()
    .email(registerFormLabels.validation.email.wrongFormat)
    .max(300, registerFormLabels.validation.email.tooLong)
    .required(registerFormLabels.validation.email.required),
  password: Yup.string()
    .required(registerFormLabels.validation.password.required)
    .min(8, registerFormLabels.validation.password.minLength)
    .max(32, registerFormLabels.validation.password.maxLength)
    .matches(/[A-Z]+/, registerFormLabels.validation.password.capitalLetterRequired)
    .matches(/[0-9]+/, registerFormLabels.validation.password.digitReuired)
    .matches(/\W+/, registerFormLabels.validation.password.specialCharacterRequired),
  confirmPassword: Yup.string()
    .required(registerFormLabels.validation.confirmPassword.required)
    .oneOf([Yup.ref('password'), null], registerFormLabels.validation.confirmPassword.sameAsPassword)
});
