export const filterFlags = {
  email: 0b0001,
  fullName: 0b0010,
  account: 0b0100,
  loan: 0b1000
};

export const filterLabelsMap = {
  email: 'Email',
  fullName: 'Full Name',
  account: 'Account Number',
  loan: 'Loan Number'
}

export const allChecked = 0b1111;
export const allUnchecked = 0b0000;