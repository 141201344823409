import axios from 'axios';
import { userProfileEndpoints } from '../../const/backend';
import { authHeaders, userAndAuthHeaders, actionTemplate } from '../shared';
import {
  FETCH__USER_PROFILE__FAIL,
  FETCH__USER_PROFILE__SUCCESS,
  FETCH__USER_PROFILE,
  FETCH__USER_PROFILE_SETTINGS,
  FETCH__USER_PROFILE_SETTINGS__FAIL,
  FETCH__USER_PROFILE_SETTINGS__SUCCESS,
  UPDATE__USER_PROFILE_SETTINGS,
  UPDATE__USER_PROFILE_SETTINGS__FAIL,
  UPDATE__USER_PROFILE_SETTINGS__SUCCESS,
  RESET__USER_PROFILE_SETTINGS,
} from './actions';
import { LOG_OUT } from '../user/actions';


export const fetchUserProfile = (token, userId) => dispatch => {
  dispatch(actionTemplate(FETCH__USER_PROFILE));

  let headers = !!userId ?
    userAndAuthHeaders(token, userId) :
    authHeaders(token);

  return axios.get(
    userProfileEndpoints.get(),
    { headers }
  ).then(response => {

    if (response.status === 200) {
      dispatch({...actionTemplate(FETCH__USER_PROFILE__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {

    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));
      return;
    }

    dispatch(actionTemplate(FETCH__USER_PROFILE__FAIL));
  });
}

export const fetchUserProfileSettings = token => dispatch => {
  dispatch(actionTemplate(FETCH__USER_PROFILE_SETTINGS));

  return axios.get(
    userProfileEndpoints.settings(),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch({ ...actionTemplate(FETCH__USER_PROFILE_SETTINGS__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    dispatch(actionTemplate(FETCH__USER_PROFILE_SETTINGS__FAIL));

    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));
    }
  });
}

export const updateProfileSettings = (settings, token) => dispatch => {
  dispatch(actionTemplate(UPDATE__USER_PROFILE_SETTINGS));

  return axios.patch(
    userProfileEndpoints.settings(),
    settings,
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch(actionTemplate(UPDATE__USER_PROFILE_SETTINGS__SUCCESS));
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));
      return;
    }

    dispatch(actionTemplate(UPDATE__USER_PROFILE_SETTINGS__FAIL));
  });
}

export const resetProfileSettings = () => dispatch => {
  dispatch(actionTemplate(RESET__USER_PROFILE_SETTINGS));
}
