import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import user from './user';
import geo from './geo';
import userProfile from './userProfile';
import userManagement from './userManagement';
import userResourceRequests from './resourceRequests';
import resourceRequests from '../reducers/resourceRequests';
import resources from '../reducers/resources';
import loanDetails from './loanDetails';
import investmentDetails from './investmentDetails';
import assetDetails from './assetDetails';
import statements from './statements';
import dataImportSessions from './dataImportSessions';
import dataImportFiles from './dataImportFiles';
import adminUserProfile from './adminUserProfile';
import dashboard from './dashboard';

export default function configureStore(history, initialState) {
  const reducers = {
    resourceRequests,
    resources,
    user,
    geo,
    userManagement,
    userProfile,
    userResourceRequests,
    loanDetails,
    investmentDetails,
    assetDetails,
    statements,
    dataImportSessions,
    dataImportFiles,
    adminUserProfile,
    dashboard,
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = (history) => combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  return createStore(
    rootReducer(history),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
