import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { adminRole } from '../const/user';
import { routes } from '../routing';
import AdminDashboardPage from './AdminDashboardPage';
import AdminLayout from './layouts/AdminLayout';

const HomePage = ({ user, history, match }) => (
  user.role === adminRole
    ? <AdminLayout component={AdminDashboardPage} />
    : <Redirect to={routes.userLoans} />
);

export const ConnectedHomePage = connect(({ user }) => ({ user }))(HomePage);
