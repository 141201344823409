import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchUserProfile } from '../../store/userProfile/actionCreators'
import UserAccountPage from '../../common/pages/user-account-page/UserAccountPage';
import LoanList from '../../components/LoanList/LoanList';

const LoansPage = ({
  match: {
    params: {
      userId
    }
  },
  user: {
    token,
  },
  userProfile: {
    isUserProfileRequested,
    loans
  },
  fetchUserProfile
}) => {
  useEffect(() => {
    fetchUserProfile(token, userId ? userId : null);
  }, [token, userId]);

  return (
    <UserAccountPage
      userId={userId}
      isLoading={isUserProfileRequested}
    >
      <LoanList
        loading={isUserProfileRequested}
        items={loans}
        userId={userId}
      />
    </UserAccountPage>
  );
}

export default connect(
  ({ user, userProfile }) => ({ user, userProfile }),
  { fetchUserProfile }
)(LoansPage);
