import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { Link, Redirect } from 'react-router-dom';
import { Input, Select, Button } from 'antd';
import { fetchStates } from '../../store/geo/actionCreators';
import { register, resetRegisterState } from '../../store/user/actionCreators';
import { routes } from '../../routing';
import registerFormLabels from '../../labels/register-form';
import { formInitialValues, registerSchema } from './formik.config';
import FormControl from '../../common/FormControl/FormControl';

import './register-form.less';

const { Option } = Select;
const { Password } = Input;

const RegisterForm = ({
  register,
  fetchStates,
  resetRegisterState,
  geo: {
    states,
    isStatesRequested
  },
  user: {
    isRegisterRequested,
    isRegisterFailed,
    registerAttempts,
    token
  }
}) => {
  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: registerSchema,
    onSubmit: ({
      fullName,
      email,
      stateId,
      city,
      organization: organizationName,
      password
    }) => {
      register({
        fullName,
        email,
        city,
        stateId,
        organizationName,
        password
      });
    }
  });

  useEffect(() => {
    fetchStates();

    return () => {
      resetRegisterState();
    };
  }, []);

  const handleStateChange = (value) => {
    formik.setFieldValue('stateId', value);
  }

  const handleFilterOption = (
    inputValue, {
      props: {
        label
      }
    }
  ) => (label.toLowerCase().includes(inputValue.toLowerCase()));

  if (token) {
    return (<Redirect to={routes.root} />);
  }

  if (registerAttempts > 0 && !isRegisterFailed && !isRegisterRequested) {
    return (
      <div className="tmf-reg-form_result tmf-form">
        <h1>{registerFormLabels.result.header}</h1>
        <p>{registerFormLabels.result.description}</p>
        <Button type='link'>
          <Link to={routes.login}>
            {registerFormLabels.buttons.goToLogin}
          </Link>
        </Button>
      </div>
    );
  }

  return (
    <div className="tmf-reg-form">
      <h1>{registerFormLabels.header}</h1>
      <form onSubmit={formik.handleSubmit}>
        <FormControl
          id="fullName"
          caption={registerFormLabels.controls.fullName.label}
          touched={formik.touched.fullName}
          error={formik.errors.fullName}
        >
          <Input id="fullName" {...formik.getFieldProps('fullName')} />
        </FormControl>
        <FormControl
          id="organization"
          caption={registerFormLabels.controls.organization.label}
          touched={formik.touched.organization}
          error={formik.errors.organization}
        >
          <Input id="organization" {...formik.getFieldProps('organization')} />
        </FormControl>
        <FormControl
          id="stateId"
          caption={registerFormLabels.controls.state.label}
        >
          <Select
            id="stateId"
            showSearch
            disabled={!states || isStatesRequested}
            value={formik.values.stateId}
            filterOption={handleFilterOption}
            loading={isStatesRequested}
            style={{ width: '100%' }}
            onBlur={formik.onBlur}
            onChange={handleStateChange}
          >
            {
              states && states.map((state) => (
                <Option
                  key={`state-${state.id}`}
                  value={state.id}
                  label={state.name}
                >
                  {state.name}
                </Option>
              ))
            }
          </Select>
        </FormControl>
        <FormControl
          id="city"
          caption={registerFormLabels.controls.city.label}
          touched={formik.touched.city}
          error={formik.errors.city}
        >
          <Input id="city" {...formik.getFieldProps('city')} />
        </FormControl>
        <FormControl
          id="email"
          caption={registerFormLabels.controls.email.label}
          touched={formik.touched.email}
          error={formik.errors.email}
        >
          <Input
            id="email"
            {...formik.getFieldProps('email')}
          />
        </FormControl>
        <FormControl
          id="password"
          caption={registerFormLabels.controls.password.label}
          touched={formik.touched.password}
          error={formik.errors.password}
        >
          <Password
            id="password"
            {...formik.getFieldProps('password')}
          />
        </FormControl>
        <FormControl
          id="confirmPassword"
          caption={registerFormLabels.controls.confirmPassword.label}
          touched={formik.touched.confirmPassword}
          error={formik.errors.confirmPassword}
        >
          <Password
            id="confirmPassword"
            {...formik.getFieldProps('confirmPassword')}
          />
        </FormControl>
        <div className="tmf-form_form-controls">
          <Button type='link'>
            <Link to={routes.login}>
              {registerFormLabels.buttons.backToLogin}
            </Link>
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isStatesRequested}
            loading={isRegisterRequested}
          >
            {registerFormLabels.buttons.register}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default connect(
  ({ geo, user }) => ({ geo, user }),
  { fetchStates, register, resetRegisterState }
)(RegisterForm);
