import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Spin, Empty, Button } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import ViewSelector from '../ViewSelector/ViewSelector';
import ResourceRequestList from './ResourceRequestList';

import './_resourceRequestView.scss';

const resourceRequestViews = [{
    key: 'open',
    title: 'Open'
  }, {
    key: 'mine',
    title: 'I\'m working on'
  }, {
    key: 'completed',
    title: 'Completed'
  }, {
    key: 'all',
    title: 'All'
  }];

class ResourceRequestView extends Component {
  state = {
    currentViewKey: 'open'
  }

  handleViewChange = (viewKey) => {
    let { onViewChange } = this.props;

    this.setState({ currentViewKey: viewKey });
    onViewChange(viewKey);
  }

  handleRefresh = () => {
    let { onRefresh } = this.props;
    let { currentViewKey } = this.state;

    onRefresh(currentViewKey);
  }

  render() {
    let {
      isItemsRequested,
      resourceRequests,
      onItemDoubleClick,
      onItemDelete
    } = this.props;
    let { currentViewKey } = this.state;

    return (
      <Fragment>
        <Row className="rsrq-view">
          <Col lg={6}>
            <ViewSelector
              views={resourceRequestViews}
              onViewChange={this.handleViewChange}
              activeViewKey="open"/>
          </Col>
          <Col lg={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Button className="float-right" shape="circle" onClick={this.handleRefresh} size="small" icon={<ReloadOutlined style={{paddingTop: '4px'}} />} />
          </Col>
        </Row>
        <Row>
          {
            isItemsRequested
            ? (
              <Spin size='large' className="rsrq-details_spinner" indicator={<LoadingOutlined spin />} />
            )
            : (
              <Col lg={12}>
                {
                  resourceRequests.length > 0 ? (
                    <ResourceRequestList
                      onItemDoubleClick={onItemDoubleClick}
                      onItemDelete={onItemDelete}
                      resourceRequests={resourceRequests}
                      view={currentViewKey} />
                  ) : (
                    <Empty
                      className="rsrq-view--empty"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={`No items found.`} >
                        <Button type="primary" onClick={this.handleRefresh} ghost>Refresh</Button>
                    </Empty>
                  )
                }
              </Col>
            )
          }
        </Row>
      </Fragment>
    )
  }
}

ResourceRequestView.propTypes = {
  resourceRequests: PropTypes.arrayOf(PropTypes.object),
  onViewChange: PropTypes.func,
  onItemDoubleClick: PropTypes.func,
  onRefresh: PropTypes.func
};

ResourceRequestView.defaultProps = {
  resourceRequests: [],
  onViewChange: () => {},
  onItemDoubleClick: () => {},
  onRefresh: () => {}
};

export default ResourceRequestView;
