import React from 'react';
import UsersView from '../../components/UsersView/UsersView';
import AdminPage from '../../common/pages/admin-page/AdminPage';

const UserManagementPage = ({ history }) => {
  return (<AdminPage>
    <UsersView history={ history }/>
  </AdminPage>)
};

export default UserManagementPage;
