import {
  FETCH__ASSET_DETAILS,
  FETCH__ASSET_DETAILS__FAIL,
  FETCH__ASSET_DETAILS__SUCCESS
} from './actions';

const defaultState = {
  assetDetails: undefined,
  isAssetDetailsFetched: false,
  isAssetDetailsFetchFailed: false
};

const assetDetails = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH__ASSET_DETAILS:
      return {
        ...state,
        isAssetDetailsFetched: true,
        isAssetDetailsFetchFailed: false,
      };
    case FETCH__ASSET_DETAILS__FAIL:
      return {
        ...state,
        isAssetDetailsFetched: false,
        isAssetDetailsFetchFailed: true,
      };
    case FETCH__ASSET_DETAILS__SUCCESS:
      return {
        ...state,
        isAssetDetailsFetched: false,
        assetDetails: action.details,
      };
    default:
      return state;
  }
}

export default assetDetails;