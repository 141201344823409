import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Empty, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import DataImportSessionListItem from './DataImportSessionListItem';

import './_dataImportSessionList.scss';

class DataImportSessionsList extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedSession: null }
  }

  handleItemClick = (session) => {
    let { onSessionSelectionChanged } = this.props;

    this.setState({ ...this.state, selectedSession: session });
    !!onSessionSelectionChanged && onSessionSelectionChanged(session);
  }

  render() {
    let that = this;
    let { loading, items, onStartSession, dataImportSessions: { selectedSession } } = this.props;
    // let { selectedSession } = this.state;

    return (
      <div className="data-import-sessions__root">
        <h2>Sessions</h2>
        <div className="data-import-sessions__list">
          {
            loading &&
            <Spin
              size="large"
              className="rsrq-details_spinner"
              indicator={
                <LoadingOutlined spin />
              }
            />
          }
          {
            !loading && !!items && items.length === 0 && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Data Import Sessions not found">
                  <Button
                    type="primary"
                    onClick={onStartSession}
                  >
                    Start New
                  </Button>
              </Empty>
            )
          }
          {
            !loading && !!items && items.length > 0 && items.map((item) =>
              (<DataImportSessionListItem
                key={item.id}
                onClick={that.handleItemClick}
                selected={!!selectedSession && selectedSession.id === item.id}
                session={item} />))
          }
        </div>
      </div>
    );
  }
}

DataImportSessionsList.defaultProps = {
  items: [],
  loading: false,
  onStartSession: () => {}
}

export default connect(({dataImportSessions}) => ({dataImportSessions}))(DataImportSessionsList);
