import * as Yup from 'yup';
import setNewPasswordLabels from '../../labels/set-new-password';

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(setNewPasswordLabels.validation.password.required)
    .min(8, setNewPasswordLabels.validation.password.minLength)
    .matches(/[A-Z]+/, setNewPasswordLabels.validation.password.capitalLetterRequired)
    .matches(/[0-9]+/, setNewPasswordLabels.validation.password.digitReuired)
    .matches(/\W+/, setNewPasswordLabels.validation.password.specialCharacterRequired),
  confirmPassword: Yup.string()
    .required(setNewPasswordLabels.validation.confirmPassword.required)
    .oneOf([Yup.ref('password'), null], setNewPasswordLabels.validation.confirmPassword.sameAsPassword)
});
