import axios from 'axios';
import { actionTemplate, authHeaders } from '../shared';
import { LOG_OUT } from '../user/actions';
import { dataImportFilesEndpoints } from '../../const/backend';
import {
  FETCH__DATAIMPORTFILES,
  FETCH__DATAIMPORTFILES__SUCCESS,
  FETCH__DATAIMPORTFILES__FAIL
} from './actions';

export const fetchDataImportFiles = (token, sessionId) => dispatch => {
  dispatch(actionTemplate(FETCH__DATAIMPORTFILES));

  axios.get(
    dataImportFilesEndpoints.listBySessionId(sessionId),
    { headers: { ...authHeaders(token) }}
  ).then(response => {
    if (response.status === 200) {
      dispatch({...actionTemplate(FETCH__DATAIMPORTFILES__SUCCESS), data: response.data });
    }
  }).catch(({ response }) => {
    if (response.status === 401) {
      dispatch(actionTemplate(LOG_OUT));

      return;
    }

    dispatch(actionTemplate(FETCH__DATAIMPORTFILES__FAIL));
  });
}