export const FETCH__ADMIN_USER_PROFILE = 'FETCH__ADMIN_USER_PROFILE';
export const FETCH__ADMIN_USER_PROFILE__SUCCESS = 'FETCH__ADMIN_USER_PROFILE__SUCCESS';
export const FETCH__ADMIN_USER_PROFILE__FAIL = 'FETCH__ADMIN_USER_PROFILE__FAIL';

export const CLEAR__ADMIN_USER_PROFILE = 'CLEAR__ADMIN_USER_PROFILE';

export const FETCH__USER_LOANS = 'FETCH__USER_LOANS';
export const FETCH__USER_LOANS__SUCCESS = 'FETCH__USER_LOANS__SUCCESS';
export const FETCH__USER_LOANS__FAIL = 'FETCH__USER_LOANS__FAIL';

export const FETCH__USER_ACCOUNTS = 'FETCH__USER_ACCOUNTS';
export const FETCH__USER_ACCOUNTS__SUCCESS = 'FETCH__USER_ACCOUNTS__SUCCESS';
export const FETCH__USER_ACCOUNTS__FAIL = 'FETCH__USER_ACCOUNTS__FAIL';

export const FETCH__USER_REQUESTS = 'FETCH__USER_REQUESTS';
export const FETCH__USER_REQUESTS__SUCCESS = 'FETCH__USER_REQUESTS__SUCCESS';
export const FETCH__USER_REQUESTS__FAIL = 'FETCH__USER_REQUESTS__FAIL';

export const CONFIRM__PROFILE__USER_EMAIL = 'CONFIRM__PROFILE__USER_EMAIL';
export const CONFIRM__PROFILE__USER_EMAIL__SUCCESS = 'CONFIRM__PROFILE__USER_EMAIL__SUCCESS';
export const CONFIRM__PROFILE__USER_EMAIL__FAIL = 'CONFIRM__PROFILE__USER_EMAIL__FAIL';

export const UNLINK__LOAN = 'UNLINK__LOAN';
export const UNLINK__LOAN__SUCCESS = 'UNLINK__LOAN__SUCCESS';
export const UNLINK__LOAN__FAIL = 'UNLINK__LOAN__FAIL';

export const UNLINK__INVESTMENT = 'UNLINK__INVESTMENT';
export const UNLINK__INVESTMENT__SUCCESS = 'UNLINK__INVESTMENT__SUCCESS';
export const UNLINK__INVESTMENT__FAIL = 'UNLINK__INVESTMENT__FAIL';

export const DELETE__PROFILE__RESOURCE_REQUEST = 'DELETE__PROFILE__RESOURCE_REQUEST';
export const DELETE__PROFILE__RESOURCE_REQUEST__SUCCESS = 'DELETE__PROFILE__RESOURCE_REQUEST__SUCCESS';
export const DELETE__PROFILE__RESOURCE_REQUEST__FAIL = 'DELETE__PROFILE__RESOURCE_REQUEST__FAIL';