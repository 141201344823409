import React from 'react';

import './user-data.less';

const UserData = ({ name, email }) => {
  if (!name || !email) {
    return null;
  }

  return (
    <div className="user-heading_data">
      <h2>{name}</h2>
      <h2>{email}</h2>
    </div>
  );
}

export default UserData;
