import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AdminPage from '../../common/pages/admin-page/AdminPage';
import {
  fetchDataImportSessions,
  updateSelectedSession,
  createDataImportSession,
  clearSelectedSession
} from '../../store/dataImportSessions/actionCreators';
import DataImportSessionsList from '../../components/DataImportSessionsList/DataImportSessionsList';
import DataImportWizard from '../../components/DataImportWizard/DataImportWizard';

import { Empty, Button } from 'antd';

import './data-import-page.less';

const DataImportPage = ({
  updateSelectedSession,
  user: {
    token
  },
  createDataImportSession,
  fetchDataImportSessions,
  clearSelectedSession,
  dataImportSessions: {
    selectedSession,
    items,
    isSessionsFetched
  },
}) => {

  useEffect(() => {
    fetchDataImportSessions(token);

    return () => clearSelectedSession();
  }, [])

  const handleSessionSelected = (session) => {
    updateSelectedSession(session);
  }

  const handleStartNewSession = () => {
    createDataImportSession().then(() => fetchDataImportSessions(token))
  }

  return (
    <AdminPage>
      <div className="data-import">
        <DataImportSessionsList onSessionSelectionChanged={handleSessionSelected} loading={isSessionsFetched} items={items} />
        {
          !!selectedSession && <DataImportWizard session={selectedSession} />
        }
        {
          !selectedSession && !isSessionsFetched &&
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Active Data Import Sessions">
              <Button
                type="primary"
                onClick={handleStartNewSession}
              >
                Start New
              </Button>
          </Empty>
        }
      </div>
    </AdminPage>
  )
};

export default connect(
  ({ user, dataImportSessions }) => ({ user, dataImportSessions }),
  { fetchDataImportSessions, updateSelectedSession, clearSelectedSession, createDataImportSession }
)(DataImportPage);
