import React from 'react';

import UserAccountPage from '../../common/pages/user-account-page/UserAccountPage';
import StatementsVault from '../../components/StatementsVault/StatementsVault';

const StatementsVaultPage = ({
  match: {
    params: {
      userId
    }
  }
}) => (
  <UserAccountPage
    userId={userId}
    loadProfile
  >
    <StatementsVault userId={userId} />
  </UserAccountPage>
);

export default StatementsVaultPage;
