import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { defaultRoutes } from '.';

const AuthenticatedRoute = ({
  component: Component,
  render,
  user,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      !user.token
        ? <Redirect to={defaultRoutes.login} />
        : !Component && render
          ? render(props)
          : <Component {...props} />
    )}
  />
);

export default connect(({ user }) => ({ user }))(AuthenticatedRoute);
