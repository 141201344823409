const mainHeaderLabels = {
  header: 'TMF Financial Portal',
  subHeader: 'Administration',
  userMenu: {
    editProfile: 'Edit Profile',
    logOut: 'Log Out'
  },
  links: {
    dashboard: 'Dashboard',
    users: 'Users',
    requests: 'Resource Requests',
    dataImport: 'Data Import'
  }
}

export default mainHeaderLabels;