import * as Yup from 'yup';
import registerFormLabels from '../../labels/register-form';

export const initialFormValues = {
  fullName: '',
  organizationName: '',
  stateId: undefined,
  city: '',
  password: '',
  confirmPassword: undefined,
  email: '',
};

export const profileSettingsSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, registerFormLabels.validation.fullName.tooShort)
    .required(registerFormLabels.validation.fullName.required),
  organization: Yup.string()
    .notRequired(),
  stateId: Yup.number()
    .notRequired(),
  city: Yup.string()
    .required(registerFormLabels.validation.city.required)
    .max(50, registerFormLabels.validation.city.tooLong),
  password: Yup.string()
    .min(8, registerFormLabels.validation.password.minLength)
    .matches(/[A-Z]+/, registerFormLabels.validation.password.capitalLetterRequired)
    .matches(/[0-9]+/, registerFormLabels.validation.password.digitReuired)
    .matches(/\W+/, registerFormLabels.validation.password.specialCharacterRequired),
  confirmPassword: Yup.string()
    .test('same-as-pass-if-not-empty', registerFormLabels.validation.confirmPassword.sameAsPassword, function(item) {
      if (!!this.parent.password) {
        return this.parent.password === this.parent.confirmPassword
      }

      return true;
    })
    .oneOf([Yup.ref('password'), null], registerFormLabels.validation.confirmPassword.sameAsPassword)
});
