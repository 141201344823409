import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Statistic, Result, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { fetchUserProfile } from '../../store/userProfile/actionCreators'
import { fetchLoan } from '../../store/loanDetails/actionCreators';
import UserAccountPage from '../../common/pages/user-account-page/UserAccountPage';
import { routes, routeBuilder } from '../../routing';
import LoanLinesTable from '../../components/LoanLinesTable/LoanLinesTable';
import './loan-details-page.less'

const LoanDetailsPage = ({
  match: {
    params: {
      userId,
      loanId
    },
  },
  user: {
    token
  },
  loanDetails: {
    isLoanDetailsFetched,
    isLoanDetailsFetchFailed,
    details,
  },
  fetchLoan
}) => {
  const homeLink = !userId
  ? routes.userLoans
  : routeBuilder.userAccountLoans(userId);

  useEffect(() => {
    fetchLoan(loanId, token);
  }, [loanId, token]);

  const handleRefresh = () => {
    fetchLoan(loanId, token);
  }

  if (isLoanDetailsFetched) {

    return (
      <Spin
        size="large"
        className="rsrq-details_spinner"
        indicator={
          <LoadingOutlined spin />
        }
      />
    );
  }

  if (isLoanDetailsFetchFailed || !details) {

    return (
      <Result
        status="error"
        title="Something went wrong"
        subTitle="Please try to refresh page a bit later. If situation still take place - contact support"
        extra={(
          <Button type="ghost" onClick={handleRefresh}>Refresh Page</Button>
        )}
      />
    )
  }

  return (
    <UserAccountPage
      userId={userId}
      loadProfile
    >
      <div className="loan-details">
        {
          !!details && (
            <>
              <div className="loan-details_header">
                <h3>
                  <Link className="loan-details_loan-number" to={homeLink}>Loans</Link>
                  <span className="loan-details_loan-number">/</span>
                  <span className="loan-details_loan-number">{details.number}</span>
                  {details.description}
                </h3>
              </div>
              <div className="loan-details_common-info">
                <Statistic prefix="$" precision={2} title="Original Loan Amount" value={details.originalLoanAmount} />
                <Statistic prefix="$" precision={2} title="Principal Balance" value={details.principalBalance} />
                <Statistic prefix="$" precision={2} title="Interest Balance" value={details.interestBalance} />
                <Statistic prefix="$" precision={2} title="Scheduled Payment" value={details.scheduledPayment} />
                <Statistic suffix="%" precision={2} title="Rate" value={details.rate} />
              </div>
              <div className="loan-details_tables">
                <h3>Details</h3>
                <LoanLinesTable lines={details.lines} />
              </div>
            </>
          )
        }
      </div>
    </UserAccountPage>
  );
}

export default connect(
  ({ user, loanDetails }) => ({ user, loanDetails }),
  { fetchUserProfile, fetchLoan }
)(LoanDetailsPage);
