const userHeaderLabels = {
  subHeader: 'Administration',
  userMenu: {
    editProfile: 'Edit Profile',
    logOut: 'Log Out',
  },
  labelsLinks: {
    loans: 'Loans',
    investments: 'Investments',
    status: 'Status',
    statements: 'Statements',
  },
};

export default userHeaderLabels;
