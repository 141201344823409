const confirmEmailLabels = {
  header: 'Email confirmation',
  result: {
    success: {
      subHeader: 'Congratulations! Your email was sucessfully verified.'
    },
    fail: {
      subHeader: 'Sorry! We can\'t verify your email. Please, contact support.'
    }
  },
  buttons: {
    goToLogin: 'Go to Login Page'
  }
}

export default confirmEmailLabels;