import React from 'react';
import classNames from 'classnames';
import ResourceRequestListItemAlt from './ResourceRequestListItemAlt';

const ResourceRequestList = ({ resourceRequests, onItemDoubleClick, onItemDelete, view }) => {
  let rootClasses = classNames(
    'rsrq-list', {
      [`rsrq-list--${view}`]: true
    }
  );

  return (
    <ul className={rootClasses}>
      {resourceRequests.map((item) => (
        <ResourceRequestListItemAlt onDoubleClick={onItemDoubleClick} onDelete={onItemDelete} {...item} key={`rrq-${item.id}`} />
      ))}
  </ul>)
};

export default ResourceRequestList;