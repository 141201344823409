import React, { Component, Fragment } from 'react';
import { Badge } from 'reactstrap';

import moment from 'moment';

import './ResourceRequestCard.css';

const requestTypeMap = {
  '0': 'Loan',
  '1': 'Investment'
}

const statusMap = {
  '0': 'Open',
  '1': 'Accepted',
  '2': 'Declined',
  '3': 'Approved'
}

class ResourceRequestCard extends Component {
  render() {
    let {
      requestDetails: {
        id,
        initiatedBy,
        createdOn,
        processedBy,
        acceptedOn,
        completedOn,
        type,
        resourceNumber,
        status,
        remark
      }
    } = this.props;

    return (
      <div className="rsrq-card">
        <h3>Request Details</h3>
        <label>request number</label>
        <p>{`RSRQ-${('000000'+id).slice(-7)}`}</p>
        <label>Current status</label>
        <p>{statusMap[status]}</p>
        {
          completedOn &&
          (
            <Fragment>
              <label>Current status</label>
              <p>{moment(createdOn).format('MMMM Do, YYYY')}</p>
            </Fragment>
          )
        }
        {
          status === 2 &&
          (
            <Fragment>
              <label>Remark</label>
              <p>{remark}</p>
            </Fragment>
          )
        }
        <label>requested resource</label>
        <p>{requestTypeMap[type]}</p>
        <label>requested number or name</label>
        <p>{resourceNumber}</p>
        <label>created on</label>
        <p>{moment(createdOn).format('MMMM Do, YYYY')}</p>
        <label>initiated by {initiatedBy.isAdmin && <Badge color="primary">admin</Badge>}</label>
        <p>{initiatedBy.fullName}</p>
        {
          processedBy &&
          (
            <Fragment>
              <label>accepted by</label>
              <p>{processedBy.fullName}</p>
              <label>accepted on</label>
              <p>{moment(acceptedOn).format('MMMM Do, YYYY')}</p>
            </Fragment>
          )
        }
      </div>
    );
  }
}

export default ResourceRequestCard;