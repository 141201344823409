const registerFormLabels = {
  validation: {
    fullName: {
      tooShort: 'It\'s too short',
      tooLong: '350 symbols max',
      required: 'It\'s required'
    },
    organizationName: {
      tooLong: '300 symbols max',
    },
    city: {
      required: 'It\'s required',
      tooLong: '50 characters max'
    },
    email: {
      wrongFormat: 'Wrong email format',
      required: 'Can\'t be empty',
      tooLong: '300 symbols max'
    },
    password: {
      minLength: 'At least 8 characters long',
      maxLength: '32 symbols max',
      capitalLetterRequired: 'At least one capital letter',
      digitReuired: 'At least one digit',
      specialCharacterRequired: 'One or more special character',
      required: 'Can\'t be empty'
    },
    confirmPassword: {
      required: 'Can\'t be empty',
      sameAsPassword: 'Doesn\'t match'
    }
  },
  controls: {
    fullName: {
      label: 'Full Name',
      placeholder: 'e.g. John Doe'
    },
    organization: {
      label: 'Organization',
      placeholder: 'Your organization name'
    },
    state: {
      label: 'State',
      placeholder: 'It\'s needed to choose city'
    },
    city: {
      label: 'City',
      placeholder: 'Where are you from?'
    },
    email: {
      label: 'Email Address',
      placeholder: 'We\'ll send you confirmation email'
    },
    password: {
      label: 'Password',
      placeholder: 'Put password here'
    },
    confirmPassword: {
      label: 'Confirm Password',
      placeholder: 'Have to match with password'
    }
  },
  buttons: {
    backToLogin: 'Back to Login',
    register: 'Register',
    goToLogin: 'Go to Login page'
  },
  header: 'Registration',
  description: 'Please, fill form below to complete registration process',
  result: {
    header: 'Thank you for your registration!',
    description: 'We\'ve sent you an email with further instructions to complete your registration.'
  }
};

export default registerFormLabels;
