import {
  FETCH__USERS_RRQ_LIST,
  FETCH__USERS_RRQ_LIST__SUCCESS,
  FETCH__USERS_RRQ_LIST__FAIL,
  CREATE__RRQ,
  CREATE__RRQ__FAIL,
  CREATE__RRQ__SUCCESS,
} from './actions';

const defaultState = {
  items: [],
  isUsersRequestsFetched: false,
  isUsersRequestFetchFailed: false,
  isCreateResourceRequestInProgress: false,
  isCreateResourceRequestFailed: false
};

const userResourceRequests = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH__USERS_RRQ_LIST:
      return {
        ...state,
        isUsersRequestsFetched: true,
        isUsersRequestFetchFailed: false
      };
    case FETCH__USERS_RRQ_LIST__FAIL:
      return {
        ...state,
        isUsersRequestsFetched: false,
        isUsersRequestFetchFailed: true
      };
    case FETCH__USERS_RRQ_LIST__SUCCESS:
      return {
        ...state,
        isUsersRequestsFetched: false,
        items: action.data
      };
    case CREATE__RRQ:
      return {
        ...state,
        isCreateResourceRequestInProgress: true,
        isCreateResourceRequestFailed: false,
      };
    case CREATE__RRQ__SUCCESS:
      return {
        ...state,
        isCreateResourceRequestInProgress: false,
      };
    case CREATE__RRQ__FAIL:
      return {
        ...state,
        isCreateResourceRequestInProgress: false,
        isCreateResourceRequestFailed: true,
      };
    default:
      return state;
  }
}

export default userResourceRequests;