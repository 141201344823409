import axios from 'axios';
import {
  FETCH_USER_TOKEN,
  FETCH_USER_TOKEN__SUCCESS,
  FETCH_USER_TOKEN__FAILED,
  REGISTER_USER,
  REGISTER_USER__SUCCESS,
  REGISTER_USER__FAILED,
  RESTORE_PASSWORD,
  RESTORE_PASSWORD__SUCCESS,
  RESTORE_PASSWORD__FAILED,
  RESET_RESETPASSWORDSTATE,
  RESET_REGISTERSTATE,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL__SUCCESS,
  CONFIRM_EMAIL__FAILED,
  RESET_CONFIRMEMAILSTATE,
  VERIFY_RESET_PASSWORD_TOKEN,
  VERIFY_RESET_PASSWORD_TOKEN__SUCCESS,
  VERIFY_RESET_PASSWORD_TOKEN__FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD__SUCCESS,
  CHANGE_PASSWORD__FAILED,
  RESET_CHANGEPASSWORDSTATE,
  LOG_OUT,
  DELETE_USER,
  DELETE_USER__SUCCESS,
  DELETE_USER__FAIL
} from './actions';
import { authEndpoints, usersEndpoints, authorizedRequestHeaders } from '../../const/backend';

export const login = (username, password) => dispatch => {
  dispatch({ type: FETCH_USER_TOKEN });

  axios.post(
    authEndpoints.login(), {
      email: username,
      password,
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: FETCH_USER_TOKEN__SUCCESS, token: response.data })
    }
  }).catch(response => {
    dispatch({ type: FETCH_USER_TOKEN__FAILED });
  })
};

export const register = (data) => dispatch => {
  dispatch({ type: REGISTER_USER});

  axios.post(
    authEndpoints.register(), {
      ...data
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: REGISTER_USER__SUCCESS });
    }
  }).catch(response => {
    dispatch({ type: REGISTER_USER__FAILED })
  });
}

export const resetPassword = (email) => dispatch => {
  dispatch({ type: RESTORE_PASSWORD });

  axios.post(
    authEndpoints.resetPassword(), {
      email
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: RESTORE_PASSWORD__SUCCESS });
    }
  }).catch(response => {
    dispatch({ type: RESTORE_PASSWORD__FAILED });
  });
}

export const resetResetPasswordState = () => dispatch => {
  dispatch({ type: RESET_RESETPASSWORDSTATE });
}

export const resetRegisterState = () => dispatch => {
  dispatch({ type: RESET_REGISTERSTATE });
}

export const confirmEmail = (id, token) => dispatch => {
  dispatch({ type: CONFIRM_EMAIL });

  axios.post(
    authEndpoints.confirmEmail(), {
      id,
      token,
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: CONFIRM_EMAIL__SUCCESS });
    }
  }).catch(response => {
    dispatch({ type: CONFIRM_EMAIL__FAILED });
  });
}

export const resetConfirmEmailState = () => dispatch => {
  dispatch({ type: RESET_CONFIRMEMAILSTATE });
}

export const verifyChangePasswordToken = (id, token) => dispatch => {
  dispatch({ type: VERIFY_RESET_PASSWORD_TOKEN });

  axios.post(
    authEndpoints.verifyChangePasswordToken(), {
      id,
      token
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: VERIFY_RESET_PASSWORD_TOKEN__SUCCESS });
    }
  }).catch(response => {
    dispatch({ type: VERIFY_RESET_PASSWORD_TOKEN__FAILED });
  });
}

export const changePassword = (id, token, password) => dispatch => {
  dispatch({ type: CHANGE_PASSWORD });

  axios.post(
    authEndpoints.changePassword(), {
      id,
      token,
      password
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: CHANGE_PASSWORD__SUCCESS });
    }
  }).catch(response => {
    dispatch({ type: CHANGE_PASSWORD__FAILED });
  });
}

export const resetChangePasswordState = () => dispatch => {
  dispatch({ type: RESET_CHANGEPASSWORDSTATE });
}

export const logout = () => dispatch => {
  dispatch({ type: LOG_OUT });
}

export const deleteUser = (token, userId) => dispatch => {
  dispatch({ type: DELETE_USER });

  return axios.delete(
    usersEndpoints.delete(userId),
    {
      headers: { ...authorizedRequestHeaders(token) }
    }
  ).then(response => {
    if (response.status === 200) {
      dispatch({ type: DELETE_USER__SUCCESS });
    }

    if (response.status === 401) {
      dispatch({ type: LOG_OUT })
    }
  }).catch(response => {
    dispatch({ type: DELETE_USER__FAIL });
  });
}
